import styled from "styled-components";

export const SideDrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 3;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const SideDrawerBox = styled.div`
  position: fixed;
  display: flex;
  width: 460px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => props.theme.color_theme.bodyColor};
  transition: right 0.3s ease-in-out;
  right: 0;
  top: 0;
  z-index: 34;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;
