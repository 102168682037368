import React from "react";
import { useLocation } from "react-router-dom";
import {
  DashboardPageWrapper,
  ClientListWrapper,
  GeneratedDocListWrapper,
  AuditListWrapper,
} from "./DashboardPage.styled";
import ClientList from "../../modules/ClientList/ClientList";
import DocumentList from "../../modules/DocumentList/DocumentList";
import GenerateICF from "../../modules/GenerateICF/GenerateICF";
import AuditList from "../../modules/AuditList/AuditList";
import RedactDoc from "../../modules/RedactDoc/RedactDoc";

const DashboardPage = () => {
  const location = useLocation();

  const isGeneratePath = location.pathname.includes("generate");
  const isStyled = location.pathname.includes("format");
  const isRedact = location.pathname.includes("redact");
  const isAuditLog = location.pathname.includes("logs");

  return (
    <DashboardPageWrapper>
      {isGeneratePath ? (
        <>
          <ClientListWrapper style={{height: 'auto'}}>
             <GenerateICF />
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      ) : isAuditLog ? (
        <>
          <AuditListWrapper style={{ height: "auto" }}>
            <AuditList />
          </AuditListWrapper>
        </>
      ) : isStyled ? (
        <>
          <ClientListWrapper style={{height: 'auto'}}>
            <GenerateICF/>
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      )  : isRedact ? (
        <>
          <ClientListWrapper>
            <RedactDoc/>
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      ) : (
        <>
          <ClientListWrapper>
            <ClientList />
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      )}
    </DashboardPageWrapper>
  );
};

export default DashboardPage;
