import styled from "styled-components";

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 20.5px;
`;

export const ToggleSwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  border-radius: 16px;
  overflow: hidden;
`;

export const ToggleSwitchSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.color_theme.toggleSwitchDisableColor};
  transition: 0.4s;
  border-radius: 0%;

  &:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    left: 2px;
    bottom: 2.6px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const ToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSwitchSpan} {
    background-color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME"
    ? "#ffffff"
    : props.theme.color_theme.primaryColor};
  }

  &:focus + ${ToggleSwitchSpan} {
    box-shadow: 0 0 1px #008000;
  }

  &:checked + ${ToggleSwitchSpan}:before {
    transform: translateX(16px);
    background-color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME"
    ? "#08E17B"
    : ""};
  }
`;
