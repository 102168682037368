import React from "react";

const APlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M0.833252 15.8337L5.41658 4.16699H7.08325L11.6666 15.8337H10.1041L8.91658 12.7087H3.58325L2.39575 15.8337H0.833252ZM4.08325 11.3753H8.41658L6.29158 5.72949H6.20825L4.08325 11.3753ZM15.2083 13.3337V10.6253H12.4999V9.37533H15.2083V6.66699H16.4583V9.37533H19.1666V10.6253H16.4583V13.3337H15.2083Z"
      />
    </svg>
  );
};

export default APlusIcon;
