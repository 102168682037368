import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useSpeechSynthesis = (enableListening = true) => {
  const screen_reader = useSelector((state) => state?.a11y?.screen_reader);
  const [speaking, setSpeaking] = useState(false);

  const speak = (text, voiceIndex = 0) => {
    text = text.replace(/[_-]/g, " ");
    if (!("speechSynthesis" in window)) return;
    window.speechSynthesis.cancel();

    let enus = window.speechSynthesis
      .getVoices()
      .filter((y) => y.lang === "en-US");

    if (enus.length === 0) {
      enus = window.speechSynthesis.getVoices().filter((y) => y.lang === "en");
    }

    if (enus.length === 0) {
      enus = window.speechSynthesis.getVoices();
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = enus[voiceIndex];

    setSpeaking(true);
    utterance.addEventListener("end", function () {
      setSpeaking(false);
    });

    window.speechSynthesis.speak(utterance);
  };

  const handleTabKeyPress = (event) => {
    if (event.key === "Tab") {
      const focusedElement = document.activeElement;
      if (
        screen_reader &&
        focusedElement &&
        (focusedElement?.dataset?.speech ||
          focusedElement?.textContent?.length < 100)
      ) {
        if (focusedElement?.dataset?.speech === "") return;
        if (focusedElement?.dataset?.speech) {
          speak(focusedElement?.dataset?.speech);
        } else {
          speak(focusedElement.textContent);
        }
      }
    }
  };

  const cancelSpeaking = () => {
    if (!("speechSynthesis" in window)) return;
    window.speechSynthesis.cancel();
  };

  useEffect(() => {
    if (!enableListening) return;
    if (screen_reader === false) {
      if (!("speechSynthesis" in window)) return;
      window.speechSynthesis.cancel();
    }
    window.addEventListener("keyup", handleTabKeyPress);
    return () => {
      window.removeEventListener("keyup", handleTabKeyPress);
    };
  }, [screen_reader, enableListening]);

  return { speak, speaking, cancelSpeaking };
};

export default useSpeechSynthesis;
