import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  // width: 1280px;
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  background:  ${(props) => `${props.theme.color_theme.bodyColor}`};
  ${({ hideHeader }) =>
    hideHeader
      ? `
      background: none;
      border: none;
      width: -webkit-fill-available; /* For WebKit-based browsers */
      width: -moz-available; /* For Mozilla Firefox */
      width: fill-available; /* For other modern browsers */
      position: absolute;
      top: 0; 
      left: 0; 
      z-index: 2;
  `
      : ''}
`;

export const OptionsWrapper = styled.div`
  display: flex;
`;


export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.div`
  width: 218px;
  background-color: ${(props) => props.theme.color_theme.toolTipBackgoundColor};
  color: ${(props) => props.theme.color_theme.color};
  border-radius: 8px;
  padding: 16px 20px 12px 20px;
  position: absolute;
  right: 45px;
  margin-top: 4px;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-weight: 400;
  font-family: Segoe UI;
  
  &::after {
    content: "";
    position: absolute;
    
    top: -25px;
    right: 2%;
    margin-left: -5px;
    border-width: 14px;
    border-style: solid;
    border-color: ${(props) => `transparent transparent ${props.theme.color_theme.toolTipBackgoundColor} transparent`};
  }
`;
