import React from "react";
import htmlIcon from "../html.png"
const HtmlIcon = () => {
  return (
    <>
    <img src={htmlIcon} alt="html"/>
    </>
  );
};

export default HtmlIcon;
