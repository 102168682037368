import React from "react";

const SharePointIcon = () => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			style={{ enableBackground: 'new 0 0 24 24' }}
			xmlSpace="preserve"
		>
			<path d="M7.6,13.8L7.6,13.8L7.6,13.8C7.4,14,7.1,14.1,6.7,14c-0.3,0-0.5-0.1-0.8-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1
	c0.3,0.1,0.7,0.2,1,0.2v0l0,0c0.1,0,0.2,0,0.4,0h0c0.6,0,1.1-0.2,1.5-0.6c0.2-0.2,0.3-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7v0
	c0-0.6-0.3-1-0.6-1.3c-0.3-0.3-0.7-0.4-1-0.5l0,0l0,0c-0.4-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0.1-0.1l0,0l0,0C6.6,11,6.8,10.9,7.1,11l0,0l0,0c0.3,0.1,0.5,0.1,0.8,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1
	c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
	C8.5,10.1,8.5,10,8.4,10c-0.3-0.1-0.7-0.3-1-0.3c-0.7-0.2-1.3,0-1.8,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.1,0.4-0.1,0.6h0v0
	c0,0.6,0.3,1,0.6,1.3c0.3,0.3,0.7,0.4,1,0.5l0,0l0,0c0.4,0.1,0.6,0.2,0.7,0.3c0.1,0.1,0.1,0.1,0.1,0.2C7.6,13.7,7.6,13.7,7.6,13.8
	C7.6,13.8,7.6,13.8,7.6,13.8z"/>
			<path d="M21.2,10.1c-0.7-0.9-1.7-1.6-2.7-1.9c-0.1-1.4-0.7-2.7-1.7-3.7c-1-1.1-2.5-1.8-4-1.9c-0.2,0-0.3,0-0.5,0
	C11,2.6,9.6,3,8.6,3.8c-1.1,0.8-1.9,2-2.3,3.3H3c-0.4,0-0.7,0.1-1,0.4C1.7,7.8,1.6,8.1,1.6,8.5v8.2c0,0.4,0.1,0.7,0.4,0.9
	C2.3,17.9,2.6,18,3,18h5.4c0,0,0,0,0,0.1c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.8,1.3,2.9,1.4c0.1,0,0.2,0,0.3,0c1,0,2-0.3,2.8-0.9
	c0.8-0.6,1.4-1.5,1.6-2.4c1.4-0.1,2.7-0.7,3.6-1.7c1.1-1,1.6-2.4,1.6-3.9C22.4,12.3,22,11.1,21.2,10.1z M16.7,7.9
	c-0.8,0-1.5,0.2-2.3,0.5c-0.6,0.3-1.2,0.6-1.7,1.1v-1c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.2-0.6-0.4-1-0.4H7.8c0.3-0.8,0.8-1.6,1.5-2.1
	c0.8-0.7,1.9-1,3-1c0,0,0,0,0,0c1.2,0,2.4,0.5,3.3,1.3C16.4,6,16.9,6.9,17,7.9C16.9,7.9,16.8,7.9,16.7,7.9z M9.7,18h1.6
	c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9v-1.6c0,0,0,0,0.1,0c0.8,0,1.6,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.1s-0.3,1.5-0.9,2.1
	c-0.6,0.6-1.4,0.9-2.2,0.9c-0.8,0-1.6-0.3-2.2-0.9C10.1,19.6,9.7,18.8,9.7,18C9.7,18,9.7,18,9.7,18z M12.7,13.7v-1.8
	c0.3-0.7,0.8-1.3,1.3-1.7c0.6-0.5,1.3-0.8,2.1-0.8c0.2,0,0.3,0,0.5,0c0.6,0,1.2,0.1,1.7,0.4c0.7,0.3,1.3,0.8,1.8,1.4
	c0.4,0.6,0.7,1.3,0.8,2.1c0.1,0.7-0.1,1.5-0.4,2.2C20.2,16,19.7,16.6,19,17c-0.5,0.3-1.2,0.6-1.8,0.6c-0.1-1-0.5-1.9-1.3-2.6
	C15.1,14.2,14,13.7,12.7,13.7C12.8,13.7,12.7,13.7,12.7,13.7z M11.3,8.6v8l-8.3,0v-8L11.3,8.6z"/>
		</svg>
	);
};

export default SharePointIcon;
