import React from "react";

const CompanyBadgeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M7.25 17H8.75V14.75H11V13.25H8.75V11H7.25V13.25H5V14.75H7.25V17ZM13 13.25H19V12.25H13V13.25ZM13 16.25H17V15.25H13V16.25ZM3.5 21C3.1 21 2.75 20.85 2.45 20.55C2.15 20.25 2 19.9 2 19.5V7.5C2 7.1 2.15 6.75 2.45 6.45C2.75 6.15 3.1 6 3.5 6H9.75V2.5C9.75 2.1 9.9 1.75 10.2 1.45C10.5 1.15 10.8534 1 11.2603 1H12.7397C13.1466 1 13.5 1.15 13.8 1.45C14.1 1.75 14.25 2.1 14.25 2.5V6H20.5C20.9 6 21.25 6.15 21.55 6.45C21.85 6.75 22 7.1 22 7.5V19.5C22 19.9 21.85 20.25 21.55 20.55C21.25 20.85 20.9 21 20.5 21H3.5ZM3.5 19.5H20.5V7.5H14.25V8.25C14.25 8.71667 14.1 9.08333 13.8 9.35C13.5 9.61667 13.1466 9.75 12.7397 9.75H11.2603C10.8534 9.75 10.5 9.61667 10.2 9.35C9.9 9.08333 9.75 8.71667 9.75 8.25V7.5H3.5V19.5ZM11.25 8.25H12.75V2.5H11.25V8.25Z" />
    </svg>

  );
};

export default CompanyBadgeIcon;
