import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ClientListWrapper,
  ClientListOptionsWrapper,
  ClientListTitle,
  ClientListTitleIcon
} from "./ClientList.styled";
import Search from "../../components/Search/Search";
import Button from "../../components/Button/Button";
// import FilterIcon from "../../assets/svgs/FilterIcon";
// import SortIcon from "../../assets/svgs/SortIcon";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import AddClientModal from "../AddClientModal/AddClientModal";
import ListTable from "../ListTable/ListTable";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import useKeyPress from "../../hooks/useKeyPress";
import speechFormat from "../../helpers/speechFormat";
import TitleComponent from "../../components/TitleComponent/TitleComponent";

const ClientList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [input, setInput] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [loadingSuccessPopup, setLoadingSuccessPopup] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const fetchInput = (val) => {
    setInput(val);
  };

  const handleSuccess = ({ loading }) => {
    setShowSuccessPopup(true);
    setLoadingSuccessPopup(loading);
    closeModal();
  };

  const overlayName = "AddClientModal";
  useKeyPress("A", ["altKey"], () => setModalOpen(true));
  useKeyPress("Escape", [], closeModal, true, overlayName);

  return (
    <>
      <ClientListWrapper>
        <div style={{ display: "flex" }}>
          <ClientListTitleIcon>Companies</ClientListTitleIcon>
          <ClientListTitle></ClientListTitle>
          <TitleComponent />
        </div>
        <ClientListOptionsWrapper>
          <Search
            width={400}
            thres={1300}
            placeholdertext={"Search Companies..."}
            righticons={true}
            onChange={fetchInput}
            value={input}
            searchCommand={{
              text: "[G]",
              key: "G",
              subkey: [],
            }}
            voiceCommand={{
              text: "[Alt+G]",
              key: "G",
              subkey: ["altKey"],
            }}
            speech={speechFormat({
              action: "search",
              item: "companies",
            })}
          />
          <Button
            type="tertiary"
            text="+ Add a Company"
            accessKeyText="[Alt+A]"
            onClick={() => setModalOpen(true)}
            buttonStyle={{ marginLeft: 10 }}
            data-speech={speechFormat({
              key: "Alt + A",
              action: "",
              item: "Add a Company",
            })}
          />
        </ClientListOptionsWrapper>
      </ClientListWrapper>
      {modalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <AddClientModal
            onClose={closeModal}
            onSuccess={handleSuccess}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}
      <ListTable searchInput={input} setSearchInput={setInput} />

      {showSuccessPopup && (
        <SuccessPopup
          msg_processing={"Adding new Company details, please wait..."}
          msg_done={"Company is added successfully."}
          onClose={() => setShowSuccessPopup(false)}
          loading={loadingSuccessPopup}
        />
      )}
    </>
  );
};

ClientList.propTypes = {};

ClientList.defaultProps = {};

export default ClientList;
