import { createSlice } from "@reduxjs/toolkit";

const A11ySlice = createSlice({
  name: "a11y",
  initialState: {
    overlay: false,
    high_contrast: false,
    dark_mode: false,
    screen_reader: true,
    font_delta: 0,
    overlay_name: "",
    active_list: "",
    loginToolTip: true,
    optionsToolTip: true
  },
  reducers: {
    toggleContrastMode(state, action) {
      state.high_contrast = action.payload;
    },
    toggleDarkMode(state, action) {
      state.dark_mode = action.payload;
    },
    toggleScreenReader(state, action) {
      state.screen_reader = action.payload;
    },
    incFontDelta(state) {
      state.font_delta = Math.min(4, state.font_delta + 1);
    },
    noFontDelta(state) {
      state.font_delta = 0;
    },
    decFontDelta(state) {
      state.font_delta = Math.max(-4, state.font_delta - 1);
    },
    setOverlay(state, action) {
      state.overlay = action.payload;
    },
    setOverlayName(state, action) {
      state.overlay_name = action.payload;
    },
    setActiveList(state, action) {
      state.active_list = action.payload;
    },
    setLoginToolTip(state, action) {
      state.loginToolTip = action.payload;
    },
    setOptionsToolTip(state, action) {
      state.optionsToolTip = action.payload;
    }
  },
});

export const {
  toggleContrastMode,
  toggleScreenReader,
  incFontDelta,
  noFontDelta,
  decFontDelta,
  setOverlay,
  setOverlayName,
  toggleDarkMode,
  setActiveList,
  setLoginToolTip,
  setOptionsToolTip,
} = A11ySlice.actions;
export default A11ySlice.reducer;
