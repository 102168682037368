import { styled } from "styled-components";

export const Wrapper = styled.div`
display: flex;
justify-content: center;
background-color: ${(props) => props.theme.color_theme.bodyColor};
`;

export const TextWrapper = styled.div`
    color: ${(props) => props.theme.color_theme.color};
    opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 16px;
`


export const ShortcutWrapper = styled.div`
    color: ${(props) => props.theme.color_theme.color};
    opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

