import styled from 'styled-components';

export const AccordionContainer = styled.div`
  // border: 1px solid #ccc;
  margin: 4px;
`;

export const AccordionHeader = styled.div`
  // background-color: ${(props) => props.theme.color_theme.bodyColor};
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 500;
`;

export const TextWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
`;

export const AccordionIconWrapper = styled.div`
  background-color: ${(props) => props.theme.color_theme.tableColor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.color_theme.color};
  position: relative;
`;

export const AccordionContent = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  padding: 4px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
  opacity: 0.6;
`;

export const ExtraTextWrapper = styled.span`
  // margin-left: 4px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  margin-right: 10px;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
`;


export const RightIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  height: inherit;
  right: 0px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
`;