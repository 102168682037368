import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const TableContainer = styled.div `
  width: 500px;
`;

export const UpdatedText = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;