import ApiServer from "./ApiServer";
import RedactApiServer from "./RedactApiServer";

export const getDestinationTemplate = (pharmaName) => {
  return ApiServer({
    method: "GET",
    url: `/v1/templates/destination/${pharmaName}`,
  });
};

export const getSourceTemplate = (pharmaName) => {
  return ApiServer({
    method: "GET",
    url: `/v1/templates/source/${pharmaName}`,
  });
};

export const getProgress = (progressId) => {
  return ApiServer({
    method: "GET",
    url: `/poll/${progressId}`,
  });
};

export const generateDocument = (data,{
  pharma_name,
  output_file_name,
  is_folder 
}) => {
  return ApiServer({
    method: "POST",
    url: `/v2/document/generate`,
    data: data,
    params: {
      pharma_name,
      output_file_name,
      is_folder 
    },
    timeout: 2000000,
  });
};

export const generateStyledDocument = (data,{
  pharma_name,
  output_file_name,
  input_document_name 
}) => {
  return ApiServer({
    method: "POST",
    url: `/v1/document/style`,
    data: data,
    params: {
      pharma_name,
      output_file_name,
      input_document_name 
    },
    timeout: 2000000,
  });
};

export const getGeneratedDocument = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/generated-files`,
  });
};

export const getEntitlement = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/entitlements`,
  });
};

export const getGeneratedDocumentDownloadUrl = (file_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/generated-files/${file_name}`
    // params: { file_name },
  });
};

export const getPreviewPDFFile = (template, name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/preview/templates`,
    responseType: "arraybuffer",
    params: { template, name, mode: "streaming" },
  });
};

export const getPreviewSharepointPDFFile = (pharma_name, file_name,  is_folder=false, template) => {
  return ApiServer({
    method: "GET",
    url: `/v1/preview/source`,
    responseType: "arraybuffer",
    params: { pharma_name, file_name, is_folder, mode: "streaming", template },
  });
};

export const getClientList = () => {
  return ApiServer({
    method: "GET",
    url: `/list/clients`,
  });
};

export const getMembersList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/list/clients/users`,
    params: { pharma_name },
  });
};

export const getClientInfo = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/client/info`,
    params: { pharma_name },
  });
};

export const createClient = (pharma_name, data, pharma_details = {}) => {
  return ApiServer({
    method: "POST",
    url: `/client`,
    data: data,
    params: { pharma_name, ...pharma_details },
  });
};

export const addClientUsers = (pharma_name, data) => {
  return ApiServer({
    method: "POST",
    url: `/client/users/add`,
    data: data,
    params: { pharma_name },
  });
};

export const EditClientInfo = ( pharma_name, data, pharma_details = {}) => {
  return ApiServer({
    method: "PUT",
    url: `/clients/info`,
    data: data,
    params: { pharma_name, ...pharma_details },
  });
};

export const deleteClientUsers = (pharma_name, data) => {
  return ApiServer({
    method: "POST",
    url: `/client/users/delete`,
    data: data,
    params: { pharma_name },
  });
};

export const getPharmaClientList = () => {
  return ApiServer({
    method: "GET",
    url: `/pharma_clients`,
  });
};

export const getProtocolDocumentList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/document/source/list/${pharma_name}`,
  });
};

export const getInputDocumentList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/document/style/list/${pharma_name}`,
  });
};

export const getConfigurationDocumentList = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/config/styles`,
  });
};

export const searchUser = (value) => {
  return ApiServer({
    method: "GET",
    url: `/v2/client/users/search`,
    params: { value },
  });
};

export const getCsv = (args) => {
  return ApiServer({
    method: "POST",
    url: `/download-csv?args=${args}`,
  });
};

export const getAuditList = (search_query, args,page) => {
  return ApiServer({
    method: "POST",
    url: `/export_audit_logs?search_query=${search_query}&args=${args}&page=${page}&page_size=8`,
  });
};

export const uploadSourceDocument = (pharma_name,data) => {
  return ApiServer({
    method: "POST",
    url: "v1/document/source/upload",
    params: { pharma_name },
    data
  })
}
export const uploadStyleDocument = (pharma_name,data) => {
  return ApiServer({
    method: "POST",
    url: "v1/document/style/upload",
    params: { pharma_name },
    data
  })
}


export const uploadRedactDocument = (pharma_name, redaction_type, remove_metadata, data) => {
  return RedactApiServer({
    method: "POST",
    url: `redact/?pharma_name=${pharma_name}&redaction_type=${redaction_type?.toLowerCase().split(" ").join('_')}&remove_metadata=${remove_metadata?.toLowerCase()}`,
    data
  })
}

export const getPDFList = (pharma_name, folder_name) => {
  return RedactApiServer({
    method: "GET",
    url: `document/source/list/${pharma_name}/${folder_name}`,
  })
}

export const downloadSharepointPdf = (pharma_name, folder_name, file_name) => {
  return RedactApiServer({
    method: "GET",
    url: `download/source?pharma_name=${pharma_name}&folder_name=${folder_name}&file_name=${file_name}`,
    responseType: 'blob'
  })
}