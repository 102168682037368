import React from "react";

const NextIcon = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7508 11.9752L8.80078 7.0252L9.87578 5.9502L15.9008 11.9752L9.87578 18.0002L8.80078 16.9252L13.7508 11.9752Z"
      />
    </svg>
  );
};

export default NextIcon;
