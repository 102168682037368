import React, { useState, useEffect } from "react";
import {
  TableWrapper,
  TableHeading,
  HeadingCell,
  TableRows,
  PaginationContainer,
  ListFooterWrapper,
  FooterText,
  TableInfoText,
} from "./AuditListTable.styled";
import { getAuditList } from "../../apis/ApiServerRequests";
import { connect, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import { setActiveList } from "../../store/slices/A11ySlice";
import useKeyPress from "../../hooks/useKeyPress";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import NoSearchFound from "../../components/NoSearchFound/NoSearchFound";
import Button from "../../components/Button/Button";
import speechFormat from "../../helpers/speechFormat";
import useSpeechSynthesis from "../../hooks/useSpeechSynthesis";
import AuditTableRow from "../../components/AuditTableRow/AuditTableRow";

const AuditListTable = ({
  client_count,
  searchInput,
  setSearchInput,
  is_contrast,
  dispatch,
  currentTab,
  currentPage,
  setCurrentPage,
}) => {
  const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);

  const perPage = 7 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60));
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [reading, setReading] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const active_list = useSelector((state) => state?.a11y?.active_list);

  useEffect(() => {
    if (!speaking && reading) setReading(false);
  }, [speaking]);

  useEffect(() => {
    if (selectedRow !== null) dispatch(setActiveList("client_list"));
  }, [selectedRow]);

  useEffect(() => {
    if (active_list !== "client_list") setSelectedRow(null);
  }, [active_list]);

  useEffect(() => {
    setLoading(true);
    let fetchList =  "document"

    if (currentTab === "client log") {
      fetchList = "client"
    }
    
    if (currentTab === "format log") {
      fetchList = "format"
    }
    getAuditList(searchInput || "", fetchList, currentPage)
      .then(({ data }) => {
        setClientList(data.data);
        setItemList(data.data);
        // setTotalPages(Math.ceil(data.data.length / perPage));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentTab, currentPage, searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput]);

  const getNewAuditList = (page) => {
    setLoading(true);
    let fetchList =  "document"

    if (currentTab === "client log") {
      fetchList = "client"
    }
    
    if (currentTab === "format log") {
      fetchList = "format"
    }

    return getAuditList(searchInput || "", fetchList, page).then(({ data }) => {
      setLoading(false);
      if (data && data.data && data.data.length > 0) {
        setClientList(data.data);
        setItemList(data.data);
        return true;
      } else {
        return false;
      }
    });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      getNewAuditList(prevPage).then((hasData) => {
        if (hasData) {
          setCurrentPage(prevPage);
        }
      });
    }
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    getNewAuditList(nextPage).then((hasData) => {
      if (hasData) {
        setCurrentPage(nextPage);
      }
    });
  };

  const handleArrowUP = () => {
    if (active_list === "client_list")
      setSelectedRow(Math.max(0, selectedRow - 1));
  };

  const handleArrowDown = () => {
    let l = itemList.length;
    if (active_list === "client_list")
      setSelectedRow(Math.min(l - 1, selectedRow + 1));
  };

  const handleClientListRead = () => {
    if (reading) {
      cancelSpeaking();
      setReading(false);
      return;
    }
    let speech = "";
    itemList.forEach(({ company_name }, i) => {
      speech += speechFormat({
        key: `Alt + ${i + 1}`,
        action: "select",
        item: company_name,
      });
      speech += ". ";
    });
    setReading(true);
    speak(speech);
  };

  useKeyPress("C", [], handleClientListRead);
  useKeyPress("ArrowUp", [], handleArrowUP);
  useKeyPress("ArrowDown", [], handleArrowDown);
  useKeyPress("K", ["altKey"], () => setSearchInput(""));

  return (
    <>
      {currentTab === "generation log" &&  (
        <>
          <TableWrapper>
            <TableHeading>
              <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
                Log ID
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
                Timestamp
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
                Company Name
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"18.2%"}>
                Generated Document
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"10.2%"}>
                Source <span>*</span>
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
                Destination
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
                Status
              </HeadingCell>
            </TableHeading>
            {itemList && (
              <TableRows>
                {loading ? (
                  <div style={{ width: "100%" }}>
                    <SkeletonLoader count={perPage} height={60} />
                  </div>
                ) : (
                  <>
                    {searchInput && itemList.length === 0 ? (
                      <NoSearchFound
                        height={"336px"}
                        resetSearch={() => setSearchInput("")}
                      />
                    ) : (
                      itemList.map((row, index, array) => (
                        <AuditTableRow
                          key={index}
                          index={index}
                          row={row}
                          islast={index === array.length - 1}
                          onSelectRow={setSelectedRow}
                          isSelected={selectedRow === index}
                          currentTab={currentTab}
                        />
                      ))
                    )}
                  </>
                )}
              </TableRows>
            )}
          </TableWrapper>
          <TableInfoText>
            <span>*</span>Shortcuts for the selected row are{" "}
            <span>Download Generated Document [1],</span>
            <span>View Source files [2],</span>
            <span>Download Destination Document [3]</span>
          </TableInfoText>
        </>
      )}
      
      {currentTab === "format log" && (
         <>
         <TableWrapper>
           <TableHeading>
             <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
               Log ID
             </HeadingCell>
             <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
               Timestamp
             </HeadingCell>
             <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
               Company Name
             </HeadingCell>
             <HeadingCell is_contrast={is_contrast} width={"18.2%"}>
               Generated Document
             </HeadingCell>
             <HeadingCell is_contrast={is_contrast} width={"10.2%"}>
               Source <span>*</span>
             </HeadingCell>
             <HeadingCell is_contrast={is_contrast} width={"14.2%"}>
               Status
             </HeadingCell>
           </TableHeading>
           {itemList && (
             <TableRows>
               {loading ? (
                 <div style={{ width: "100%" }}>
                   <SkeletonLoader count={perPage} height={60} />
                 </div>
               ) : (
                 <>
                   {searchInput && itemList.length === 0 ? (
                     <NoSearchFound
                       height={"336px"}
                       resetSearch={() => setSearchInput("")}
                     />
                   ) : (
                     itemList.map((row, index, array) => (
                       <AuditTableRow
                         key={index}
                         index={index}
                         row={row}
                         islast={index === array.length - 1}
                         onSelectRow={setSelectedRow}
                         isSelected={selectedRow === index}
                         currentTab={currentTab}
                       />
                     ))
                   )}
                 </>
               )}
             </TableRows>
           )}
         </TableWrapper>
         <TableInfoText>
           <span>*</span>Shortcuts for the selected row are{" "}
           <span>Download Generated Document [1],</span>
           <span>View Source files [2],</span>
           <span>Download Destination Document [3]</span>
         </TableInfoText>
       </>
      )}
      
      {currentTab === "client log" && (
        <>
          <TableWrapper>
            <TableHeading>
              <HeadingCell is_contrast={is_contrast} width={"20%"}>
                Company ID
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"20%"}>
                Timestamp
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"20%"}>
                Company Name
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"20%"}>
                Method
              </HeadingCell>
              <HeadingCell is_contrast={is_contrast} width={"20%"}>
                Status
              </HeadingCell>
            </TableHeading>
            {itemList && (
              <TableRows>
                {loading ? (
                  <div style={{ width: "100%" }}>
                    <SkeletonLoader count={perPage} height={60} />
                  </div>
                ) : (
                  <>
                    {searchInput && itemList.length === 0 ? (
                      <NoSearchFound
                        height={"336px"}
                        resetSearch={() => setSearchInput("")}
                      />
                    ) : (
                      itemList.map((row, index, array) => (
                        <AuditTableRow
                          key={index}
                          index={index}
                          row={row}
                          islast={index === array.length - 1}
                          onSelectRow={setSelectedRow}
                          isSelected={selectedRow === index}
                          currentTab={currentTab}
                        />
                      ))
                    )}
                  </>
                )}
              </TableRows>
            )}
          </TableWrapper>
          <TableInfoText>
            <span>*</span>Shortcuts for the selected row are{" "}
            <span>View Company Information [1],</span>
          </TableInfoText>
        </>
      )}

      <ListFooterWrapper>
        <FooterText>
          <Button
            type="ghost"
            text={reading ? "Cancel Read List" : "Read List"}
            accessKeyText="[ C ]"
            buttonStyle={{ padding: "4px 0px" }}
            data-speech={speechFormat({
              key: "C",
              action: reading ? "Cancel Reading of" : "read",
              item: "Company List",
            })}
            onClick={handleClientListRead}
          />
        </FooterText>
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            onPrev={handlePrevPage}
            onNext={handleNextPage}
            prevAccessKey={{
              text: "[Ctrl + ,]",
              key: ",",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + comma",
                action: "goto",
                item: "Previous page of Company list",
              }),
            }}
            nextAccessKey={{
              text: "[Ctrl + .]",
              key: ".",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + fullstop",
                action: "goto",
                item: "Next page of Company list",
              }),
            }}
            audits="true"
          />
        </PaginationContainer>
      </ListFooterWrapper>
    </>
  );
};

AuditListTable.propTypes = {};

AuditListTable.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    client_count: state?.client?.client_count,
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(AuditListTable);
