import React from "react";

const CalenderIcon = ({ arrowDown }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M14.15 22V20.5H19.5V9.75H4.5V15.25H3V5C3 4.6 3.15 4.25 3.45 3.95C3.75 3.65 4.1 3.5 4.5 3.5H6.125V2H7.75V3.5H16.25V2H17.875V3.5H19.5C19.9 3.5 20.25 3.65 20.55 3.95C20.85 4.25 21 4.6 21 5V20.5C21 20.9 20.85 21.25 20.55 21.55C20.25 21.85 19.9 22 19.5 22H14.15ZM4.5 8.25H19.5V5H4.5V8.25Z" />
            {arrowDown ?
                <path d="M3.00078 18.75L4.05078 17.7L6.90078 20.525L6.90078 14L8.40078 14L8.40078 20.525L11.2508 17.7L12.3008 18.75L7.65078 23.4L3.00078 18.75Z" />
                :
                <path d="M12.3 18.6499L11.25 19.6999L8.4 16.8749L8.4 23.3999L6.9 23.3999L6.9 16.8749L4.05 19.6999L3 18.6499L7.65 13.9999L12.3 18.6499Z" />
            }       </svg>
    );
};

export default CalenderIcon;
