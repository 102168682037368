import React from "react";

const BusinessIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 21C3.1 21 2.75 20.85 2.45 20.55C2.15 20.25 2 19.9 2 19.5V7.5C2 7.1 2.15 6.75 2.45 6.45C2.75 6.15 3.1 6 3.5 6H8V3.5C8 3.1 8.15 2.75 8.45 2.45C8.75 2.15 9.1 2 9.5 2H14.5C14.9 2 15.25 2.15 15.55 2.45C15.85 2.75 16 3.1 16 3.5V6H20.5C20.9 6 21.25 6.15 21.55 6.45C21.85 6.75 22 7.1 22 7.5V19.5C22 19.9 21.85 20.25 21.55 20.55C21.25 20.85 20.9 21 20.5 21H3.5ZM3.5 19.5H20.5V7.5H3.5V19.5ZM9.5 6H14.5V3.5H9.5V6Z"
            />
        </svg>
    );
};

export default BusinessIcon;
