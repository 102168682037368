import React from "react";
import PropTypes from "prop-types";
import {
  AccessKey,
  Count,
  CountWrapper,
  TotalCount,
  Wrapper,
} from "./Pagination.styled";
import Button from "../Button/Button";
import PrevPageIcon from "../../assets/svgs/PrevPageIcon";
import PrevIcon from "../../assets/svgs/PrevIcon";
import NextIcon from "../../assets/svgs/NextIcon";
import NextPageIcon from "../../assets/svgs/NextPageIcon";
import useKeyPress from "../../hooks/useKeyPress";

const Pagination = ({
  currentPage,
  totalPages,
  onFirstPage,
  onLastPage,
  onNext,
  onPrev,
  firstAccessKey,
  prevAccessKey,
  nextAccessKey,
  lastAccesskey,
  isOverlay,
  overlayName,
  audits
}) => {
  useKeyPress(
    firstAccessKey?.key,
    firstAccessKey?.subkey,
    onFirstPage,
    isOverlay,
    overlayName
  );
  useKeyPress(
    prevAccessKey?.key,
    prevAccessKey?.subkey,
    onPrev,
    isOverlay,
    overlayName
  );
  useKeyPress(
    nextAccessKey?.key,
    nextAccessKey?.subkey,
    onNext,
    isOverlay,
    overlayName
  );
  useKeyPress(
    lastAccesskey?.key,
    lastAccesskey?.subkey,
    onLastPage,
    isOverlay,
    overlayName
  );

  return (
    <Wrapper>
      {!audits && (
      <Button
        type="ghost"
        accessKeyText={firstAccessKey?.text}
        buttonStyle={{ padding: "4px 0px" }}
        iconStyle={{ marginRight: "6px" }}
        icon={<PrevPageIcon />}
        onClick={onFirstPage}
        data-speech={firstAccessKey?.speech}
      />
    )}
      <Button
        type="ghost"
        accessKeyText={prevAccessKey?.text}
        buttonStyle={{ padding: "4px 0px" }}
        iconStyle={{ marginRight: "6px" }}
        icon={<PrevIcon />}
        onClick={onPrev}
        data-speech={prevAccessKey?.speech}
      />
      
      <CountWrapper>
        <Count>{currentPage}</Count>
        {!audits && (
        <TotalCount>of {totalPages}</TotalCount>
      )}
      </CountWrapper>
      <Button
        type="ghost"
        accessKeyText={nextAccessKey?.text}
        buttonStyle={{ padding: "4px 0px" }}
        iconStyle={{ marginRight: "6px" }}
        icon={<NextIcon />}
        onClick={onNext}
        data-speech={nextAccessKey?.speech}
      />
      {!audits && (
      <Button
        type="ghost"
        accessKeyText={lastAccesskey?.text}
        buttonStyle={{ padding: "4px 0px" }}
        iconStyle={{ marginRight: "6px" }}
        icon={<NextPageIcon />}
        onClick={onLastPage}
        data-speech={lastAccesskey?.speech}
      />
    )}
    </Wrapper>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onFirstPage: PropTypes.func.isRequired,
  onLastPage: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default Pagination;
