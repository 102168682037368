import styled, { css } from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  height: ${(props) => props.RightSelectButton ? "" : "58px"};
  ${({ selected }) =>
    selected &&
    css`
      border-radius: 8px;
      background-color: ${(props) => props.theme.color_theme.selectedBackgroundColor};
      
    `}
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => props.height};
`;

export const RowJustify = styled.div`
  display: flex;
  width: ${(props) => props.RightSelectButton ? "100%" : "calc(100% - 70px)"};
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  width: ${(props) => props.RightSelectButton ? "calc(80% - 10px)" : "calc(80% - 10px)"};
  ${({ selected }) =>
    selected &&
    css`
      color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" ? props.theme.color_theme.bodyColor : props.theme.color_theme.color};
        `}
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  width: calc(20% - 10px);
  align-items: flex-end;
  ${({ selected }) =>
    selected &&
    css`
      color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" ? props.theme.color_theme.bodyColor : props.theme.color_theme.color};
        `}
`;

export const TitleText = styled.div`
  ${Fonts.Body2};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  color: inherit;

`;
export const SmallText = styled.div`
  ${Fonts.Small};
  color: ${(props) => props.RightSelectButton ? props.theme.color_theme.color : "#666666"};
  opacity:  ${(props) => props.RightSelectButton && props.selected ? "0.6" : props.RightSelectButton ? "0.4" : ""};
  margin-bottom: ${(props) => props.RightSelectButton ? "" : "12px"};
  text-transform: uppercase;
  width: max-content;
  ${({ selected }) =>
    selected &&
    css`
      color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" ? props.theme.color_theme.bodyColor : props.theme.color_theme.color};
        `}
`;


export const SelectTextWrapper = styled.div`
${Fonts.Small};
color: ${(props) => props.theme.color_theme.color};
width: max-content;
opacity: ${(props) => props.selected ? "0.6" : "0.4"};
display: flex;
font-style: ${(props) => props.selected ? "italic" : "inherit"};
${({ selected }) =>
    selected &&
    css`
    color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" ? props.theme.color_theme.bodyColor : props.theme.color_theme.color};
      `}
`;
