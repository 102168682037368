import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ModalContent, ModalOverlay } from "./ModalWrapper.styled";
import { useDispatch } from "react-redux";
import { setOverlay, setOverlayName } from "../../store/slices/A11ySlice";
const FocusTrap = require("focus-trap-react");

const ModalWrapper = ({ children, modalStyle, overlayName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay(true));
    dispatch(setOverlayName(overlayName));
    return () => {
      dispatch(setOverlay(false));
      dispatch(setOverlayName(""));
    };
  }, []);

  return (
    <FocusTrap>
      <ModalOverlay>
        {<ModalContent style={modalStyle}>{children}</ModalContent>}
      </ModalOverlay>
    </FocusTrap>
  );
};

ModalWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalWrapper;
