import React from "react";

const UserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 11.9746C10.9 11.9746 10 11.6246 9.3 10.9246C8.6 10.2246 8.25 9.32461 8.25 8.22461C8.25 7.12461 8.6 6.22461 9.3 5.52461C10 4.82461 10.9 4.47461 12 4.47461C13.1 4.47461 14 4.82461 14.7 5.52461C15.4 6.22461 15.75 7.12461 15.75 8.22461C15.75 9.32461 15.4 10.2246 14.7 10.9246C14 11.6246 13.1 11.9746 12 11.9746ZM4 19.9996V17.6496C4 17.0163 4.15833 16.4746 4.475 16.0246C4.79167 15.5746 5.2 15.2329 5.7 14.9996C6.81667 14.4996 7.8875 14.1246 8.9125 13.8746C9.9375 13.6246 10.9667 13.4996 12 13.4996C13.0333 13.4996 14.0583 13.6288 15.075 13.8871C16.0917 14.1454 17.1577 14.5179 18.273 15.0045C18.7947 15.2399 19.213 15.5813 19.5278 16.0286C19.8426 16.4759 20 17.0163 20 17.6496V19.9996H4ZM5.5 18.4996H18.5V17.6496C18.5 17.3829 18.4208 17.1288 18.2625 16.8871C18.1042 16.6454 17.9083 16.4663 17.675 16.3496C16.6083 15.8329 15.6333 15.4788 14.75 15.2871C13.8667 15.0954 12.95 14.9996 12 14.9996C11.05 14.9996 10.125 15.0954 9.225 15.2871C8.325 15.4788 7.35 15.8329 6.3 16.3496C6.06667 16.4663 5.875 16.6454 5.725 16.8871C5.575 17.1288 5.5 17.3829 5.5 17.6496V18.4996ZM12 10.4746C12.65 10.4746 13.1875 10.2621 13.6125 9.83711C14.0375 9.41211 14.25 8.87461 14.25 8.22461C14.25 7.57461 14.0375 7.03711 13.6125 6.61211C13.1875 6.18711 12.65 5.97461 12 5.97461C11.35 5.97461 10.8125 6.18711 10.3875 6.61211C9.9625 7.03711 9.75 7.57461 9.75 8.22461C9.75 8.87461 9.9625 9.41211 10.3875 9.83711C10.8125 10.2621 11.35 10.4746 12 10.4746Z" />
        </svg>
    );
};

export default UserIcon;
