import styled from "styled-components";

export const A11YSideDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TopNavWrapper = styled.div`
  display: flex;
  padding: 16px 4px 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
`;

export const TopNavText = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const TopNavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const ItemWrapper = styled.div`
    padding: 16px 24px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BottomNavWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
