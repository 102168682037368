import React from "react";

const SpeakerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M15.2084 10.6247V9.37467H18.3334V10.6247H15.2084ZM16.2501 16.6663L13.7292 14.7913L14.4792 13.7913L17.0001 15.6663L16.2501 16.6663ZM14.5417 6.18717L13.7917 5.18717L16.2501 3.33301L17.0001 4.33301L14.5417 6.18717ZM4.37508 15.833V12.4997H2.91675C2.573 12.4997 2.27873 12.3773 2.03394 12.1325C1.78914 11.8877 1.66675 11.5934 1.66675 11.2497V8.74967C1.66675 8.40592 1.78914 8.11165 2.03394 7.86686C2.27873 7.62207 2.573 7.49967 2.91675 7.49967H6.66675L10.8334 4.99967V14.9997L6.66675 12.4997H5.62508V15.833H4.37508ZM11.6667 12.7913V7.20801C12.0417 7.54134 12.3438 7.94759 12.573 8.42676C12.8022 8.90592 12.9167 9.43023 12.9167 9.99967C12.9167 10.5691 12.8022 11.0934 12.573 11.5726C12.3438 12.0518 12.0417 12.458 11.6667 12.7913ZM2.91675 8.74967V11.2497H7.00008L9.58342 12.7913V7.20801L7.00008 8.74967H2.91675Z"
      />
    </svg>
  );
};

export default SpeakerIcon;
