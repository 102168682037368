import React from "react";

const NoPreviewIcon = ({ docColor, docFoldColor, rectColor, markColor }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="64" viewBox="0 0 60 64" >
            <path d="M11 8.5C11 4.08172 14.5817 0.5 19 0.5H41L60 19.5V55.5C60 59.9183 56.4183 63.5 52 63.5H19C14.5817 63.5 11 59.9183 11 55.5V8.5Z" fill={docColor ? docColor : "#E0E0E0"} />
            <path d="M41 0.5L60 19.5H44C42.3431 19.5 41 18.1569 41 16.5V0.5Z" fill={docFoldColor ? docFoldColor : "#CFCFCF"} />
            <path d="M0 29.5C0 28.3954 0.895431 27.5 2 27.5H46C47.1046 27.5 48 28.3954 48 29.5V45.5C48 46.6046 47.1046 47.5 46 47.5H2C0.895432 47.5 0 46.6046 0 45.5V29.5Z" fill={rectColor ? rectColor : "#AAAAAA"} />
            <rect x="6" y="32" width="36" height="4" fill={markColor ? markColor : "#F2F2F2"} />
            <rect x="6" y="39" width="28" height="4" fill={markColor ? markColor : "#F2F2F2"} />
        </svg>

    );
};

export default NoPreviewIcon;