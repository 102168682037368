import React from "react";
import pdfIc from "../pdf.png"
const PdfIcon = () => {
  return (
    <>
    <img src={pdfIc} alt="pdf"/>
    </>
  );
};

export default PdfIcon;
