import React from "react";
import PropTypes from "prop-types";
import {
  LogoWrapper,
  LogoIconWrapper,
  LogoContent,
  LogoTitle,
  LogoSubtitle,
} from "./Logo.styled";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import LogoIcon from "../../assets/svgs/LogoIcon";

const Logo = ({ width, height, is_contrast, dark_mode }) => {
  const navigate = useNavigate();

  const onClickNavigateToHome = () => {
    navigate("/");
  };
  return (
    <LogoWrapper onClick={onClickNavigateToHome} >
      <LogoIconWrapper width={width} height={height} >
        <LogoIcon contrast={is_contrast} dark_mode={dark_mode} />
      </LogoIconWrapper>
      {/* <LogoContent>
        <LogoTitle>AgileWriter</LogoTitle>
        <LogoSubtitle>Admin Portal</LogoSubtitle>
      </LogoContent> */}
    </LogoWrapper>
  )
}

Logo.propTypes = {};

Logo.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
  };
};

export default connect(mapStateToProps)(Logo);
