import React from "react";

const SelectIcfIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M12.35 16.5C11.6833 17.1667 10.875 17.5 9.925 17.5C8.975 17.5 8.16667 17.1667 7.5 16.5C6.83333 15.8333 6.5 15.0292 6.5 14.0875C6.5 13.1458 6.83333 12.3417 7.5 11.675L11.65 7.5C12.3167 6.83333 13.125 6.5 14.075 6.5C15.025 6.5 15.8333 6.83333 16.5 7.5C17.1667 8.16667 17.5 8.97083 17.5 9.9125C17.5 10.8542 17.1667 11.6583 16.5 12.325L12.35 16.5ZM8.55 15.425C8.93333 15.8083 9.3875 16.0042 9.9125 16.0125C10.4375 16.0208 10.8917 15.8333 11.275 15.45L12.825 13.875L10.125 11.175L8.55 12.725C8.16667 13.1073 7.975 13.557 7.975 14.0742C7.975 14.5914 8.16667 15.0417 8.55 15.425ZM15.45 8.575C15.0667 8.19167 14.6125 7.99583 14.0875 7.9875C13.5625 7.97917 13.1083 8.16667 12.725 8.55L11.175 10.125L13.875 12.825L15.45 11.275C15.8333 10.8917 16.0208 10.4417 16.0125 9.925C16.0042 9.40833 15.8167 8.95833 15.45 8.575ZM4.5 21C4.0875 21 3.73438 20.8531 3.44063 20.5594C3.14688 20.2656 3 19.9125 3 19.5V4.5C3 4.0875 3.14688 3.73438 3.44063 3.44062C3.73438 3.14688 4.0875 3 4.5 3H9.625C9.70833 2.41667 9.975 1.9375 10.425 1.5625C10.875 1.1875 11.4 1 12 1C12.6 1 13.125 1.1875 13.575 1.5625C14.025 1.9375 14.2917 2.41667 14.375 3H19.5C19.9125 3 20.2656 3.14688 20.5594 3.44062C20.8531 3.73438 21 4.0875 21 4.5V19.5C21 19.9125 20.8531 20.2656 20.5594 20.5594C20.2656 20.8531 19.9125 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5ZM12 4.075C12.2333 4.075 12.4375 3.9875 12.6125 3.8125C12.7875 3.6375 12.875 3.43333 12.875 3.2C12.875 2.96667 12.7875 2.7625 12.6125 2.5875C12.4375 2.4125 12.2333 2.325 12 2.325C11.7667 2.325 11.5625 2.4125 11.3875 2.5875C11.2125 2.7625 11.125 2.96667 11.125 3.2C11.125 3.43333 11.2125 3.6375 11.3875 3.8125C11.5625 3.9875 11.7667 4.075 12 4.075Z" />
        </svg>
    );
};

export default SelectIcfIcon;
