import React from "react";

const NextPageIcon = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18.0002V6.00024H19V18.0002H17.5ZM6 18.0002V6.00024L14.65 12.0002L6 18.0002ZM7.5 15.1252L12.025 12.0002L7.5 8.87524V15.1252Z"
      />
    </svg>
  );
};

export default NextPageIcon;
