import styled from "styled-components";

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const SuccessPopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  width: 420px;
  height: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px 32px 24px;
  flex-direction: column;
  gap: 32px;
  background: ${(props) => props.theme.color_theme.bodyColor};
  border-radius: 12px;
  z-index: 10;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 336px;
`;
