import React from "react";

const Emailcon = (fillColor) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12V13.325C22 14.2583 21.6721 15.0458 21.0164 15.6875C20.3607 16.3292 19.5552 16.65 18.6 16.65C17.9986 16.65 17.4316 16.5042 16.899 16.2125C16.3663 15.9208 15.9583 15.5083 15.675 14.975C15.2417 15.5417 14.6993 15.9625 14.0479 16.2375C13.3965 16.5125 12.7139 16.65 12 16.65C10.7066 16.65 9.60407 16.2 8.69245 15.3C7.78082 14.4 7.325 13.2999 7.325 11.9997C7.325 10.6994 7.78082 9.59108 8.69245 8.67465C9.60407 7.75822 10.7066 7.3 12 7.3C13.2934 7.3 14.3959 7.75825 15.3076 8.67475C16.2192 9.59125 16.675 10.6997 16.675 12V13.325C16.675 13.8352 16.8625 14.2669 17.2375 14.6202C17.6125 14.9734 18.0625 15.15 18.5875 15.15C19.1125 15.15 19.5625 14.9734 19.9375 14.6202C20.3125 14.2669 20.5 13.8352 20.5 13.325V12C20.5 9.62708 19.6766 7.61718 18.0297 5.9703C16.3828 4.32343 14.3729 3.5 12 3.5C9.62708 3.5 7.61718 4.32343 5.9703 5.9703C4.32343 7.61718 3.5 9.62708 3.5 12C3.5 14.3729 4.32343 16.3828 5.9703 18.0297C7.61718 19.6766 9.62708 20.5 12 20.5H17.35V22H12ZM12.0015 15.15C12.8838 15.15 13.6333 14.8438 14.25 14.2312C14.8667 13.6188 15.175 12.875 15.175 12C15.175 11.1 14.8662 10.3417 14.2485 9.725C13.6309 9.10833 12.8809 8.8 11.9985 8.8C11.1162 8.8 10.3667 9.10833 9.75 9.725C9.13333 10.3417 8.825 11.1 8.825 12C8.825 12.875 9.13382 13.6188 9.75147 14.2312C10.3691 14.8438 11.1191 15.15 12.0015 15.15Z" />
        </svg>

    );
};

export default Emailcon;
