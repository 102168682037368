import React from "react";
import { SignInPageWrapper } from "./SignInPage.styled";
import Layout from "../../modules/Layout/Layout";
import SSOLogin from "../../modules/SSOLogin/SSOLogin";
import { connect } from "react-redux";

const SignInPage = ({ is_contrast, dark_mode }) => {
  return (
    <Layout noToken={true}>
      <SignInPageWrapper is_contrast={is_contrast} dark_mode={dark_mode}>
        <SSOLogin />
      </SignInPageWrapper>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
  };
};

export default connect(mapStateToProps)(SignInPage);
