import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SearchWrapper, SearchError } from "./Search.styled";
import InputField from "../InputField/InputField";

const Search = ({
  placeholdertext,
  voiceCommand,
  searchCommand,
  righticons,
  wrapperStyle,
  value,
  width,
  onChange,
  speech,
  ...props
}) => {
  const handleInputChange = (event, value) => {
    const inputValue = event?.target?.value || value;
    if (onChange) {
      onChange(inputValue); // Call the provided onChange function
    }
  };

  const [errorMsg, setErrorMsg] = useState("");

  const handleSearchError = (msg) => {
    setErrorMsg(msg);
  };

  useEffect(() => {
    let timer;
    if (errorMsg) timer = setTimeout(() => setErrorMsg(""), 5000);
    return () => clearTimeout(timer);
  }, [errorMsg]);

  return (
    <SearchWrapper style={wrapperStyle}>
      {errorMsg && <SearchError>{errorMsg}</SearchError>}
      <InputField
        width={width}
        type="text"
        placeholder={placeholdertext}
        value={value}
        onChangefunction={handleInputChange}
        handleSearchError={handleSearchError}
        righticons={righticons}
        voiceCommand={voiceCommand}
        searchCommand={searchCommand}
        {...props}
        data-speech={speech}
      />
    </SearchWrapper>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
