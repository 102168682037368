import React, { useEffect, useState } from "react";
import {
  UpdateModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  EntitlementDetailWrapper,
} from "./UpdateEntitlementsModal.styled";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { EditClientInfo, getClientInfo } from "../../apis/ApiServerRequests";
import { useDispatch } from "react-redux";
import useKeyPress from "../../hooks/useKeyPress";
import SearchAutocomplete from "../../components/AutoCompleteSearch/AutoCompleteSearch";
import EntitlementIcon from "../../assets/svgs/EntitlementIcon";
import { incrClientCount } from "../../store/slices/ClientSlice";

const UpdateEntitlementsModal = ({
  onClose,
  onSuccess,
  pharma_name,
  overlayName,
}) => {
  const dispatch = useDispatch();
  const [pharmaName, setPharmaName] = useState("");
  const [address, set_address] = useState("");
  const [business_contact_email, set_business_contact_email] = useState("");
  const [business_contact_name, set_business_contact_name] = useState("");
  const [technical_contact_name, set_technical_contact_name] = useState("");
  const [technical_contact_email, set_technical_contact_email] = useState("");
  const [entitlement_type, set_entitlement_type] = useState([]);
  const [loading, setLoading] = useState(false);


  const addEntitlement = (entitlementValue) => {
    if (!entitlement_type.includes(entitlementValue))
      set_entitlement_type((prevEntitlement) => [
        ...prevEntitlement,
        entitlementValue,
      ]);
  };

  const deleteEntitlementfunction = (itemIndex) => {
    let data = [...entitlement_type];
    data.splice(itemIndex, 1);
    set_entitlement_type(data);
  };

  const handleEditClient = () => {
    let pharma_details = {
      address,
      business_contact_name,
      business_contact_email,
      technical_contact_name,
      technical_contact_email,
      old_pharma_name : pharmaName,
    };
    let data = {
      entitlement_type: entitlement_type,
    };

    setLoading(true);
    onSuccess({ loading: true });
    EditClientInfo(pharmaName, data, pharma_details)
      .then(() => {
        onClose();
        onSuccess({ loading: false });
        dispatch(incrClientCount(1));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (pharma_name) {
      getClientInfo(pharma_name).then(({ data }) => {
        setPharmaName(pharma_name);
        set_address(data.address);
        set_business_contact_email(data.business_contact_email);
        set_business_contact_name(data.business_contact_name);
        set_technical_contact_name(data.technical_contact_name);
        set_technical_contact_email(data.technical_contact_email);
        set_entitlement_type(data.entitlement_type);
      });
    }
    return () => {
      setPharmaName("");
      set_address("");
      set_business_contact_email("");
      set_business_contact_name("");
      set_technical_contact_name("");
      set_technical_contact_email("");
      set_entitlement_type([]);
    };
  }, []);

  useKeyPress("Escape", [], onClose, true, overlayName, true);
  useKeyPress("Enter", [], handleEditClient, true, overlayName, true);

  return (
    <UpdateModalWrapper>
      <HeaderWrapper>
        <HeadTitle>Manage Entitlements</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Update Entitlements"}
            onClick={handleEditClient}
            accessKeyText={"[Enter]"}
            disabled={entitlement_type.length > 0 ? false : true || loading}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "inherit",
          width: "inherit",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div style={{ width: 584, marginBottom: "20px" }}>
          <SearchAutocomplete
            placeholder={"Add Entitlement"}
            handleAddMember={addEntitlement}
            searchCommand={{
              text: "[Alt+E]",
              key: "E",
              subkey: ["altKey"],
            }}
            rightIcon={EntitlementIcon()}
            searchEnabled={false}
            enableAddButton={false}
            isOverlay={true}
            overlayName={overlayName}
          />
        </div>
        {entitlement_type.length > 0 &&
          entitlement_type.map((item, index) => {
            return (
              <EntitlementDetailWrapper
                style={{
                  paddingTop: "15px",
                }}
              >
                <InputField
                  width={"280"}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  value={item}
                  type={"text"}
                  inputStyle={{
                    paddingBottom: "10px",
                    height: "34px",
                  }}
                  icon={EntitlementIcon()}
                />
                <Button
                  type={"ghost"}
                  icon={DeleteIcon()}
                  text={""}
                  accessKeyText={`[ Ctrl + ${index + 1} ]`}
                  onClick={() => deleteEntitlementfunction(index)}
                  iconStyle={{ marginRight: "0px" }}
                  buttonStyle={{
                    paddingLeft: "0px",
                  }}
                />
              </EntitlementDetailWrapper>
            );
          })}
      </div>
    </UpdateModalWrapper>
  );
};

export default UpdateEntitlementsModal;
