import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { username: null, token: null, icf_docs_count: 0 },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setUsername(state, action) {
      state.username = action.payload;
    },
    incrICFCount(state, action) {
      state.icf_docs_count += action.payload;
    },
  },
});

export const { setToken, setUsername, incrICFCount } = userSlice.actions;
export default userSlice.reducer;
