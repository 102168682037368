import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackspaceContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const FormTitle = styled.div`
  ${Fonts.Body3};
  text-align: center;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 0px;
  align-items: center
`;

export const FormFooter = styled.div`
  display: flex;
  margin: 0 110px;
  /* margin-top: -24px; */
  align-items: center;
  width: 420px;
  justify-content: center
`;

export const SelectPharma = styled.div`
  display: flex;
  width: 297px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #e8e8e8;
  background: #fbfbfb;
  margin-bottom: 24px;
  height: 30px;

  select {
    width: 100%;
    height: 100%;
    background: transparent; /* Make it transparent */
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const SelectProtocolDoc = styled.div`
  display: flex;
  width: 297px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #e8e8e8;
  background: #fbfbfb;
  margin-bottom: 24px;
  height: 30px;

  select {
    width: 100%;
    height: 100%;
    background: transparent; /* Make it transparent */
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const LabelTextWrapper = styled.div`
  display: flex;
  justify-content: start;
  font-size: ${(props) => props.theme.font_delta + 10}px;
  color : ${(props) => props.theme.color_theme.color};
  opacity: 0.8;
  margin-bottom: 2px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
width: 420px;
`
