import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  border: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  border-radius: 8px;
`;

export const TableHeading = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: flex-start;
  gap: 20px;
  border-bottom: ${(props) =>
    `1px solid ${props.theme.color_theme.borderColor}`};
  border-radius: 8px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  background: ${(props) => props.theme.color_theme.tableColor};
  justify-content: space-between;
  width: calc(100% - 24px);
`;

export const HeadingCell = styled.div`
  min-width: ${({ width }) => `calc(${width} - 12px);`};
  max-width: ${({ width }) => `${width}`};
  display: ${({ theme, thres }) =>
    thres && theme.window_width < thres ? "none" : "flex"};
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-direction: row;

  span {
    color: ${(props) => props.theme.color_theme.primaryColor};
  }
`;

export const TableRows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
`;

export const PermissionText = styled.span`
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 10}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PaginationContainer = styled.div`
  position: sticky;
  margin: 14px 0px;
`;

export const ListFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FooterText = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${(props) => props.theme.color_theme.color};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 12}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.6;
    cursor: pointer;
    margin-left: 6px;
  }
`;

export const TableInfoText = styled.div`
  margin-bottom: 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;

  span {
    color: ${(props) => props.theme.color_theme.primaryColor};
    margin-left: 4px;
  }
`;
