import React, { useEffect } from "react";
import {
  UpdateClientModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  MemberDetailWrapper,
  AddMemberWrapper,
  MemberDetailContainer,
} from "./ShowAuditSourceModal.styled";
import Button from "../../components/Button/Button";
import InfoTextarea from "../../components/InfoTextarea/InfoTextarea";
import useKeyPress from "../../hooks/useKeyPress";
import DownloadIcon from "../../assets/svgs/DownloadIcon";
import { connect } from "react-redux";

const ShowAuditSourceModal = ({
  onClose,
  source_info,
  styling_configuration_files,
  overlayName,
  dark_mode,
}) => {
  useKeyPress("Escape", [], onClose, true, overlayName, true);

  useEffect(() => {
    console.log("source_info ==>", source_info)
  }, [source_info])

  const handleDocumentDownload = async (documentUrl, documentName, sites) => {
    if (documentUrl) {
      try {
        let downloadUrl;
        if(sites==="ownCloud"){
          downloadUrl = `${documentUrl}/download`;
        }else{
          downloadUrl = `${documentUrl}`;
        }
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = documentName;
        anchor.click();
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    }
  };

  return (
    <UpdateClientModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"Sources"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
          />
        </HeadRight>
      </HeaderWrapper>

      <div
        style={{
          height: "inherit",
          width: "inherit",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div style={{ width: 584}}></div>
        <AddMemberWrapper>
          <MemberDetailContainer>
            {source_info && source_info?.map((item, index) => {
              return (
                <MemberDetailWrapper
                  key={index}
                  style={{
                    paddingTop: index === 0 ? "0px" : "13px",
                    borderBottom:
                      index === source_info.length - 1
                        ? "none"
                        : "1px solid #f2f2f2",
                  }}
                >
                  <>
                    <span
                      onClick={() =>
                        handleDocumentDownload(
                          item.source_file.url,
                          item.source_file.name
                        )
                      }
                    >
                      <InfoTextarea
                        labelStyle={{
                          position: "absolute",
                          padding: "4px 22px",
                        }}
                        label={`Source File Name`}
                        inputStyle={{
                          paddingBottom: "10px",
                          paddingTop: "20px",
                          height: "auto",
                        }}
                        iconStyle={dark_mode ? { fill: "#fff" } : {}}
                        width={"310"}
                        value={item.source_file.name}
                        rightIcon={<DownloadIcon />}
                      />
                    </span>
                    {(!styling_configuration_files?.length > 0) && <span
                      onClick={() =>
                        handleDocumentDownload(
                          item.source_template.url,
                          item.source_template.name,
                          "ownCloud"
                        )
                      }
                    >
                      <InfoTextarea
                        labelStyle={{
                          position: "absolute",
                          padding: "4px 22px",
                        }}
                        label={`Source File Template`}
                        inputStyle={{
                          paddingBottom: "10px",
                          paddingTop: "20px",
                          height: "auto",
                        }}
                        iconStyle={dark_mode ? { fill: "#fff" } : {}}
                        width={"310"}
                        value={item.source_template.name}
                        rightIcon={<DownloadIcon />}
                      />
                    </span>}
                  </>
                </MemberDetailWrapper>
              );
            })}

            {styling_configuration_files?.length > 0 && (
              <>
                <HeadTitle  style={{marginBottom: "10px", marginTop: "10px" }}>Styling Files</HeadTitle>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "#f2f2f2",
                    margin: "20px 0px",
                    marginBottom: "20px" 
                  }}
                ></div>
              </>
            )}
            {styling_configuration_files &&
              styling_configuration_files.map((item, index) => {
                if (index % 2 === 0) {
                  const secondItem =
                    styling_configuration_files[index + 1] || null;
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "13px",
                        gap: "10px"
                      }}
                    >
                      <MemberDetailWrapper
                        style={{
                          borderBottom: "none",
                          flex: "0 0 48%",
                          paddingTop: index === 0 ? "0px" : "13px",
                        }}
                      >
                        <span
                          onClick={() =>
                            handleDocumentDownload(item.url, item.name, "ownCloud")
                          }
                        >
                          <InfoTextarea
                            labelStyle={{
                              position: "absolute",
                              padding: "4px 22px",
                            }}
                            label={`Styling Configuration File`}
                            inputStyle={{
                              paddingBottom: "10px",
                              paddingTop: "20px",
                              height: "auto",
                            }}
                            iconStyle={dark_mode ? { fill: "#fff" } : {}}
                            width={"310"}
                            value={item.name}
                            rightIcon={<DownloadIcon />}
                          />
                        </span>
                      </MemberDetailWrapper>
                      {secondItem && (
                        <MemberDetailWrapper
                          key={index + 1}
                          style={{
                            borderBottom: "none",
                            flex: "0",
                            paddingTop: index === 0 ? "0px" : "13px",
                          }}
                        >
                          <span
                            onClick={() =>
                              handleDocumentDownload(
                                secondItem.url,
                                secondItem.name,
                                "ownCloud"
                              )
                            }
                          >
                            <InfoTextarea
                              labelStyle={{
                                position: "absolute",
                                padding: "4px 22px",
                              }}
                              label={`Styling Configuration File`}
                              inputStyle={{
                                paddingBottom: "10px",
                                paddingTop: "20px",
                                height: "auto",
                              }}
                              iconStyle={dark_mode ? { fill: "#fff" } : {}}
                              width={"310"}
                              value={secondItem.name}
                              rightIcon={<DownloadIcon />}
                            />
                          </span>
                        </MemberDetailWrapper>
                      )}
                    </div>
                  );
                }
                return null;
              })}
          </MemberDetailContainer>
        </AddMemberWrapper>
      </div>
    </UpdateClientModalWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    dark_mode: state?.a11y?.dark_mode,
  };
};

export default connect(mapStateToProps)(ShowAuditSourceModal);
