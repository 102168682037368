import React from "react";
import PropTypes from "prop-types";
import { IndeterminateLoaderWrapper } from "./IndeterminateLoader.styled";

const IndeterminateLoader = ({ size = 40 }) => (
  <IndeterminateLoaderWrapper size={size} />
);

IndeterminateLoader.propTypes = {};

IndeterminateLoader.defaultProps = {};

export default IndeterminateLoader;
