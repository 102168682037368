import React, { useState, useEffect } from "react";
import {
  RowCenter,
  DocumentTitle,
  DocumentWrapper,
  SearchContainer,
  SmallText,
  DocumentBody,
  PaginationContainer,
  DocumentTitleIcon,
} from "./DocumentList.styled";
import Button from "../../components/Button/Button";
import SortIcon from "../../assets/svgs/SortIcon";
import Search from "../../components/Search/Search";
import DocumentItem from "../../components/DocumentItem/DocumentItem";
import Pagination from "../../components/Pagination/Pagination";
import { connect, useSelector } from "react-redux";
import {
  getGeneratedDocument,
  getGeneratedDocumentDownloadUrl,
} from "../../apis/ApiServerRequests";
import useKeyPress from "../../hooks/useKeyPress";
import { setActiveList } from "../../store/slices/A11ySlice";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import NoSearchFound from "../../components/NoSearchFound/NoSearchFound";
import CalenderIcon from "../../assets/svgs/CalenderIcon";
import speechFormat from "../../helpers/speechFormat";
import useSpeechSynthesis from "../../hooks/useSpeechSynthesis";
import TitleComponent from "../../components/TitleComponent/TitleComponent";

const DocumentList = ({ icf_docs_count, dispatch }) => {
  const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);

  const perPage = 5 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [sort, setSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reading, setReading] = useState(false);

  const [input, setInput] = useState("");
  const [itemList, setItemList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const active_list = useSelector((state) => state?.a11y?.active_list);
  // const overlay = useSelector((state) => state?.a11y?.overlay);

  // useEffect(() => {
  //   setSelectedItem(null);
  // }, [overlay]);

  useEffect(() => {
    if (!speaking && reading) setReading(false);
  }, [speaking]);

  useEffect(() => {
    if (selectedItem !== null) dispatch(setActiveList("document_list"));
  }, [selectedItem]);

  useEffect(() => {
    if (active_list !== "document_list") setSelectedItem(null);
  }, [active_list]);

  useEffect(() => {
    setLoading(true);
    getGeneratedDocument()
      .then(({ data }) => {
        setDocList(data);
        setTotalPages(Math.ceil(data?.length ? data?.length / perPage : 0));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [icf_docs_count]);

  const handleSortByDate = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    if (sort) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };

  useEffect(() => {
    let filterList = docList?.filter(({ name }) =>
      name.toLowerCase().includes((input || "").toLowerCase())
    );
    let activeList = filterList
      ?.sort(handleSortByDate)
      ?.slice((currentPage - 1) * perPage, currentPage * perPage);

    setItemList(activeList);
    setTotalPages(
      Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
    );
  }, [input, currentPage, docList, sort]);

  useEffect(() => {
    setCurrentPage(1);
  }, [input]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFileDownload = (path = null) => {
    if (!path && selectedItem >= 0) {
      path = itemList[selectedItem]?.name;
    }
    const file_name = path
    getGeneratedDocumentDownloadUrl(file_name).then(({ data }) => {
      const anchor = document.createElement("a");
      anchor.href = data?.url;
      anchor.download = file_name;
      anchor.click();
    });
  };

  const handleArrowUP = () => {
    if (active_list === "document_list")
      setSelectedItem(Math.max(0, selectedItem - 1));
  };

  const handleArrowDown = () => {
    let l = itemList.length;
    if (active_list === "document_list")
      setSelectedItem(Math.min(l - 1, selectedItem + 1));
  };

  const fetchInput = (val) => {
    setSelectedItem(null);
    setInput(val);
  };

  const handleDocsListRead = () => {
    if (reading) {
      cancelSpeaking();
      setReading(false);
      return;
    }
    let speech = "";
    itemList.forEach(({ name }, i) => {
      speech += speechFormat({
        key: `Control + ${i + 1}`,
        action: "select",
        item: name,
      });
      speech += ". ";
    });
    setReading(true);
    speak(speech);
  };

  useKeyPress("R", [], handleDocsListRead);
  useKeyPress("U", [], () => setSort(!sort));
  useKeyPress("ArrowUp", [], handleArrowUP);
  useKeyPress("ArrowDown", [], handleArrowDown);

  useKeyPress("z", [], handleFileDownload);
  useKeyPress("1", ["ctrlKey"], () => setSelectedItem(0));
  useKeyPress("2", ["ctrlKey"], () => setSelectedItem(1));
  useKeyPress("3", ["ctrlKey"], () => setSelectedItem(2));
  useKeyPress("4", ["ctrlKey"], () => setSelectedItem(3));
  useKeyPress("5", ["ctrlKey"], () => setSelectedItem(4));
  useKeyPress("6", ["ctrlKey"], () => setSelectedItem(5));
  useKeyPress("7", ["ctrlKey"], () => setSelectedItem(6));
  useKeyPress("K", ["ctrlKey"], () => setInput(""));

  return (
    <DocumentWrapper>
      <RowCenter>
        <div style={{ display: "flex", position: "absolute", left: 0 }}>
          <DocumentTitleIcon>GENERATED DOCS</DocumentTitleIcon>
          <TitleComponent />
        </div>
        <DocumentTitle></DocumentTitle>
        <Button
          type="ghost"
          icon={sort ? <CalenderIcon /> : <CalenderIcon arrowDown={true} />}
          accessKeyText="[U]"
          buttonStyle={{ padding: "4px 0px" }}
          onClick={() => setSort(!sort)}
          data-speech={speechFormat({
            key: "U",
            action: "sort",
            item: "Generated docs",
          })}
        />
      </RowCenter>
      <SearchContainer>
        <Search
          width={"100%"}
          placeholdertext={"Search Docs..."}
          righticons={true}
          onChange={fetchInput}
          value={input}
          searchCommand={{
            text: "[ X ]",
            key: "X",
            subkey: [],
          }}
          voiceCommand={{
            text: "[ Alt + X ]",
            key: "X",
            subkey: ["altKey"],
          }}
          speech={speechFormat({
            action: "search",
            item: "Generated Docs",
          })}
        />
        <RowCenter>
          <SmallText>
            Download Shortcut works only for <br></br>the selected row.
          </SmallText>
          <Button
            type="ghost"
            text={reading ? "Cancel Read List" : "Read List"}
            accessKeyText="[ R ]"
            buttonStyle={{ padding: "4px 0px" }}
            data-speech={speechFormat({
              key: "R",
              action: reading ? "Cancel Reading of" : "read",
              item: "Generated Docs",
            })}
            onClick={handleDocsListRead}
          />
        </RowCenter>
      </SearchContainer>
      <DocumentBody>
        {loading ? (
          <div style={{ width: "100%" }}>
            <SkeletonLoader count={perPage} height={74} />
          </div>
        ) : (
          <>
            {input && itemList.length === 0 ? (
              <NoSearchFound
                height={"336px"}
                accessKey={"Ctrl + K"}
                resetSearch={() => setInput("")}
              />
            ) : (
              itemList?.map((item, index) => {
                return (
                  <DocumentItem
                    key={index}
                    index={index}
                    type={item?.type}
                    text={item.name}
                    date={item.date}
                    handleDownload={handleFileDownload}
                    selectedItemIndex={selectedItem}
                    setSelectedItemIndex={setSelectedItem}
                  ></DocumentItem>
                );
              })
            )}
          </>
        )}
      </DocumentBody>
      <PaginationContainer>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onFirstPage={() => setCurrentPage(1)}
          onLastPage={() => setCurrentPage(totalPages)}
          onPrev={handlePrevPage}
          onNext={handleNextPage}
          firstAccessKey={{
            text: "[;]",
            key: ";",
            subkey: [],
            speech: speechFormat({
              key: "Semicolon",
              action: "goto",
              item: "First page of Generated Docs",
            }),
          }}
          prevAccessKey={{
            text: "[,]",
            key: ",",
            subkey: [],
            speech: speechFormat({
              key: "comma",
              action: "goto",
              item: "Previous page of Generated Docs",
            }),
          }}
          nextAccessKey={{
            text: "[.]",
            key: ".",
            subkey: [],
            speech: speechFormat({
              key: "fullstop",
              action: "goto",
              item: "Next page of Generated Docs",
            }),
          }}
          lastAccesskey={{
            text: "[']",
            key: "'",
            subkey: [],
            speech: speechFormat({
              key: "single quote",
              action: "goto",
              item: "Last page of Generated Docs",
            }),
          }}
        />
      </PaginationContainer>
    </DocumentWrapper>
  );
};

DocumentList.propTypes = {};

DocumentList.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    icf_docs_count: state?.user?.icf_docs_count,
  };
};

export default connect(mapStateToProps)(DocumentList);
