import React from "react";

const ContrastIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M10.0001 18.3337C8.8473 18.3337 7.76397 18.1149 6.75008 17.6774C5.73619 17.2399 4.85425 16.6462 4.10425 15.8962C3.35425 15.1462 2.7605 14.2642 2.323 13.2503C1.8855 12.2364 1.66675 11.1531 1.66675 10.0003C1.66675 8.84755 1.8855 7.76421 2.323 6.75033C2.7605 5.73644 3.35425 4.85449 4.10425 4.10449C4.85425 3.35449 5.73619 2.76074 6.75008 2.32324C7.76397 1.88574 8.8473 1.66699 10.0001 1.66699C11.1529 1.66699 12.2362 1.88574 13.2501 2.32324C14.264 2.76074 15.1459 3.35449 15.8959 4.10449C16.6459 4.85449 17.2397 5.73644 17.6772 6.75033C18.1147 7.76421 18.3334 8.84755 18.3334 10.0003C18.3334 11.1531 18.1147 12.2364 17.6772 13.2503C17.2397 14.2642 16.6459 15.1462 15.8959 15.8962C15.1459 16.6462 14.264 17.2399 13.2501 17.6774C12.2362 18.1149 11.1529 18.3337 10.0001 18.3337ZM10.4167 17.0837C12.3195 16.9448 13.9063 16.2087 15.1772 14.8753C16.448 13.542 17.0834 11.917 17.0834 10.0003C17.0834 8.08366 16.448 6.45866 15.1772 5.12533C13.9063 3.79199 12.3195 3.05588 10.4167 2.91699V17.0837Z"
      />
    </svg>
  );
};

export default ContrastIcon;
