import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";
import backgroundImg from '../../assets/BGGrad.png';


export const SignInPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 37px;
  justify-content: center;

  ${props =>
    !props.dark_mode
      ? `
    background-image: url(${backgroundImg});
    background-size: cover;
    background-repeat: no-repeat;
  `
      : props.dark_mode && props.is_contrast ? `
        background-color: ${props.theme.color_theme.bodyColor};
      ` : `
      background-color: #131313;
      `
  }
`;
