import React from "react";
import Layout from "../modules/Layout/Layout";
import { Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const ProtectRoute = ({ children }) => {
  const { accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;

  return (
    <>{isLoggedIn ? <Layout>{children}</Layout> : <Navigate to="/signin" />}</>
  );
};

export default ProtectRoute;
