import React from "react";

const PrevIcon = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.525 18.0002L8.5 11.9752L14.525 5.9502L15.6 7.0252L10.65 11.9752L15.6 16.9252L14.525 18.0002Z"
      />
    </svg>
  );
};

export default PrevIcon;
