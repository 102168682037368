import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme.color_theme.tableColor};
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
`;

export const Circle = styled.div`
  width: 44px;
  height: 44px;
`;

export const MainText = styled.div`
  ${Fonts.Body2};
  margin-bottom: 2px;
`;
export const SmallText = styled.div`
  ${Fonts.Small};
  color : ${(props) => props.theme.color_theme.color};
  opacity: 0.4;
`;
