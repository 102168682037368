import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : "800px")};
  height: 520px;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
`;
export const ButtonWrapper = styled.div`
width: 400px;
margin: 16px 0px 0px 0px;
display: flex;
  justify-content: end;
  align-items: end;
`;

export const HeadTitle = styled.div`
  ${Fonts.Body3}
`;
export const HeadRight = styled.div`
  display: flex;
`;
export const Body = styled.div`
  display: flex;
  height: 480px;
`;
export const Left = styled.div`
  width:  ${({ pairs }) => (pairs ? `420px` : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-right: 1px solid #f2f2f2;
  background: rgba(255, 255, 255, 0);
  padding: 0px 12px 0px 0px;
  overflow: scroll;
`;

export const Middle = styled.div`
  width:  ${({ preview }) => (preview ? `420px` : `420px`)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: ${({ preview }) => (preview ? "1px solid #f2f2f2" : "0px")};
  background: rgba(255, 255, 255, 0);
  padding: 0px 12px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 12px;
`;

export const PairWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 0px 0px 0px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
`
export const DocumentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  height: 272px;
`;

export const PaginationContainer = styled.div`
  margin: 14px 0px;
  display: flex;
  justify-content: center;
`;

export const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

export const PreviewTitle = styled.div`
  ${Fonts.Body3};
  text-transform: uppercase;
`;
export const PairTitle = styled.div`
  ${Fonts.Body3};
  margin-bottom: 16px;
`;

export const PreviewSubtext = styled.div`
  ${Fonts.Small};
  color:  ${(props) => props.theme.color_theme.color};
  opacity: ${({ is_contrast }) => (is_contrast ? 0.8 : 0.6)};
`;

export const PreviewBody = styled.div`
  width: 420px;
  height: 350px;
  padding: 10px 0px;
  overflow: auto
`;

export const PreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
export const Frame = styled.iframe`
  height: 100%;
  width: 100%;
`;
