import React from "react";

const MoonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 17.5C7.91667 17.5 6.14583 16.7708 4.6875 15.3125C3.22917 13.8542 2.5 12.0833 2.5 10C2.5 7.91667 3.22917 6.14583 4.6875 4.6875C6.14583 3.22917 7.91667 2.5 10 2.5C10.1111 2.5 10.2292 2.50347 10.3542 2.51042C10.4792 2.51736 10.6389 2.52778 10.8333 2.54167C10.3333 2.98611 9.94444 3.53472 9.66667 4.1875C9.38889 4.84028 9.25 5.52778 9.25 6.25C9.25 7.5 9.6875 8.5625 10.5625 9.4375C11.4375 10.3125 12.5 10.75 13.75 10.75C14.4722 10.75 15.1597 10.6215 15.8125 10.3646C16.4653 10.1076 17.0139 9.75 17.4583 9.29167C17.4722 9.45833 17.4826 9.59375 17.4896 9.69792C17.4965 9.80208 17.5 9.90278 17.5 10C17.5 12.0833 16.7708 13.8542 15.3125 15.3125C13.8542 16.7708 12.0833 17.5 10 17.5ZM10 16.25C11.5139 16.25 12.8333 15.7812 13.9583 14.8438C15.0833 13.9062 15.7847 12.8056 16.0625 11.5417C15.7153 11.6944 15.3426 11.809 14.9444 11.8854C14.5463 11.9618 14.1482 12 13.75 12C12.1571 12 10.8005 11.4399 9.68031 10.3197C8.5601 9.19947 8 7.8429 8 6.25C8 5.91667 8.03472 5.55903 8.10417 5.17708C8.17361 4.79514 8.29861 4.36111 8.47917 3.875C7.11806 4.25 5.98958 5.01042 5.09375 6.15625C4.19792 7.30208 3.75 8.58333 3.75 10C3.75 11.7361 4.35764 13.2118 5.57292 14.4271C6.78819 15.6424 8.26389 16.25 10 16.25Z"
      />
    </svg>
  );
};

export default MoonIcon;
