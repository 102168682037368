import React from "react";

const LogoIcon = ({ contrast, dark_mode }) => {
  return (

    <>
      {contrast && dark_mode ?
        <svg xmlns="http://www.w3.org/2000/svg" width="190" height="50" viewBox="0 0 190 50" fill="none">
          <g clip-path="url(#clip0_1762_61887)">
            <path d="M40.8828 36.2727L49.2503 17.3047H51.134L59.5014 36.2727H57.2142L54.8735 30.8657L55.9497 31.4309H44.3799L45.4846 30.8657L43.1706 36.2727H40.8828ZM50.1654 19.8878L45.8334 30.0849L45.188 29.5733H55.1427L54.5508 30.0849L50.2189 19.8878H50.1654Z" fill="white" />
            <path d="M67.6835 41.3605C66.6048 41.3677 65.5307 41.218 64.4951 40.9163C63.5371 40.6396 62.6387 40.188 61.8451 39.5844L62.5712 37.9971C63.0758 38.3549 63.6117 38.6662 64.1724 38.9274C64.6964 39.1673 65.2475 39.343 65.8137 39.4506C66.3947 39.5598 66.9847 39.6139 67.5759 39.6123C68.8491 39.6123 69.8133 39.2714 70.4683 38.5896C71.1234 37.9078 71.4507 36.9214 71.4503 35.6303V32.5896H71.7195C71.4733 33.5965 70.8651 34.4775 70.0108 35.0646C69.1406 35.6747 68.1228 35.9795 66.9573 35.9791C65.7557 35.9791 64.7152 35.7057 63.8358 35.1588C62.9563 34.6119 62.2788 33.845 61.8032 32.8582C61.3276 31.8721 61.0898 30.7332 61.0898 29.4413C61.0898 28.1495 61.3276 27.015 61.8032 26.0378C62.2784 25.0607 62.9554 24.303 63.834 23.7646C64.7127 23.2262 65.7532 22.957 66.9555 22.957C68.1393 22.957 69.1615 23.2576 70.0224 23.8588C70.8645 24.4321 71.4599 25.3013 71.6904 26.2937H71.4486V23.1989H73.5741V35.3338C73.5741 36.6605 73.3544 37.7725 72.9148 38.6698C72.4981 39.5432 71.8027 40.2532 70.9381 40.6878C70.0598 41.1363 68.9749 41.3605 67.6835 41.3605ZM67.3608 34.2035C68.6162 34.2035 69.6115 33.7731 70.3468 32.9123C71.0821 32.0514 71.4503 30.8944 71.4515 29.4413C71.4515 27.9882 71.0833 26.8359 70.3468 25.9844C69.6104 25.1328 68.615 24.7065 67.3608 24.7053C66.1232 24.7053 65.1366 25.1316 64.4009 25.9844C63.6652 26.8371 63.297 27.9894 63.2962 29.4413C63.2962 30.8944 63.6645 32.0514 64.4009 32.9123C65.1373 33.7731 66.124 34.2035 67.3608 34.2035Z" fill="white" />
            <path d="M77.3945 19.9399V17.4922H80.0852V19.9399H77.3945ZM77.6637 36.2713V23.1957H79.8428V36.2713H77.6637Z" fill="white" />
            <path d="M87.915 36.5152C86.5875 36.5152 85.5875 36.1206 84.915 35.3314C84.2425 34.5423 83.9062 33.4122 83.9062 31.9413V17.3047H86.0853V31.7814C86.0853 32.4093 86.1706 32.9386 86.3411 33.3692C86.5117 33.7998 86.7673 34.1227 87.108 34.3378C87.4487 34.5529 87.8702 34.6605 88.3725 34.6605C88.5839 34.6608 88.795 34.6472 89.0045 34.6198C89.2003 34.5954 89.3938 34.555 89.583 34.4989L89.5295 36.3285C89.2423 36.3826 88.9685 36.4268 88.7086 36.4628C88.4456 36.4985 88.1804 36.516 87.915 36.5152Z" fill="white" />
            <path d="M97.6274 36.5159C96.246 36.5159 95.0578 36.2423 94.0628 35.695C93.0807 35.1627 92.2763 34.3541 91.7489 33.3694C91.2027 32.3632 90.9297 31.1609 90.9297 29.7624C90.9297 28.3996 91.1989 27.2115 91.7372 26.1979C92.2453 25.2155 93.0128 24.3911 93.9564 23.8142C94.8983 23.2401 95.988 22.9531 97.2256 22.9531C98.4093 22.9531 99.4227 23.2132 100.266 23.7334C101.109 24.2535 101.759 24.989 102.216 25.9398C102.674 26.8909 102.903 28.0299 102.902 29.3566V30.1909H92.596V28.7671H101.448L101.017 29.1159C101.017 27.6818 100.699 26.5651 100.062 25.7659C99.4254 24.9667 98.4973 24.5677 97.2779 24.5688C96.3628 24.5688 95.5876 24.7797 94.9524 25.2014C94.3171 25.6231 93.8372 26.2058 93.5128 26.9496C93.19 27.6946 93.0285 28.5601 93.0285 29.5461V29.7089C93.0285 30.8031 93.2029 31.7268 93.5518 32.4799C93.8761 33.2058 94.4288 33.8058 95.1256 34.1886C95.8256 34.5762 96.659 34.77 97.6256 34.77C98.3892 34.7713 99.1479 34.6486 99.8721 34.4066C100.63 34.1466 101.33 33.7398 101.931 33.2095L102.687 34.7432C102.095 35.2812 101.342 35.7117 100.427 36.0345C99.528 36.3536 98.5811 36.5164 97.6274 36.5159Z" fill="white" />
            <path d="M111.458 36.2727L104.812 17.3047H107.126L112.966 34.3896H112.024L117.971 17.3047H119.746L125.611 34.3896H124.778L130.64 17.3047H132.85L126.202 36.2727H124.238L118.534 19.8611H119.129L113.373 36.2727H111.458Z" fill="white" />
            <path d="M134.168 36.2738V23.1977H136.294V26.2918H136.024C136.33 25.2337 136.859 24.431 137.612 23.8837C138.365 23.3364 139.361 23.018 140.598 22.9285L141.378 22.8477L141.54 24.7314L140.168 24.8924C138.948 24.9998 138.011 25.3899 137.356 26.0628C136.702 26.7356 136.374 27.655 136.374 28.8209V36.2738H134.168Z" fill="white" />
            <path d="M143.527 19.9399V17.4922H146.22V19.9399H143.527ZM143.796 36.2713V23.1957H145.976V36.2713H143.796Z" fill="white" />
            <path d="M155.284 36.5182C153.741 36.5182 152.584 36.1056 151.813 35.2804C151.042 34.4552 150.656 33.2804 150.656 31.7559V24.8955H148.098V23.1995H150.656V19.1914H152.835V23.1995H156.978V24.8943H152.835V31.5408C152.835 32.5629 153.05 33.3381 153.481 33.8664C153.911 34.3947 154.611 34.6592 155.58 34.66C155.87 34.6595 156.159 34.6234 156.44 34.5525C156.727 34.4811 156.987 34.4094 157.22 34.3373L157.597 36.0054C157.365 36.1309 157.024 36.2472 156.575 36.3542C156.152 36.4594 155.719 36.5144 155.284 36.5182Z" fill="white" />
            <path d="M165.215 36.5175C163.834 36.5175 162.645 36.2439 161.65 35.6966C160.668 35.1639 159.864 34.3555 159.336 33.371C158.789 32.3663 158.516 31.1648 158.516 29.7663C158.516 28.4035 158.785 27.2154 159.323 26.2018C159.831 25.2194 160.599 24.3949 161.542 23.8181C162.484 23.244 163.574 22.957 164.812 22.957C165.995 22.957 167.009 23.2171 167.852 23.7373C168.695 24.2574 169.345 24.9929 169.802 25.9437C170.26 26.8948 170.488 28.0338 170.488 29.3605V30.1948H160.184V28.7687H169.035L168.605 29.1175C168.605 27.6834 168.287 26.5667 167.65 25.7675C167.013 24.9683 166.085 24.5692 164.865 24.5704C163.95 24.5704 163.175 24.7813 162.539 25.203C161.904 25.6247 161.424 26.2074 161.1 26.9512C160.777 27.6962 160.616 28.5617 160.616 29.5477V29.7105C160.616 30.8047 160.79 31.7283 161.139 32.4814C161.463 33.2075 162.016 33.8075 162.713 34.1902C163.41 34.5778 164.244 34.7716 165.213 34.7716C165.976 34.7729 166.735 34.6501 167.459 34.4082C168.217 34.1481 168.917 33.7413 169.517 33.2111L170.273 34.7448C169.681 35.2828 168.928 35.7132 168.013 36.0361C167.114 36.355 166.168 36.5179 165.215 36.5175Z" fill="white" />
            <path d="M173.637 36.2738V23.1977H175.762V26.2918H175.493C175.799 25.2337 176.328 24.431 177.081 23.8837C177.834 23.3364 178.83 23.018 180.068 22.9285L180.848 22.8477L181.009 24.7314L179.637 24.8924C178.417 24.9998 177.48 25.3899 176.825 26.0628C176.17 26.7356 175.843 27.655 175.843 28.8209V36.2738H173.637Z" fill="white" />
            <path d="M184.163 37.6567V35.3084H183.211V34.7852H185.742V35.3084H184.793V37.6567H184.163Z" fill="white" />
            <path d="M186.062 37.6583V34.7891H186.591L187.621 36.6734H187.49L188.52 34.7891H189.037V37.6583H188.463V35.7536H188.553L187.723 37.2269H187.368L186.538 35.7495H186.632V37.6583H186.062Z" fill="white" />
            <path d="M9.37714 49.7777C10.841 49.7777 12.0277 48.591 12.0277 47.1271C12.0277 45.6633 10.841 44.4766 9.37714 44.4766C7.91327 44.4766 6.72656 45.6633 6.72656 47.1271C6.72656 48.591 7.91327 49.7777 9.37714 49.7777Z" fill="#49FFA9" />
            <path d="M0.414062 47.6603C0.414062 47.6603 6.72511 40.2667 17.1908 34.1981C20.7989 31.8225 24.5426 29.7713 27.9117 28.701L27.9815 28.6789L28.0536 28.6469C28.0536 28.6469 28.8298 17.3167 38.2205 0.476562C9.37569 20.1103 0.414062 47.6603 0.414062 47.6603Z" fill="#E9FFF3" />
            <path d="M17.1908 34.1965C17.1908 34.1965 22.078 19.7988 20.5844 1.34766C13.0757 15.375 3.29778 22.4831 3.29778 22.4831C3.29778 22.4831 4.23616 35.3732 0.414062 47.6575C0.414062 47.6587 6.97278 40.6686 17.1908 34.1965Z" fill="#49FFA9" />
          </g>
          <defs>
            <clipPath id="clip0_1762_61887">
              <rect width="189.535" height="50" fill="white" />
            </clipPath>
          </defs>
        </svg>
        :
        !contrast && dark_mode ?
          <svg xmlns="http://www.w3.org/2000/svg" width="190" height="50" viewBox="0 0 190 50" fill="none">
            <g clip-path="url(#clip0_1762_61485)">
              <path d="M40.8828 36.2727L49.2503 17.3047H51.134L59.5014 36.2727H57.2142L54.8735 30.8657L55.9497 31.4309H44.3799L45.4846 30.8657L43.1706 36.2727H40.8828ZM50.1654 19.8878L45.8334 30.0849L45.188 29.5733H55.1427L54.5508 30.0849L50.2189 19.8878H50.1654Z" fill="white" />
              <path d="M67.6835 41.3605C66.6048 41.3677 65.5307 41.218 64.4951 40.9163C63.5371 40.6396 62.6387 40.188 61.8451 39.5844L62.5712 37.9971C63.0758 38.3549 63.6117 38.6662 64.1724 38.9274C64.6964 39.1673 65.2475 39.343 65.8137 39.4506C66.3947 39.5598 66.9847 39.6139 67.5759 39.6123C68.8491 39.6123 69.8133 39.2714 70.4683 38.5896C71.1234 37.9078 71.4507 36.9214 71.4503 35.6303V32.5896H71.7195C71.4733 33.5965 70.8651 34.4775 70.0108 35.0646C69.1406 35.6747 68.1228 35.9795 66.9573 35.9791C65.7557 35.9791 64.7152 35.7057 63.8358 35.1588C62.9563 34.6119 62.2788 33.845 61.8032 32.8582C61.3276 31.8721 61.0898 30.7332 61.0898 29.4413C61.0898 28.1495 61.3276 27.015 61.8032 26.0378C62.2784 25.0607 62.9554 24.303 63.834 23.7646C64.7127 23.2262 65.7532 22.957 66.9555 22.957C68.1393 22.957 69.1615 23.2576 70.0224 23.8588C70.8645 24.4321 71.4599 25.3013 71.6904 26.2937H71.4486V23.1989H73.5741V35.3338C73.5741 36.6605 73.3544 37.7725 72.9148 38.6698C72.4981 39.5432 71.8027 40.2532 70.9381 40.6878C70.0598 41.1363 68.9749 41.3605 67.6835 41.3605ZM67.3608 34.2035C68.6162 34.2035 69.6115 33.7731 70.3468 32.9123C71.0821 32.0514 71.4503 30.8944 71.4515 29.4413C71.4515 27.9882 71.0833 26.8359 70.3468 25.9844C69.6104 25.1328 68.615 24.7065 67.3608 24.7053C66.1232 24.7053 65.1366 25.1316 64.4009 25.9844C63.6652 26.8371 63.297 27.9894 63.2962 29.4413C63.2962 30.8944 63.6645 32.0514 64.4009 32.9123C65.1373 33.7731 66.124 34.2035 67.3608 34.2035Z" fill="white" />
              <path d="M77.3945 19.9399V17.4922H80.0852V19.9399H77.3945ZM77.6637 36.2713V23.1957H79.8428V36.2713H77.6637Z" fill="white" />
              <path d="M87.915 36.5152C86.5875 36.5152 85.5875 36.1206 84.915 35.3314C84.2425 34.5423 83.9062 33.4122 83.9062 31.9413V17.3047H86.0853V31.7814C86.0853 32.4093 86.1706 32.9386 86.3411 33.3692C86.5117 33.7998 86.7673 34.1227 87.108 34.3378C87.4487 34.5529 87.8702 34.6605 88.3725 34.6605C88.5839 34.6608 88.795 34.6472 89.0045 34.6198C89.2003 34.5954 89.3938 34.555 89.583 34.4989L89.5295 36.3285C89.2423 36.3826 88.9685 36.4268 88.7086 36.4628C88.4456 36.4985 88.1804 36.516 87.915 36.5152Z" fill="white" />
              <path d="M97.6274 36.5159C96.246 36.5159 95.0578 36.2423 94.0628 35.695C93.0807 35.1627 92.2763 34.3541 91.7489 33.3694C91.2027 32.3632 90.9297 31.1609 90.9297 29.7624C90.9297 28.3996 91.1989 27.2115 91.7372 26.1979C92.2453 25.2155 93.0128 24.3911 93.9564 23.8142C94.8983 23.2401 95.988 22.9531 97.2256 22.9531C98.4093 22.9531 99.4227 23.2132 100.266 23.7334C101.109 24.2535 101.759 24.989 102.216 25.9398C102.674 26.8909 102.903 28.0299 102.902 29.3566V30.1909H92.596V28.7671H101.448L101.017 29.1159C101.017 27.6818 100.699 26.5651 100.062 25.7659C99.4254 24.9667 98.4973 24.5677 97.2779 24.5688C96.3628 24.5688 95.5876 24.7797 94.9524 25.2014C94.3171 25.6231 93.8372 26.2058 93.5128 26.9496C93.19 27.6946 93.0285 28.5601 93.0285 29.5461V29.7089C93.0285 30.8031 93.2029 31.7268 93.5518 32.4799C93.8761 33.2058 94.4288 33.8058 95.1256 34.1886C95.8256 34.5762 96.659 34.77 97.6256 34.77C98.3892 34.7713 99.1479 34.6486 99.8721 34.4066C100.63 34.1466 101.33 33.7398 101.931 33.2095L102.687 34.7432C102.095 35.2812 101.342 35.7117 100.427 36.0345C99.528 36.3536 98.5811 36.5164 97.6274 36.5159Z" fill="white" />
              <path d="M111.458 36.2727L104.812 17.3047H107.126L112.966 34.3896H112.024L117.971 17.3047H119.746L125.611 34.3896H124.778L130.64 17.3047H132.85L126.202 36.2727H124.238L118.534 19.8611H119.129L113.373 36.2727H111.458Z" fill="white" />
              <path d="M134.168 36.2738V23.1977H136.294V26.2918H136.024C136.33 25.2337 136.859 24.431 137.612 23.8837C138.365 23.3364 139.361 23.018 140.598 22.9285L141.378 22.8477L141.54 24.7314L140.168 24.8924C138.948 24.9998 138.011 25.3899 137.356 26.0628C136.702 26.7356 136.374 27.655 136.374 28.8209V36.2738H134.168Z" fill="white" />
              <path d="M143.527 19.9399V17.4922H146.22V19.9399H143.527ZM143.796 36.2713V23.1957H145.976V36.2713H143.796Z" fill="white" />
              <path d="M155.284 36.5182C153.741 36.5182 152.584 36.1056 151.813 35.2804C151.042 34.4552 150.656 33.2804 150.656 31.7559V24.8955H148.098V23.1995H150.656V19.1914H152.835V23.1995H156.978V24.8943H152.835V31.5408C152.835 32.5629 153.05 33.3381 153.481 33.8664C153.911 34.3947 154.611 34.6592 155.58 34.66C155.87 34.6595 156.159 34.6234 156.44 34.5525C156.727 34.4811 156.987 34.4094 157.22 34.3373L157.597 36.0054C157.365 36.1309 157.024 36.2472 156.575 36.3542C156.152 36.4594 155.719 36.5144 155.284 36.5182Z" fill="white" />
              <path d="M165.215 36.5175C163.834 36.5175 162.645 36.2439 161.65 35.6966C160.668 35.1639 159.864 34.3555 159.336 33.371C158.789 32.3663 158.516 31.1648 158.516 29.7663C158.516 28.4035 158.785 27.2154 159.323 26.2018C159.831 25.2194 160.599 24.3949 161.542 23.8181C162.484 23.244 163.574 22.957 164.812 22.957C165.995 22.957 167.009 23.2171 167.852 23.7373C168.695 24.2574 169.345 24.9929 169.802 25.9437C170.26 26.8948 170.488 28.0338 170.488 29.3605V30.1948H160.184V28.7687H169.035L168.605 29.1175C168.605 27.6834 168.287 26.5667 167.65 25.7675C167.013 24.9683 166.085 24.5692 164.865 24.5704C163.95 24.5704 163.175 24.7813 162.539 25.203C161.904 25.6247 161.424 26.2074 161.1 26.9512C160.777 27.6962 160.616 28.5617 160.616 29.5477V29.7105C160.616 30.8047 160.79 31.7283 161.139 32.4814C161.463 33.2075 162.016 33.8075 162.713 34.1902C163.41 34.5778 164.244 34.7716 165.213 34.7716C165.976 34.7729 166.735 34.6501 167.459 34.4082C168.217 34.1481 168.917 33.7413 169.517 33.2111L170.273 34.7448C169.681 35.2828 168.928 35.7132 168.013 36.0361C167.114 36.355 166.168 36.5179 165.215 36.5175Z" fill="white" />
              <path d="M173.637 36.2738V23.1977H175.762V26.2918H175.493C175.799 25.2337 176.328 24.431 177.081 23.8837C177.834 23.3364 178.83 23.018 180.068 22.9285L180.848 22.8477L181.009 24.7314L179.637 24.8924C178.417 24.9998 177.48 25.3899 176.825 26.0628C176.17 26.7356 175.843 27.655 175.843 28.8209V36.2738H173.637Z" fill="white" />
              <path d="M184.163 37.6567V35.3084H183.211V34.7852H185.742V35.3084H184.793V37.6567H184.163Z" fill="white" />
              <path d="M186.062 37.6583V34.7891H186.591L187.621 36.6734H187.49L188.52 34.7891H189.037V37.6583H188.463V35.7536H188.553L187.723 37.2269H187.368L186.538 35.7495H186.632V37.6583H186.062Z" fill="white" />
              <path d="M9.37714 49.7777C10.841 49.7777 12.0277 48.591 12.0277 47.1271C12.0277 45.6633 10.841 44.4766 9.37714 44.4766C7.91327 44.4766 6.72656 45.6633 6.72656 47.1271C6.72656 48.591 7.91327 49.7777 9.37714 49.7777Z" fill="#2E9866" />
              <path d="M0.414062 47.6603C0.414062 47.6603 6.72511 40.2667 17.1908 34.1981C20.7989 31.8225 24.5426 29.7713 27.9117 28.701L27.9815 28.6789L28.0536 28.6469C28.0536 28.6469 28.8298 17.3167 38.2205 0.476562C9.37569 20.1103 0.414062 47.6603 0.414062 47.6603Z" fill="#A5E5C2" />
              <path d="M17.1908 34.1965C17.1908 34.1965 22.078 19.7988 20.5844 1.34766C13.0757 15.375 3.29778 22.4831 3.29778 22.4831C3.29778 22.4831 4.23616 35.3732 0.414062 47.6575C0.414062 47.6587 6.97278 40.6686 17.1908 34.1965Z" fill="#2E9866" />
            </g>
            <defs>
              <clipPath id="clip0_1762_61485">
                <rect width="189.535" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
          :
          contrast && !dark_mode ?
            <svg width="190" height="50" viewBox="0 0 190 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1360_48471)">
                <path d="M40.8828 36.274L49.2503 17.3059H51.134L59.5014 36.274H57.2142L54.8735 30.867L55.9497 31.4321H44.3799L45.4846 30.867L43.1706 36.274H40.8828ZM50.1654 19.8891L45.8334 30.0862L45.188 29.5745H55.1427L54.5508 30.0862L50.2189 19.8891H50.1654Z" fill="#074829" />
                <path d="M67.6795 41.3598C66.6008 41.367 65.5268 41.2174 64.4912 40.9157C63.5332 40.639 62.6348 40.1874 61.8412 39.5837L62.5673 37.9965C63.0719 38.3542 63.6078 38.6656 64.1685 38.9267C64.6925 39.1666 65.2436 39.3423 65.8098 39.45C66.3908 39.5591 66.9808 39.6132 67.572 39.6116C68.8452 39.6116 69.8094 39.2707 70.4644 38.5889C71.1195 37.9071 71.4468 36.9207 71.4464 35.6296V32.5889H71.7156C71.4694 33.5958 70.8612 34.4769 70.0069 35.0639C69.1367 35.674 68.1189 35.9788 66.9534 35.9785C65.7518 35.9785 64.7113 35.705 63.8319 35.1581C62.9524 34.6112 62.2749 33.8443 61.7993 32.8575C61.3237 31.8715 61.0859 30.7325 61.0859 29.4407C61.0859 28.1488 61.3237 27.0143 61.7993 26.0372C62.2745 25.06 62.9514 24.3023 63.8301 23.7639C64.7088 23.2255 65.7493 22.9564 66.9516 22.9564C68.1354 22.9564 69.1576 23.2569 70.0185 23.8581C70.8606 24.4314 71.456 25.3007 71.6865 26.293H71.4447V23.1982H73.5702V35.3331C73.5702 36.6598 73.3505 37.7719 72.9109 38.6691C72.4942 39.5425 71.7988 40.2525 70.9342 40.6872C70.0559 41.1356 68.971 41.3598 67.6795 41.3598ZM67.3569 34.2029C68.6123 34.2029 69.6076 33.7724 70.3429 32.9116C71.0782 32.0507 71.4464 30.8938 71.4476 29.4407C71.4476 27.9876 71.0793 26.8352 70.3429 25.9837C69.6065 25.1321 68.6111 24.7058 67.3569 24.7046C66.1193 24.7046 65.1326 25.131 64.397 25.9837C63.6613 26.8364 63.2931 27.9887 63.2923 29.4407C63.2923 30.8938 63.6606 32.0507 64.397 32.9116C65.1334 33.7724 66.12 34.2029 67.3569 34.2029Z" fill="#074829" />
                <path d="M77.3984 19.9421V17.4944H80.0891V19.9421H77.3984ZM77.6676 36.2735V23.1979H79.8467V36.2735H77.6676Z" fill="#074829" />
                <path d="M87.915 36.5164C86.5875 36.5164 85.5875 36.1218 84.915 35.3327C84.2425 34.5435 83.9062 33.4135 83.9062 31.9426V17.3059H86.0853V31.7827C86.0853 32.4106 86.1706 32.9399 86.3411 33.3705C86.5117 33.8011 86.7673 34.124 87.108 34.3391C87.4487 34.5542 87.8702 34.6618 88.3725 34.6618C88.5839 34.6621 88.795 34.6485 89.0045 34.6211C89.2003 34.5966 89.3938 34.5562 89.583 34.5001L89.5295 36.3298C89.2423 36.3838 88.9685 36.428 88.7086 36.4641C88.4456 36.4998 88.1804 36.5172 87.915 36.5164Z" fill="#074829" />
                <path d="M97.6274 36.5169C96.246 36.5169 95.0578 36.2433 94.0628 35.696C93.0807 35.1636 92.2763 34.3551 91.7489 33.3704C91.2027 32.3642 90.9297 31.1619 90.9297 29.7634C90.9297 28.4006 91.1989 27.2124 91.7372 26.1989C92.2453 25.2165 93.0128 24.392 93.9564 23.8151C94.8983 23.2411 95.988 22.9541 97.2256 22.9541C98.4093 22.9541 99.4227 23.2142 100.266 23.7343C101.109 24.2545 101.759 24.99 102.216 25.9407C102.674 26.8919 102.903 28.0308 102.902 29.3576V30.1919H92.596V28.7681H101.448L101.017 29.1169C101.017 27.6828 100.699 26.5661 100.062 25.7669C99.4254 24.9677 98.4973 24.5686 97.2779 24.5698C96.3628 24.5698 95.5876 24.7807 94.9524 25.2024C94.3171 25.6241 93.8372 26.2068 93.5128 26.9506C93.19 27.6956 93.0285 28.5611 93.0285 29.5471V29.7099C93.0285 30.8041 93.2029 31.7277 93.5518 32.4808C93.8761 33.2067 94.4288 33.8068 95.1256 34.1896C95.8256 34.5772 96.659 34.771 97.6256 34.771C98.3892 34.7723 99.1479 34.6496 99.8721 34.4076C100.63 34.1476 101.33 33.7408 101.931 33.2105L102.687 34.7442C102.095 35.2822 101.342 35.7126 100.427 36.0355C99.528 36.3546 98.5811 36.5174 97.6274 36.5169Z" fill="#074829" />
                <path d="M111.458 36.274L104.812 17.3059H107.126L112.966 34.3908H112.024L117.971 17.3059H119.746L125.611 34.3908H124.778L130.64 17.3059H132.85L126.202 36.274H124.238L118.534 19.8623H119.129L113.373 36.274H111.458Z" fill="#074829" />
                <path d="M134.164 36.2735V23.1974H136.29V26.2915H136.02C136.326 25.2334 136.855 24.4307 137.608 23.8834C138.361 23.3361 139.357 23.0177 140.594 22.9282L141.375 22.8474L141.536 24.7311L140.164 24.8921C138.944 24.9995 138.007 25.3896 137.352 26.0625C136.698 26.7353 136.37 27.6547 136.37 28.8206V36.2735H134.164Z" fill="#074829" />
                <path d="M143.531 19.9421V17.4944H146.224V19.9421H143.531ZM143.8 36.2735V23.1979H145.98V36.2735H143.8Z" fill="#074829" />
                <path d="M155.288 36.5177C153.745 36.5177 152.588 36.1051 151.817 35.2799C151.046 34.4547 150.66 33.2799 150.66 31.7555V24.895H148.102V23.1991H150.66V19.1909H152.839V23.1991H156.982V24.8938H152.839V31.5403C152.839 32.5624 153.054 33.3376 153.485 33.8659C153.915 34.3942 154.615 34.6587 155.584 34.6595C155.874 34.659 156.163 34.6229 156.444 34.552C156.731 34.4806 156.991 34.4089 157.224 34.3368L157.601 36.0049C157.368 36.1305 157.028 36.2467 156.579 36.3537C156.156 36.4589 155.723 36.5139 155.288 36.5177Z" fill="#074829" />
                <path d="M165.215 36.5168C163.834 36.5168 162.645 36.2432 161.65 35.6959C160.668 35.1632 159.864 34.3548 159.336 33.3703C158.789 32.3657 158.516 31.1641 158.516 29.7657C158.516 28.4029 158.785 27.2147 159.323 26.2011C159.831 25.2187 160.599 24.3943 161.542 23.8174C162.484 23.2434 163.574 22.9564 164.812 22.9564C165.995 22.9564 167.009 23.2164 167.852 23.7366C168.695 24.2567 169.345 24.9922 169.802 25.943C170.26 26.8942 170.488 28.0331 170.488 29.3598V30.1942H160.184V28.768H169.035L168.605 29.1168C168.605 27.6827 168.287 26.566 167.65 25.7668C167.013 24.9676 166.085 24.5686 164.865 24.5697C163.95 24.5697 163.175 24.7806 162.539 25.2023C161.904 25.624 161.424 26.2067 161.1 26.9505C160.777 27.6955 160.616 28.561 160.616 29.5471V29.7098C160.616 30.804 160.79 31.7277 161.139 32.4808C161.463 33.2068 162.016 33.8069 162.713 34.1895C163.41 34.5771 164.244 34.7709 165.213 34.7709C165.976 34.7722 166.735 34.6495 167.459 34.4075C168.217 34.1474 168.917 33.7407 169.517 33.2104L170.273 34.7442C169.681 35.2821 168.928 35.7126 168.013 36.0354C167.114 36.3544 166.168 36.5172 165.215 36.5168Z" fill="#074829" />
                <path d="M173.633 36.2735V23.1974H175.758V26.2915H175.489C175.795 25.2334 176.324 24.4307 177.077 23.8834C177.83 23.3361 178.826 23.0177 180.064 22.9282L180.844 22.8474L181.005 24.7311L179.633 24.8921C178.413 24.9995 177.476 25.3896 176.821 26.0625C176.167 26.7353 175.839 27.6547 175.839 28.8206V36.2735H173.633Z" fill="#074829" />
                <path d="M184.163 37.6579V35.3097H183.211V34.7864H185.742V35.3097H184.793V37.6579H184.163Z" fill="#074829" />
                <path d="M186.062 37.6579V34.7887H186.591L187.621 36.673H187.49L188.52 34.7887H189.037V37.6579H188.463V35.7532H188.553L187.723 37.2265H187.368L186.538 35.7491H186.632V37.6579H186.062Z" fill="#074829" />
                <path d="M9.37714 49.7782C10.841 49.7782 12.0277 48.5915 12.0277 47.1277C12.0277 45.6638 10.841 44.4771 9.37714 44.4771C7.91327 44.4771 6.72656 45.6638 6.72656 47.1277C6.72656 48.5915 7.91327 49.7782 9.37714 49.7782Z" fill="#065A32" />
                <path d="M0.414062 47.6587C0.414062 47.6587 6.72511 40.2651 17.1908 34.1965C20.7989 31.8209 24.5426 29.7697 27.9117 28.6994L27.9815 28.6773L28.0536 28.6453C28.0536 28.6453 28.8298 17.3151 38.2205 0.474976C9.37569 20.1087 0.414062 47.6587 0.414062 47.6587Z" fill="#91DAB2" />
                <path d="M17.1908 34.1959C17.1908 34.1959 22.078 19.7982 20.5844 1.34708C13.0757 15.3744 3.29778 22.4825 3.29778 22.4825C3.29778 22.4825 4.23616 35.3727 0.414062 47.657C0.414062 47.6581 6.97278 40.668 17.1908 34.1959Z" fill="#065A32" />
              </g>
              <defs>
                <clipPath id="clip0_1360_48471">
                  <rect width="189.535" height="50" fill="white" transform="translate(0 0.000335693)" />
                </clipPath>
              </defs>
            </svg>

            :
            <svg width="190" height="50" viewBox="0 0 190 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1213_23252)">
                <path d="M40.8848 36.2727L49.2522 17.3047H51.1359L59.5034 36.2727H57.2162L54.8755 30.8657L55.9516 31.4309H44.3819L45.4865 30.8657L43.1726 36.2727H40.8848ZM50.1673 19.8878L45.8353 30.0849L45.19 29.5733H55.1446L54.5528 30.0849L50.2208 19.8878H50.1673Z" fill="#1C7A56" />
                <path d="M67.6835 41.3586C66.6048 41.3657 65.5307 41.2161 64.4951 40.9144C63.5371 40.6377 62.6387 40.1861 61.8451 39.5824L62.5712 37.9952C63.0758 38.3529 63.6117 38.6643 64.1724 38.9254C64.6964 39.1653 65.2475 39.341 65.8137 39.4487C66.3947 39.5578 66.9847 39.6119 67.5759 39.6103C68.8491 39.6103 69.8133 39.2694 70.4683 38.5876C71.1234 37.9059 71.4507 36.9194 71.4503 35.6283V32.5876H71.7195C71.4733 33.5945 70.8651 34.4756 70.0108 35.0626C69.1406 35.6727 68.1228 35.9776 66.9573 35.9772C65.7557 35.9772 64.7152 35.7037 63.8358 35.1568C62.9563 34.6099 62.2788 33.8431 61.8032 32.8562C61.3276 31.8702 61.0898 30.7312 61.0898 29.4394C61.0898 28.1475 61.3276 27.013 61.8032 26.0359C62.2784 25.0588 62.9554 24.301 63.834 23.7626C64.7127 23.2243 65.7532 22.9551 66.9555 22.9551C68.1393 22.9551 69.1615 23.2557 70.0224 23.8568C70.8645 24.4302 71.4599 25.2994 71.6904 26.2917H71.4486V23.1969H73.5741V35.3318C73.5741 36.6586 73.3544 37.7706 72.9148 38.6679C72.4981 39.5413 71.8027 40.2512 70.9381 40.6859C70.0598 41.1343 68.9749 41.3586 67.6835 41.3586ZM67.3608 34.2016C68.6162 34.2016 69.6115 33.7712 70.3468 32.9103C71.0821 32.0495 71.4503 30.8925 71.4515 29.4394C71.4515 27.9863 71.0833 26.834 70.3468 25.9824C69.6104 25.1309 68.615 24.7045 67.3608 24.7033C66.1232 24.7033 65.1366 25.1297 64.4009 25.9824C63.6652 26.8351 63.297 27.9874 63.2962 29.4394C63.2962 30.8925 63.6645 32.0495 64.4009 32.9103C65.1373 33.7712 66.124 34.2016 67.3608 34.2016Z" fill="#1C7A56" />
                <path d="M77.3965 19.9418V17.4941H80.0872V19.9418H77.3965ZM77.6657 36.2732V23.1976H79.8447V36.2732H77.6657Z" fill="#1C7A56" />
                <path d="M87.915 36.5152C86.5875 36.5152 85.5875 36.1206 84.915 35.3314C84.2425 34.5423 83.9062 33.4122 83.9062 31.9413V17.3047H86.0853V31.7814C86.0853 32.4093 86.1706 32.9386 86.3411 33.3692C86.5117 33.7998 86.7673 34.1227 87.108 34.3378C87.4487 34.5529 87.8702 34.6605 88.3725 34.6605C88.5839 34.6608 88.795 34.6472 89.0045 34.6198C89.2003 34.5954 89.3938 34.555 89.583 34.4989L89.5295 36.3285C89.2423 36.3826 88.9685 36.4268 88.7086 36.4628C88.4456 36.4985 88.1804 36.516 87.915 36.5152Z" fill="#1C7A56" />
                <path d="M97.6274 36.5159C96.246 36.5159 95.0578 36.2423 94.0628 35.695C93.0807 35.1627 92.2763 34.3541 91.7489 33.3694C91.2027 32.3632 90.9297 31.1609 90.9297 29.7624C90.9297 28.3996 91.1989 27.2115 91.7372 26.1979C92.2453 25.2155 93.0128 24.3911 93.9564 23.8142C94.8983 23.2401 95.988 22.9531 97.2256 22.9531C98.4093 22.9531 99.4227 23.2132 100.266 23.7334C101.109 24.2535 101.759 24.989 102.216 25.9398C102.674 26.8909 102.903 28.0299 102.902 29.3566V30.1909H92.596V28.7671H101.448L101.017 29.1159C101.017 27.6818 100.699 26.5651 100.062 25.7659C99.4254 24.9667 98.4973 24.5677 97.2779 24.5688C96.3628 24.5688 95.5876 24.7797 94.9524 25.2014C94.3171 25.6231 93.8372 26.2058 93.5128 26.9496C93.19 27.6946 93.0285 28.5601 93.0285 29.5461V29.7089C93.0285 30.8031 93.2029 31.7268 93.5518 32.4799C93.8761 33.2058 94.4288 33.8058 95.1256 34.1886C95.8256 34.5762 96.659 34.77 97.6256 34.77C98.3892 34.7713 99.1479 34.6486 99.8721 34.4066C100.63 34.1466 101.33 33.7398 101.931 33.2095L102.687 34.7432C102.095 35.2812 101.342 35.7117 100.427 36.0345C99.528 36.3536 98.5811 36.5164 97.6274 36.5159Z" fill="#1C7A56" />
                <path d="M111.458 36.2727L104.812 17.3047H107.126L112.966 34.3896H112.024L117.971 17.3047H119.746L125.611 34.3896H124.778L130.64 17.3047H132.85L126.202 36.2727H124.238L118.534 19.8611H119.129L113.373 36.2727H111.458Z" fill="#1C7A56" />
                <path d="M134.166 36.2738V23.1977H136.292V26.2918H136.022C136.328 25.2337 136.857 24.431 137.61 23.8837C138.363 23.3364 139.359 23.018 140.596 22.9285L141.376 22.8477L141.538 24.7314L140.166 24.8924C138.946 24.9998 138.009 25.3899 137.354 26.0628C136.7 26.7356 136.372 27.655 136.372 28.8209V36.2738H134.166Z" fill="#1C7A56" />
                <path d="M143.527 19.9418V17.4941H146.22V19.9418H143.527ZM143.796 36.2732V23.1976H145.976V36.2732H143.796Z" fill="#1C7A56" />
                <path d="M155.286 36.5182C153.743 36.5182 152.586 36.1056 151.815 35.2804C151.044 34.4552 150.658 33.2804 150.658 31.7559V24.8955H148.1V23.1995H150.658V19.1914H152.837V23.1995H156.98V24.8943H152.837V31.5408C152.837 32.5629 153.052 33.3381 153.483 33.8664C153.913 34.3947 154.613 34.6592 155.582 34.66C155.872 34.6595 156.161 34.6234 156.442 34.5525C156.729 34.4811 156.989 34.4094 157.222 34.3373L157.599 36.0054C157.366 36.1309 157.026 36.2472 156.577 36.3542C156.154 36.4594 155.721 36.5144 155.286 36.5182Z" fill="#1C7A56" />
                <path d="M165.213 36.5155C163.832 36.5155 162.643 36.2419 161.648 35.6946C160.666 35.1619 159.862 34.3535 159.334 33.369C158.787 32.3644 158.514 31.1628 158.514 29.7644C158.514 28.4016 158.783 27.2134 159.321 26.1998C159.829 25.2174 160.597 24.393 161.54 23.8161C162.482 23.2421 163.572 22.9551 164.81 22.9551C165.993 22.9551 167.007 23.2152 167.85 23.7353C168.693 24.2555 169.343 24.9909 169.8 25.9417C170.258 26.8929 170.486 28.0318 170.486 29.3586V30.1929H160.182V28.7667H169.033L168.603 29.1155C168.603 27.6814 168.285 26.5648 167.648 25.7655C167.011 24.9663 166.083 24.5673 164.863 24.5685C163.948 24.5685 163.173 24.7793 162.538 25.201C161.902 25.6227 161.422 26.2055 161.098 26.9493C160.775 27.6942 160.614 28.5597 160.614 29.5458V29.7086C160.614 30.8028 160.788 31.7264 161.137 32.4795C161.461 33.2055 162.014 33.8056 162.711 34.1882C163.408 34.5758 164.242 34.7696 165.211 34.7696C165.974 34.7709 166.733 34.6482 167.457 34.4062C168.215 34.1462 168.915 33.7394 169.515 33.2091L170.271 34.7429C169.679 35.2809 168.926 35.7113 168.011 36.0341C167.113 36.3531 166.166 36.5159 165.213 36.5155Z" fill="#1C7A56" />
                <path d="M173.635 36.2738V23.1977H175.76V26.2918H175.491C175.797 25.2337 176.326 24.431 177.079 23.8837C177.832 23.3364 178.828 23.018 180.066 22.9285L180.846 22.8477L181.007 24.7314L179.635 24.8924C178.415 24.9998 177.478 25.3899 176.823 26.0628C176.168 26.7356 175.841 27.655 175.841 28.8209V36.2738H173.635Z" fill="#1C7A56" />
                <path d="M184.165 37.6567V35.3084H183.213V34.7852H185.744V35.3084H184.795V37.6567H184.165Z" fill="#1C7A56" />
                <path d="M186.062 37.6583V34.7891H186.591L187.621 36.6734H187.49L188.52 34.7891H189.037V37.6583H188.463V35.7536H188.553L187.723 37.2269H187.368L186.538 35.7495H186.632V37.6583H186.062Z" fill="#1C7A56" />
                <path d="M9.37519 49.7777C10.8391 49.7777 12.0258 48.591 12.0258 47.1271C12.0258 45.6633 10.8391 44.4766 9.37519 44.4766C7.91131 44.4766 6.72461 45.6633 6.72461 47.1271C6.72461 48.591 7.91131 49.7777 9.37519 49.7777Z" fill="#2E9866" />
                <path d="M0.414062 47.6583C0.414062 47.6583 6.72511 40.2647 17.1908 34.1961C20.7989 31.8205 24.5426 29.7694 27.9117 28.699L27.9815 28.6769L28.0536 28.645C28.0536 28.645 28.8298 17.3147 38.2205 0.474609C9.37569 20.1083 0.414062 47.6583 0.414062 47.6583Z" fill="#A5E5C2" />
                <path d="M17.1908 34.1965C17.1908 34.1965 22.078 19.7988 20.5844 1.34766C13.0757 15.375 3.29778 22.4831 3.29778 22.4831C3.29778 22.4831 4.23616 35.3732 0.414062 47.6575C0.414062 47.6587 6.97278 40.6686 17.1908 34.1965Z" fill="#2E9866" />
              </g>
              <defs>
                <clipPath id="clip0_1213_23252">
                  <rect width="189.535" height="50" fill="white" />
                </clipPath>
              </defs>
            </svg>

      }
    </>

  );
};

export default LogoIcon;
