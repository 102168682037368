import styled from "styled-components";

export const ProgressBarContainer = styled.div`
    width: 100%;
    background-color: #DEDEDE; 
    border-radius: 5.5px; /* Rounded corners */
    height: 8px;

    .progress-inner {
        background-color: #15910A;
        color: #fff; /* White text */
        font-size: 12px;
        font-weight: 500;
        height: 4px;
        text-align: center;
        padding: 2px; /* Adjust padding to control the height of the progress bar */
        border-radius: 5.5px; /* Rounded corners */
        width: ${props => props.progress}%; /* Dynamic width based on the progress prop */
    }
`;

export const ProgressInfoWrapper = styled.div`
    display: flex;
    width: -webkit-fill-available; /* For WebKit-based browsers */
    width: -moz-available; /* For Mozilla Firefox */
    width: fill-available; /* For other modern browsers */
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
`;

export const ProgressInfo = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.color_theme.color};
    opacity: 0.4;
`;

export const ProgressTimeWrapper = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.color_theme.color};
    opacity: 0.4;
`;

