import React from 'react'

const BackspaceIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.85 12.75L13.05 18.95L12 20L4 12L12 4L13.05 5.05L6.85 11.25H20V12.75H6.85Z" />
    </svg>
  )
}

export default BackspaceIcon