import React from "react";

const CompanyAddressIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M2 21V3H11.75V7.125H22V21H2ZM3.5 19.5H6.125V16.875H3.5V19.5ZM3.5 15.375H6.125V12.75H3.5V15.375ZM3.5 11.25H6.125V8.625H3.5V11.25ZM3.5 7.125H6.125V4.5H3.5V7.125ZM7.625 19.5H10.25V16.875H7.625V19.5ZM7.625 15.375H10.25V12.75H7.625V15.375ZM7.625 11.25H10.25V8.625H7.625V11.25ZM7.625 7.125H10.25V4.5H7.625V7.125ZM11.75 19.5H20.5V8.625H11.75V11.25H13.75V12.75H11.75V15.375H13.75V16.875H11.75V19.5ZM16.375 12.75V11.25H17.875V12.75H16.375ZM16.375 16.875V15.375H17.875V16.875H16.375Z" />
        </svg>
    );
};

export default CompanyAddressIcon;
