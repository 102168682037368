import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, TextWrapper, ShortcutWrapper } from "./SharePointInfo.styled";
import { connect } from "react-redux";
import SharePointCloudIcon from "../../assets/svgs/SharePointCloudIcon";

const SharePointInfo = ({ high_contrast, dark_mode }) => {
    const [cloudColor, setCloudColor] = useState("#D9D9D9");
    const [iconColor, setIconColor] = useState("#F2F2F2");
    const [circleColor, setCircleColor] = useState("#AAAAAA");
    const [arrowColor, setArrowColor] = useState("#AAAAAA");

    useEffect(() => {
        if (!high_contrast && !dark_mode) {
            setCloudColor("#D9D9D9");
            setIconColor("#F2F2F2");
            setCircleColor("#AAAAAA");
            setArrowColor("#AAAAAA");

        }
        if (high_contrast && !dark_mode) {
            setCloudColor("#E0E0E0");
            setIconColor("#F2F2F2");
            setCircleColor("#868686");
            setArrowColor("#868686");

        }

        if (!high_contrast && dark_mode) {
            setCloudColor("#505050");
            setIconColor("#AAAAAA");
            setCircleColor("#3D3D3D");
            setArrowColor("#AAAAAA");
        }

        if (high_contrast && dark_mode) {
            setCloudColor("#525252");
            setIconColor("#F2F2F2");
            setCircleColor("#3D3D3D");
            setArrowColor("F2F2F2");
        }
    }, [high_contrast, dark_mode]);

    return (
        <Wrapper >
            <SharePointCloudIcon
                cloudColor={cloudColor}
                iconColor={iconColor}
                circleColor={circleColor}
                arrowColor={arrowColor}
            />
            <TextWrapper>Please upload the document in SharePoint.</TextWrapper>
        </Wrapper>
    );
};

SharePointInfo.propTypes = {};
const mapStateToProps = (state) => {
    return {
        high_contrast: state?.a11y?.high_contrast,
        dark_mode: state?.a11y?.dark_mode,
    };
};

export default connect(mapStateToProps)(SharePointInfo);
