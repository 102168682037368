import styled from "styled-components";

export const MembersSideDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  overflow: scroll;
`;

export const TopNavWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #f2f2f2;
`;

export const TopNavText = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const TopNavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const ItemWrapper = styled.div`
`;

export const BottomNavWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: flex-end;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  cursor: pointer;
`;


export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-self: stretch;
  margin: 20px;
  border: ${(props) => `1px solid ${props.theme.color_theme.tableColor}`};
  border-radius: 8px;
`;

export const TableHeading = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: flex-start;
  gap: 20px;
  border-bottom: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${(props) => props.theme.color_theme.tableColor};
  justify-content: space-between;
`;

export const HeadingCell = styled.div`
  width: ${({ width }) => width};
  display: flex;
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  flex-direction: column;
`;

export const TableDataRow = styled.div`
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 24px;
  height: 33px;
  background: ${({ theme, index }) => (index % 2 === 0 ? theme.color_theme.tableStripLight : theme.color_theme.tableStripColorDark)};
  border-bottom-right-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  border-bottom-left-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available; /* For WebKit-based browsers */
  width: -moz-available; /* For Mozilla Firefox */
  width: fill-available; /* For other modern browsers */
`;

export const DataCell = styled.div`
  width: ${({ width }) => width};
  display: flex;
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;

  /* Add flex properties to span elements */
  span {
    display: flex;
    align-items: center;
    gap: 2px;
    color : ${(props) => props.theme.color_theme.color};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 12}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
`;

export const ClientDetailsWrapper = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  flex-direction: column;
  gap: 18px;
`
export const DetailsWrapper = styled.div`
  display: flex;
  padding: 20px 0px 20px 0px;
  flex-direction: column;
  gap: 16px;
`

export const RowWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const HeadingWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  align-self: stretch;
`;


