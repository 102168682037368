import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => theme.color_theme.bodyColor};
    color: ${({ theme }) => theme.color_theme.color};
    transition: all 0.50s linear;
  }

  :focus {
    outline: ${(props) => `2px solid ${props.theme.color_theme.primaryColor}`};
  }

  /* WebKit-based browsers (Chrome, Safari) */
  /* Customize the scrollbar */
  ::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  /* Customize the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9; /* Color of the thumb */
    border-radius: 2.5px; /* Radius of the thumb */
  }

  /* Customize the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the track */
  }

  /* Firefox */
  /* Customize the scrollbar width and color */
  /* Note: Firefox supports scrollbar styling starting from version 64 */
  /* You can adjust these values to fit your design */
  scrollbar-width: thin;
  scrollbar-color: #D9D9D9 transparent;
  
  `