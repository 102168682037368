import styled from "styled-components";

export const AuditListWrapper = styled.div `
  display: flex;
  padding: 0px 4px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const AuditListOptionsWrapper = styled.div `
  display: flex;
`;

export const AuditListTitle = styled.div `
  color : ${(props) => props.theme.color_theme.bodyColor};
  background-color : ${(props) => props.theme.color_theme.primaryColor};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  width: 0px;
  align-items: center;
  padding: 0px 100px 0px 24px;

  @media screen and (min-width: 1080px) {
    width: 65px; 
  }
`;



export const AuditListTitleIcon = styled.div `
  color : ${(props) => props.theme.color_theme.selectedTheme === 'NC_DM_THEME' ? props.theme.color_theme.color : props.theme.color_theme.bodyColor};
  background-color : ${(props) => props.theme.color_theme.primaryColor};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 18}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  // width: 152px;
  width: 0px; 
  height: 28px;
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  padding: 0px 100px 0px 24px;

  @media screen and (min-width: 1080px) {
    width: auto; 
  }
`;

export const ButtonContainer = styled.div ` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div `
  width: 80%;
  height:3px;
  background-color: #2E9866;
  margin-left: ${props => props.marginLeft || "2px"};
`;