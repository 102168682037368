import React from "react";
import PropTypes from "prop-types";
import {
  A11YSideDrawerItemWrapper,
  IconWrapper,
  InfoWrapper,
  ActionKey,
  ActionWrapper,
  Title,
  Subtitle,
  ToggleSwitchWrapper,
  ContentWrapper,
  TitleContainer,
} from "./A11ySideDrawerItem.styled";

const A11YSideDrawerItem = ({
  title,
  icon,
  subtitle,
  toggleSwitch,
  accessKeyText,
  ...props
}) => (
  <A11YSideDrawerItemWrapper {...props}>
    <InfoWrapper>
      <TitleContainer>
        <IconWrapper>{icon}</IconWrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </ContentWrapper>
      </TitleContainer>
      <ActionWrapper>
        <ToggleSwitchWrapper>{toggleSwitch}</ToggleSwitchWrapper>
        <ActionKey>{accessKeyText}</ActionKey>
      </ActionWrapper>
    </InfoWrapper>
  </A11YSideDrawerItemWrapper>
);

A11YSideDrawerItem.propTypes = {};

A11YSideDrawerItem.defaultProps = {};

export default A11YSideDrawerItem;
