import React from "react";
import DocsSmall from "../DocsSmall.png"
const DocxIcon = () => {
  return (
    <>
    <img src={DocsSmall} alt="dcs"/>
    </>
  );
};

export default DocxIcon;
