import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  SideDrawerWrapper,
  SideDrawerBox,
  Backdrop,
} from "./SideDrawer.styled";
import { useDispatch } from "react-redux";
import { setOverlay, setOverlayName } from "../../store/slices/A11ySlice";
const FocusTrap = require("focus-trap-react");

const SideDrawer = ({ children, isOpen, handleOpen, overlayName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay(true));
    dispatch(setOverlayName(overlayName));
    return () => {
      dispatch(setOverlay(false));
      dispatch(setOverlayName(""));
    };
  }, []);

  return (
    <FocusTrap>
      <SideDrawerWrapper isOpen={isOpen}>
        <Backdrop isOpen={isOpen} onClick={handleOpen} />
        <SideDrawerBox isOpen={isOpen}>{children}</SideDrawerBox>
      </SideDrawerWrapper>
    </FocusTrap>
  );
};

SideDrawer.propTypes = {};

SideDrawer.defaultProps = {};

export default SideDrawer;
