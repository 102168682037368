import { css } from "styled-components";

const Fonts = {
  Headline1: css`
    font-size: ${(props) => props.theme.font_delta+48}px;
    font-weight: 600;
    line-height: 72px;
  `,
  Headline2: css`
    font-size: ${(props) => props.theme.font_delta+36}px;
    font-weight: 600;
    line-height: 54px;
  `,
  Headline3: css`
    font-size: ${(props) => props.theme.font_delta+24}px;
    font-weight: 600;
    line-height: 24px;
  `,
  Subtitle1: css`
    font-size: ${(props) => props.theme.font_delta+20}px;
    font-weight: 600;
    line-height: 30px;
  `,
  Subtitle2: css`
    font-size: ${(props) => props.theme.font_delta+16}px;
    font-weight: 500;
    line-height: normal;
  `,
  Subtitle3: css`
    font-size: ${(props) => props.theme.font_delta+14}px;
    font-weight: 600;
    line-height: 22px;
  `,
  Body1: css`
    font-size: ${(props) => props.theme.font_delta+14}px;
    font-weight: 500;
    line-height: normal;
  `,
  Body2: css`
    font-size: ${(props) => props.theme.font_delta+13}px;
    font-weight: 500;
    line-height: normal;
  `,
  Body3: css`
    font-size: ${(props) => props.theme.font_delta+12}px;
    font-weight: 500;
    line-height: normal;
  `,
  Body3Bold: css`
    font-size: ${(props) => props.theme.font_delta+12}px;
    font-weight: 600;
    line-height: normal;
  `,
  Body4: css`
    font-size: ${(props) => props.theme.font_delta+11}px;
    font-weight: 500;
    line-height: normal;
  `,
   Small: css`
   font-size: ${(props) => props.theme.font_delta+10}px;
   font-weight: 500;
   line-height: normal;
 `,
};

export default Fonts;
