import { styled } from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  display: flex;
  width: 800px;
  height: 520px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 24px;
`;

export const PaginationContainer = styled.div`
  margin: 14px 0px;
`;

export const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

export const PreviewTitle = styled.div`
  ${Fonts.Body3};
`;

export const PreviewSubtext = styled.div`
  ${Fonts.Small};
  color: #666666;
`;

export const PreviewBody = styled.div`
  width: 400px;
  height: 350px;
  padding: 10px 0px;
  overflow: auto
`;

export const PreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;
export const Frame = styled.iframe`
  height: 100%;
  width: 100%;
`;
