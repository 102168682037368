import styled from "styled-components";


export const Content = styled.div`
  position: relative;
  height: 50px;
  right: -300px;
  top: 200px;
  background-color: blue;
  z-index: 40;
`;

export const ExtraTextWrapper = styled.span`
  margin-left: 6px;
  font-size: ${(props) => props.theme.font_delta + 16}px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  margin-right: 10px;
`;

export const RightIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 18px;
  right: 0px;
  fill: ${(props) => props.theme.color_theme.color};
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
`;

export const DropDownWrapper = styled.div`
  position: relative;
`;

export const DropDownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  padding-left: ${({ icon }) => (icon ? "50px" : "20px")};
  background-position: 2px center;
  background-repeat: no - repeat;
  background-size: auto;
  border-radius: 18px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: ${(props) => props.theme.color_theme.inputFillColor};
  color: ${(props) => props.theme.color_theme.color};
  width: 300px;
  height: 54px;
  text-transform: capitalize;
`;

export const SVG = styled.svg`
  margin-left: 4px;
  height: 1rem;
  width: 1.2rem;
`;

export const OptionMenu = styled.div`
  transform-origin: top bottom;
  position: absolute;
  right: 3px;
  top: 52px;
  background-color: ${(props) => props.theme.color_theme.bodyColor};
  margin-top: -3.8rem;
  padding: 14px 0px;
  margin-right: 0.5rem;
  width: 87%;
  max-height: 16rem;
  border:  ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  z-index: 40;
    &:hover {
    outline: none;
  }
`;

export const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => props.selected ? "#2E9866" : props.theme.color_theme.bodyColor};
  height: 34px;
  padding: 0px 10px;

    &:hover {
      background-color: #2E9866;
    }
`;

export const Label = styled.label`
  display: block; 
  color : ${(props) => props.theme.color_theme.color};
  opacity: 0.5;
  font-size: ${(props) => props.theme.font_delta + 10} px;
  font-family: Segoe UI;

`;

export const OptionText = styled.label`
  display: block;
  color : ${(props) => props.theme.color_theme.color};
  font-weight: 400;
  font-size: ${(props) => props.theme.font_delta + 13} px;
  font-family: Segoe UI;
  text-transform: capitalize;
`;

export const SvgTest = styled.button`
  width: 30px;
  height: 40px;
  border: none;
  background-color: transparent;

  /* background-color: blue; */
`;
