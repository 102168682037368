import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

export const CountWrapper = styled.div `
  border-radius: 14px;
  border: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  background: ${(props) => props.theme.color_theme.bodyColor};
  display: flex;
  padding: 9px 12px 9px 14px;
  align-items: center;
  gap: 6px;
  margin-left: 10px;
`;

export const Count = styled.div`
  ${Fonts.Body3};
`;

export const TotalCount = styled.div`
  ${Fonts.Body3};
  color: #666666;
`;
export const AccessKey = styled.div`
  ${Fonts.Body3};
  color: darkgrey;
`;