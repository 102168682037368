import React, { useEffect, useState } from "react";
import {
  EditClientModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  MemberDetailWrapper,
  AddMemberWrapper,
  AddMemberLabel,
  MemberDetailContainer,
  ExtraWrapper,
  EntitlementDetailWrapper,
  ErrorWrapper,
  RadioButtonWrapper,
  SharepointWrapper,
} from "./EditClientModal.styled";
import Button from "../../components/Button/Button";
import SelectProtocolIcon from "../../assets/svgs/SelectProtocolIcon";
import SearchAutocomplete from "../../components/AutoCompleteSearch/AutoCompleteSearch";
import InputField from "../../components/InputField/InputField";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import EntitlementIcon from "../../assets/svgs/EntitlementIcon"
import {
  EditClientInfo,
  createClient,
  getClientInfo,
  searchUser,
} from "../../apis/ApiServerRequests";
import { useDispatch } from "react-redux";
import { incrClientCount } from "../../store/slices/ClientSlice";
import DropDownNew from "../../components/Dropdown/Dropdown";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import Emailcon from "../../assets/svgs/Emailcon";
import CompanyAddressIcon from "../../assets/svgs/CompanyAddressIcon";
import PhoneIcon from "../../assets/svgs/PhoneIcon";
import BuildIcon from "../../assets/svgs/BuildIcon";
import validateEmail from "../../helpers/validateEmail";
import useKeyPress from "../../hooks/useKeyPress";
import BusinessIcon from "../../assets/svgs/BusinessIcon";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";

const specialChars = /[!@#$%^&*(),.?":{}|<>\-`~_+=;\[\]\/'']/;

const sharepointSiteDetails = () => [
  // {label: 'Custom Sharepoint URL', value: '', inputCommand: {
  //   text: "[Alt+Ctrl+1]",
  //   key: "1",
  //   subkey: ["altKey", "ctrlKey"],
  // }},
  // {label: 'Custom Sharepoint Tenant Name', value: '', inputCommand: {
  //   text: "[Alt+Ctrl+2]",
  //   key: "2",
  //   subkey: ["altKey", "ctrlKey"],
  // }},
  // {label: 'Custom Sharepoint Tenant ID', value: '', inputCommand: {
  //   text: "[Alt+Ctrl+3]",
  //   key: "3",
  //   subkey: ["altKey", "ctrlKey"],
  // }},
  {label: 'Custom Sharepoint Client ID', value: '', inputCommand: {
    text: "[Alt+Ctrl+4]",
    key: "4",
    subkey: ["altKey", "ctrlKey"],
  }},
  {label: 'Custom Sharepoint Client Secret', value: '', inputCommand: {
    text: "[Alt+Ctrl+5]",
    key: "5",
    subkey: ["altKey", "ctrlKey"],
  }}
]

const EditClientModal = ({ onClose, onSuccess, overlayName, pharma_name }) => {
  const dispatch = useDispatch();
  const [pharmaName, setPharmaName] = useState("");
  const [oldPharmaName, setOldPharmaName] = useState("");
  const [address, set_address] = useState("");
  const [business_contact_email, set_business_contact_email] = useState("");
  const [business_contact_name, set_business_contact_name] = useState("");
  const [technical_contact_name, set_technical_contact_name] = useState("");
  const [technical_contact_email, set_technical_contact_email] = useState("");
  const [entitlement_type, set_entitlement_type] = useState([]);
  const [userDetail, setUserDetail] = useState([
    { role: "owner", showOptions: true },
  ]);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [sharepointSite, setSharepointSite] = useState(sharepointSiteDetails());
  const [customDomain, setCustomDomain] = useState('');
  const [showError, setShowError] = useState("");
  const [showErrorPopUp, setShowErrorPopUp] = useState(false); 

  const handleInputChange = (e, callback) => {
    callback(e.target.value);
  };

  const changeSelectedValue = (index) => {
    setSelectedIndex(index)
  }

  const handleSharepointDetails = (e, index) => {
    const currentState = [...sharepointSite]
    currentState[index].value = e.target.value;
    setSharepointSite(currentState)
  }

  const addEntitlement = (entitlementValue) => {
    if (!entitlement_type.includes(entitlementValue)) set_entitlement_type((prevEntitlement) => [...prevEntitlement, entitlementValue]);
  };

  const deleteEntitlementfunction = (itemIndex) => {
    let data = [...entitlement_type];
    data.splice(itemIndex, 1);
    set_entitlement_type(data);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleValidation = () => {
    let valid = true;

    if (pharmaName) {
      if (specialChars.test(pharmaName)) {
        valid = false;
      }
    }

    if (emailError) {
      valid = false;
    }

    if (entitlement_type.length < 1 ) valid = false

    // if (selectedIndex === 1) valid = !sharepointSite.some(item => item?.value === '')

    if (selectedIndex === null) valid = false

    if (!address) valid = false;

    if (!business_contact_name) valid = false;
    if (!business_contact_email) valid = false;
    if (!technical_contact_name) valid = false;
    if (!technical_contact_email) valid = false;

    if (business_contact_email && !validateEmail(business_contact_email)) valid = false;
    if (technical_contact_email && !validateEmail(technical_contact_email)) valid = false;  

    return valid;
  };

  useEffect(() => {
    if (pharmaName) {
  
      if (specialChars.test(pharmaName)) {
        setErrorMessage('*Special characters are not allowed');
      } else {
        setErrorMessage('');
      }
    }
  }, [pharmaName]);

  const handleEditClient = () => {
    let pharma_details = {
      address,
      business_contact_name,
      business_contact_email,
      technical_contact_name,
      technical_contact_email,
      old_pharma_name : oldPharmaName,
      custom_domain: selectedIndex === 1,
      migration: customDomain === 'False' && selectedIndex === 1 ? true : false
    };

    if (selectedIndex === 1 && customDomain === 'False') {
      pharma_details = {
        address,
        business_contact_name,
        business_contact_email,
        technical_contact_name,
        technical_contact_email,
        old_pharma_name : oldPharmaName,
        custom_domain: selectedIndex === 1,
        migration: customDomain === 'False' && selectedIndex === 1 ? true : false,
        sharepoint_client_url: sharepointSite[0]?.value || '',
        tenant: sharepointSite[1]?.value || '',
        tenant_id: sharepointSite[2]?.value || '',
        sharepoint_client_id: sharepointSite[3]?.value || '',
        sharepoint_client_secret: sharepointSite[4]?.value || '',
      };
    }

    if (customDomain === 'True' && selectedIndex === 1) {
      pharma_details= {
        address,
        business_contact_name,
        business_contact_email,
        technical_contact_name,
        technical_contact_email,
        old_pharma_name : oldPharmaName,
        custom_domain: selectedIndex === 1,
        sharepoint_client_id: sharepointSite[0]?.value || '',
        sharepoint_client_secret: sharepointSite[1]?.value || '',
      }
    }
    let data = {
      entitlement_type: entitlement_type,
    };

    setLoading(true);
    // onSuccess({ loading: true });
    EditClientInfo(pharmaName || oldPharmaName , data, pharma_details)
    .then(() => {
        onClose();
        onSuccess({ loading: false });
        dispatch(incrClientCount(1));
        setLoading(false)
      }).catch((error) => {
        setShowErrorPopUp(true)
        setShowError(error?.response?.data?.detail || "something went wrong !")
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (pharma_name) {
      getClientInfo(pharma_name).then(({ data }) => {
        setOldPharmaName(pharma_name);
        set_address(data.address);
        set_business_contact_email(data.business_contact_email);
        set_business_contact_name(data.business_contact_name);
        set_technical_contact_name(data.technical_contact_name);
        set_technical_contact_email(data.technical_contact_email);
        set_entitlement_type(data.entitlement_type);
        setSelectedIndex(data?.custom_domain === 'True' ? 1 : 0)
        setSharepointSite(data?.custom_domain === 'False' ? [
          {label: 'Custom Sharepoint URL', value: data?.sharepoint_client_url || '', inputCommand: {
            text: "[Alt+Ctrl+1]",
            key: "1",
            subkey: ["altKey", "ctrlKey"],
          }},
          {label: 'Custom Sharepoint Tenant Name', value: "", inputCommand: {
            text: "[Alt+Ctrl+2]",
            key: "2",
            subkey: ["altKey", "ctrlKey"],
          }},
          {label: 'Custom Sharepoint Tenant ID', value: data?.tenant_id || '', inputCommand: {
            text: "[Alt+Ctrl+3]",
            key: "3",
            subkey: ["altKey", "ctrlKey"],
          }},
          {label: 'Custom Sharepoint Client ID', value: data?.sharepoint_client_id || '', inputCommand: {
            text: "[Alt+Ctrl+4]",
            key: "4",
            subkey: ["altKey", "ctrlKey"],
          }},
          {label: 'Custom Sharepoint Client Secret', value: data?.sharepoint_client_secret || '', inputCommand: {
            text: "[Alt+Ctrl+5]",
            key: "5",
            subkey: ["altKey", "ctrlKey"],
          }}
        ]
        : [
          {label: 'Custom Sharepoint Client ID', value: data?.sharepoint_client_id || '', inputCommand: {
            text: "[Alt+Ctrl+4]",
            key: "4",
            subkey: ["altKey", "ctrlKey"],
          }},
          {label: 'Custom Sharepoint Client Secret', value: data?.sharepoint_client_secret || '', inputCommand: {
            text: "[Alt+Ctrl+5]",
            key: "5",
            subkey: ["altKey", "ctrlKey"],
          }}
        ]
        )
        setCustomDomain(data?.custom_domain)
      });
    }
    return () => {
      setUserDetail([{}]);
      setPharmaName("");
      set_address("");
      set_business_contact_email("");
      set_business_contact_name("");
      set_technical_contact_name("");
      set_technical_contact_email("");
      set_entitlement_type([]);
      setOldPharmaName("");
      setCustomDomain("")
    };
  }, []);

  useKeyPress(
    "S",
    ["altKey"],
    () => changeSelectedValue(0),
    true,
    overlayName
  );

  useKeyPress(
    "C",
    ["altKey"],
    () => changeSelectedValue(1),
    true,
    overlayName
  );

  useKeyPress("Escape", [], onClose, true, overlayName, true);
  useKeyPress("Enter", [], handleEditClient, true, overlayName, true);

  return (
    <EditClientModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"EDIT COMPANY DETAILS"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Save Client"}
            onClick={handleEditClient}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || loading}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "inherit",
          width: "inherit",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <InputField
          label={"Enter Company Name"}
          labelStyle={{
            position: "absolute",
            padding: "8px 50px",
          }}
          inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
          width={"625"}
          type={"text"}
          value={pharmaName || oldPharmaName}
          onChangefunction={(e) => handleInputChange(e, setPharmaName)}
          icon={CompanyBadgeIcon()}
        />
        {errorMessage && <ErrorWrapper><span>{errorMessage}</span></ErrorWrapper>}
        <ExtraWrapper>
          <Title>Company Address</Title>
          <InputField
            width={"625"}
            labelStyle={{
              position: "absolute",
              padding: "8px 50px",
            }}
            label={`Enter Company Address`}
            value={address}
            type={"text"}
            setError={setEmailError}
            inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
            onChangefunction={(e) => handleInputChange(e, set_address)}
            icon={CompanyAddressIcon()}
          />
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Business Contact</Title>
          <div
            style={{
              display: "flex",
              width: "625px",
              justifyContent: "space-between",
            }}
          >
            <InputField
              width={"300"}
              labelStyle={{
                position: "absolute",
                padding: "8px 50px",
              }}
              label={`Business Contact Name`}
              value={business_contact_name}
              type={"text"}
              setError={setEmailError}
              inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
              onChangefunction={(e) =>
                handleInputChange(e, set_business_contact_name)
              }
              icon={BusinessIcon()}
            />
            <InputField
              width={"300"}
              labelStyle={{
                position: "absolute",
                padding: "8px 50px",
              }}
              label={`Business Contact Email`}
              value={business_contact_email}
              type={"text"}
              setError={setEmailError}
              inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
              onChangefunction={(e) =>
                handleInputChange(e, set_business_contact_email)
              }
              icon={Emailcon()}
            />
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Technical Contact</Title>
          <div
            style={{
              display: "flex",
              width: "625px",
              justifyContent: "space-between",
            }}
          >
            <InputField
              width={"300"}
              labelStyle={{
                position: "absolute",
                padding: "8px 50px",
              }}
              label={`Technical Contact Name`}
              value={technical_contact_name}
              type={"text"}
              setError={setEmailError}
              inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
              onChangefunction={(e) =>
                handleInputChange(e, set_technical_contact_name)
              }
              icon={BuildIcon()}
            />
            <InputField
              width={"300"}
              labelStyle={{
                position: "absolute",
                padding: "8px 50px",
              }}
              label={`Technical Contact Email`}
              value={technical_contact_email}
              type={"text"}
              setError={setEmailError}
              inputStyle={{ paddingBottom: "10px", paddingTop: "30px" }}
              onChangefunction={(e) =>
                handleInputChange(e, set_technical_contact_email)
              }
              icon={Emailcon()}
            />
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Entitlement</Title>
          <div
            style={{
              display: "flex",
              width: "625px",
              justifyContent: "space-between",
              flexDirection:"column",
            }}
          >
             <div style={{ width: 625, marginBottom: "20px" }}>
            <SearchAutocomplete
              placeholder={"Add Entitlement"}
              handleAddMember={addEntitlement}
              searchCommand={{
                text: "[Alt+E]",
                key: "E",
                subkey: ["altKey"],
              }}
              rightIcon={EntitlementIcon()}
              searchEnabled = {false}
              enableAddButton={false}
              isOverlay={true}
              overlayName={overlayName}
            />
          </div>

            {entitlement_type.length > 0 &&
              entitlement_type.map((item, index) => {
                return (
                  <EntitlementDetailWrapper
                    style={{
                      paddingTop: "15px"
                    }}
                  >
                    <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      value={item}
                      type={"text"}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      icon={EntitlementIcon()}
                    />
                    <Button
                      type={"ghost"}
                      icon={DeleteIcon()}
                      text={""}
                      accessKeyText={`[ Ctrl + ${index + 1} ]`}
                      onClick={() => deleteEntitlementfunction(index)}
                      iconStyle={{ marginRight: "0px" }}
                      buttonStyle={{
                        paddingLeft: "0px",
                      }}
                    />
                  </EntitlementDetailWrapper>
                );
              })}
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>SHAREPOINT SITE</Title>
            {customDomain === "False" && <RadioButtonWrapper>
              <RadioButtonGroup
                width={"280"}
                type={"radio"}
                options={[
                  {title: "Synterex Sharepoint", inputCommand: {
                    text: "[Alt+S]",
                    key: "S",
                    subkey: ["altKey"],
                  }}, 
                  {title: "Custom Sharepoint", inputCommand: {
                    text: "[Alt+C]",
                    key: "C",
                    subkey: ["altKey"],
                  }}
                ]}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                selectedIndex={selectedIndex}
                onChange={(index) => changeSelectedValue(index)}
              />
            </RadioButtonWrapper>} 
        </ExtraWrapper>
        {selectedIndex === 1 &&<SharepointWrapper>
          {sharepointSite.map((item, index) => <InputField
            label={item.label}
            labelStyle={{
              position: "absolute",
              padding: "4px 50px",
            }}
            inputStyle={{ paddingBottom: "10px", height: "34px" }}
            width={"584"}
            type={"text"}
            value={item.value}
            onChangefunction={(e) => handleSharepointDetails(e, index)}
            inputCommand={item.inputCommand}
            iconStyle={{paddingRight: '6px'}}
            icon={EntitlementIcon()}
            isOverlay={true}
            overlayName={overlayName}
          />)
          }       
        </SharepointWrapper>
        }
      </div>

      {showErrorPopUp && (
        <SuccessPopup
          msg_processing={"Updating client details, please wait..."}
          msg_done={showError}
          type="Error"
          onClose={() => {
            setShowErrorPopUp(false)
            setShowError('')
            setLoading(false)
          }}
          isOverlay={true}
          overlayName="EditModal"
          loading={false}
        />
      )}
    </EditClientModalWrapper>
  );
};

export default EditClientModal;
