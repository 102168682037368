import React from "react";

const SearchIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_581_2412)">
                <g clip-path="url(#clip1_581_2412)">
                    <path d="M19.9 20.9749L13.325 14.3999C12.825 14.8332 12.242 15.1707 11.576 15.4124C10.91 15.654 10.2014 15.7749 9.45 15.7749C7.6473 15.7749 6.12163 15.1499 4.87298 13.8999C3.62433 12.6499 3 11.1415 3 9.37485C3 7.60819 3.625 6.09985 4.875 4.84985C6.125 3.59985 7.6375 2.97485 9.4125 2.97485C11.1875 2.97485 12.6958 3.59985 13.9375 4.84985C15.1792 6.09985 15.8 7.60944 15.8 9.3786C15.8 10.0928 15.6833 10.7832 15.45 11.4499C15.2167 12.1165 14.8667 12.7415 14.4 13.3249L21 19.8749L19.9 20.9749ZM9.425 14.2749C10.7792 14.2749 11.9302 13.7957 12.8781 12.8374C13.826 11.879 14.3 10.7249 14.3 9.37485C14.3 8.02485 13.826 6.87069 12.8781 5.91235C11.9302 4.95402 10.7792 4.47485 9.425 4.47485C8.05695 4.47485 6.8941 4.95402 5.93645 5.91235C4.97882 6.87069 4.5 8.02485 4.5 9.37485C4.5 10.7249 4.97882 11.879 5.93645 12.8374C6.8941 13.7957 8.05695 14.2749 9.425 14.2749Z" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_581_2412">
                    <rect y="-0.000244141" width="24" height="24" rx="12" fill="white" />
                </clipPath>
                <clipPath id="clip1_581_2412">
                    <rect width="24" height="24" fill="white" transform="translate(0 -0.000244141)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default SearchIcon;
