import React from "react";
import jsonIc from "../json.png"
const JsonIcon = () => {
  return (
    <>
    <img src={jsonIc} alt="json"/>
    </>
  );
};

export default JsonIcon;
