import React from "react";

const NoSearchFoundIcon = ({ searchColor, crossColor, rectColor }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="61" viewBox="0 0 64 61">
            <rect y="0.299316" width="39.1734" height="52.9566" rx="6" fill={rectColor ? rectColor : "#D9D9D9"} />
            <path d="M14.4453 13.3059C14.4453 11.3026 16.0692 9.67871 18.0725 9.67871L34.4585 10.0414C36.4617 10.0414 42.0118 12.5804 42.0118 13.3059V40.1469C42.0118 42.1501 40.3878 43.774 38.3846 43.774H18.0725C16.0692 43.774 14.4453 42.1501 14.4453 40.1469V13.3059Z" fill={rectColor ? rectColor : "#D9D9D9"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7482 35.3864L14.1992 18.8374L16.9526 16.084L33.5016 32.633L30.7482 35.3864Z" fill={crossColor ? crossColor : "#F2F2F2"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1976 32.633L30.7466 16.084L33.5 18.8374L16.951 35.3864L14.1976 32.633Z" fill={crossColor ? crossColor : "#F2F2F2"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.474 43.7048C40.4782 43.7048 47.7775 36.4054 47.7775 27.4012C47.7775 18.397 40.4782 11.0977 31.474 11.0977C22.4698 11.0977 15.1704 18.397 15.1704 27.4012C15.1704 36.4054 22.4698 43.7048 31.474 43.7048ZM31.474 46.6252C42.0911 46.6252 50.6979 38.0183 50.6979 27.4012C50.6979 16.7841 42.0911 8.17725 31.474 8.17725C20.8569 8.17725 12.25 16.7841 12.25 27.4012C12.25 38.0183 20.8569 46.6252 31.474 46.6252Z" fill={searchColor ? searchColor : "#AAAAAA"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.1963 46.3014L42.8438 41.9488L44.9088 39.8838L49.2614 44.2364L47.1963 46.3014Z" fill={searchColor ? searchColor : "#AAAAAA"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.6024 60.6994L45.8047 46.2871L49.2007 42.8003L63.9984 57.2126L60.6024 60.6994Z" fill={rectColor ? rectColor : "#D9D9D9"} />
        </svg>
    );
};

export default NoSearchFoundIcon;
