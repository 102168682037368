import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, TextWrapper, ShortcutWrapper } from "./TitleComponent.styled";
import { connect } from "react-redux";
import TitleIcon from "../../assets/svgs/TitleIcon";

const TitleComponent = ({ high_contrast, dark_mode }) => {
    const [fillColor, setFillColor] = useState("#2E9866");

    useEffect(() => {
        if (!high_contrast && !dark_mode) {
            setFillColor('#2E9866')
        }
        if (high_contrast && !dark_mode) {
            setFillColor('#065A32')
        }

        if (!high_contrast && dark_mode) {
            setFillColor('#2E9866')
        }

        if (high_contrast && dark_mode) {
            setFillColor('#49FFA9')
        }
    }, [high_contrast, dark_mode]);

    return (
        <Wrapper >
            <TitleIcon fillColor={fillColor} />
        </Wrapper>
    );
};

TitleComponent.propTypes = {};
const mapStateToProps = (state) => {
    return {
        high_contrast: state?.a11y?.high_contrast,
        dark_mode: state?.a11y?.dark_mode,
    };
};

export default connect(mapStateToProps)(TitleComponent);
