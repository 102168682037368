import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, TextWrapper, ShortcutWrapper } from "./NoPreviewFound.styled";
import NoPreviewIcon from "../../assets/svgs/NoPreviewIcon";
import Button from "../Button/Button";
import { connect } from "react-redux";
import speechFormat from "../../helpers/speechFormat";

const NoPreviewFound = ({ high_contrast, dark_mode }) => {
    const [docColor, setDocColor] = useState("#E0E0E0");
    const [docFoldColor, setDocFoldColor] = useState("#CFCFCF");
    const [rectColor, setRectColor] = useState("#AAAAAA");
    const [markColor, setMarkColor] = useState("#F2F2F2");

    useEffect(() => {
        if (!high_contrast && !dark_mode) {
            setDocColor("#E0E0E0");
            setDocFoldColor("#CFCFCF");
            setRectColor("#AAAAAA");
            setMarkColor("#F2F2F2");
        }
        if (high_contrast && !dark_mode) {
            setDocColor("#E0E0E0");
            setDocFoldColor("#CFCFCF");
            setRectColor("#868686");
            setMarkColor("#F2F2F2");
        }

        if (!high_contrast && dark_mode) {
            setDocColor("#505050");
            setDocFoldColor("#3D3D3D");
            setRectColor("#3D3D3D");
            setMarkColor("#242424");
        }

        if (high_contrast && dark_mode) {
            setDocColor("#505050");
            setDocFoldColor("#3D3D3D");
            setRectColor("#3D3D3D");
            setMarkColor("#FFFFFF");
        }
    }, [high_contrast, dark_mode]);

    return (
        <Wrapper >
            <NoPreviewIcon
                docColor={docColor}
                docFoldColor={docFoldColor}
                rectColor={rectColor}
                markColor={markColor}
            />
            <TextWrapper>Please select a doc from left panel to preview it.</TextWrapper>
        </Wrapper>
    );
};

NoPreviewFound.propTypes = {};
const mapStateToProps = (state) => {
    return {
        high_contrast: state?.a11y?.high_contrast,
        dark_mode: state?.a11y?.dark_mode,
    };
};

export default connect(mapStateToProps)(NoPreviewFound);
