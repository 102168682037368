import styled from "styled-components";

export const A11YSideDrawerItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 10px 8px 10px 0px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  fill: ${(props) => props.theme.color_theme.color}
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 226px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 4px;
`;

export const Title = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Subtitle = styled.div`
  align-self: stretch;
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.selectedTheme === "NC_DM_THEME" ? "0.4" : props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 10}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20.5px;
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 40px;
`;

export const ActionKey = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
`;
