import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect } from "react-redux";

const SkeletonLoader = ({ high_contrast, dark_mode, ...props }) => {
    const [baseColor, setBaseColor] = useState("#F3F3F3");
    const [highlightColor, setHighlightColor] = useState("#DBDBDB ");

    useEffect(() => {
        if (!high_contrast && !dark_mode) {
            setBaseColor("#F2F2F2");
            setHighlightColor("#D9D9D9");
        }
        if (high_contrast && !dark_mode) {
            setBaseColor("#EAE8E8");
            setHighlightColor("#C9C9C9");
        }

        if (!high_contrast && dark_mode) {
            setBaseColor("#2C2C2D");
            setHighlightColor("#3E3E3E");
        }

        if (high_contrast && dark_mode) {
            setBaseColor("#0F0F0F");
            setHighlightColor("#191919");
        }
    }, [high_contrast, dark_mode]);

    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
            <Skeleton {...props} />
        </SkeletonTheme>
    );
};

SkeletonLoader.propTypes = {};

SkeletonLoader.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        high_contrast: state?.a11y?.high_contrast,
        dark_mode: state?.a11y?.dark_mode,
    };
};

export default connect(mapStateToProps)(SkeletonLoader);
