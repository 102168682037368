import React, { useEffect, useState } from "react";
import {
  UpdateClientModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  MemberDetailWrapper,
  AddMemberWrapper,
  AddMemberLabel,
  MemberDetailContainer,
} from "./UpdateClientModal.styled";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import {
  getMembersList,
  addClientUsers,
  deleteClientUsers,
  searchUser,
} from "../../apis/ApiServerRequests";
import { useDispatch } from "react-redux";
import { incrClientCount } from "../../store/slices/ClientSlice";
import DropDownNew from "../../components/Dropdown/Dropdown";
import InfoTextarea from "../../components/InfoTextarea/InfoTextarea";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import Emailcon from "../../assets/svgs/Emailcon";
import UserIcon from "../../assets/svgs/UserIcon";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import validateEmail from "../../helpers/validateEmail";
import useKeyPress from "../../hooks/useKeyPress";
import SearchAutocomplete from "../../components/AutoCompleteSearch/AutoCompleteSearch";

const UpdateClientModal = ({
  onClose,
  onSuccess,
  pharma_name,
  overlayName,
}) => {
  const dispatch = useDispatch();

  const [oldUserDetail, setOldUserDetail] = useState([]);
  const [oldUserDeleted, setOldUserDeleted] = useState([]);
  const [oldUserCount, setOldUserCount] = useState(0);
  const [userDetail, setUserDetail] = useState([]);

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [debounce, setDebounce] = useState(null);
  const [emailoptions, setEmailOptions] = useState([]);

  const addMemberfunction = (value) => {
    if (value?.email && value?.name) {
      setUserDetail([...userDetail, { name: value?.name, email: value?.email, role: "owner", showOptions: false }]);
    } else if (value?.email) {
      setUserDetail([...userDetail, { email: value?.email, role: "owner", showOptions: false }]);
    } else if (value?.name) {
      setUserDetail([...userDetail, { name: value?.name, role: "owner", showOptions: false }]);
    }
  };

  const deleteMemberfunction = (itemIndex) => {
    let data = [...userDetail];
    data.splice(itemIndex, 1);
    setUserDetail(data);
  };

  const deleteOldMemberfunction = (itemIndex) => {
    let data = [...oldUserDetail];
    let x = data.splice(itemIndex, 1);
    setOldUserDeleted([...oldUserDeleted, ...x]);
    setOldUserDetail(data);
  };

  const handleValidation = () => {
    let valid = true;

    if (userDetail.length < 1 && oldUserDetail.length === oldUserCount)
      valid = false;
    userDetail.forEach((e) => {
      let { name, email, role } = e;
      if (!(name && validateEmail(email) && role)) valid = false;
    });
    if (!pharma_name) {
      valid = false;
    }
    if (emailError) {
      valid = false;
    }

    return valid;
  };

  const handleUserInputChange = (e, index, type) => {
    if (type === "email") {
      let data = [...userDetail];
      data[index] = {
        ...data[index],
        email: e.target.value,
        showOptions: true,
      };
      setUserDetail(data);
    }

    if (type === "name") {
      let data = [...userDetail];
      data[index] = { ...data[index], name: e.target.value };
      setUserDetail(data);
    }

    if (type === "role") {
      let data = [...userDetail];
      data[index] = { ...data[index], role: e };
      setUserDetail(data);
    }
  };

  useEffect(() => {
    setLoading(true);
    getMembersList(pharma_name)
      .then(({ data }) => {
        let members = data.members.map((item) => ({
          name: item.name,
          email: item.email,
          role: item.role,
        }));
        setOldUserDetail(members);
        setOldUserCount(members.length);
      })
      .finally(() => setLoading(false));

    return () => {
      setUserDetail([]);
    };
  }, []);

  const handleUpdateClient = () => {
    if (!handleValidation()) return;

    setUpdating(true);
    onSuccess({ loading: true });

    if (userDetail.length > 0) {
      addClientUsers(pharma_name, { users: userDetail })
        .then(() => {
          dispatch(incrClientCount(1));
          setUserDetail([{}]);
          onClose();
          onSuccess({ loading: false });
        })
        .finally(() => setUpdating(false));
    }

    if (oldUserDeleted.length > 0) {
      deleteClientUsers(pharma_name, { users: oldUserDeleted })
        .then(() => {
          dispatch(incrClientCount(1));
          setUserDetail([{}]);
          onClose();
          onSuccess({ loading: false });
        })
        .finally(() => setUpdating(false));
    }
  };

  const handleDelete = (x) =>
    oldUserDetail.length >= x + 1
      ? deleteOldMemberfunction(x)
      : deleteMemberfunction(x);

  useKeyPress("Escape", [], onClose, true, overlayName, true);
  useKeyPress("1", ["altKey"], () => handleDelete(0), true, overlayName);
  useKeyPress("2", ["altKey"], () => handleDelete(1), true, overlayName);
  useKeyPress("3", ["altKey"], () => handleDelete(2), true, overlayName);
  useKeyPress("4", ["altKey"], () => handleDelete(3), true, overlayName);
  useKeyPress("5", ["altKey"], () => handleDelete(4), true, overlayName);
  useKeyPress("6", ["altKey"], () => handleDelete(5), true, overlayName);
  useKeyPress("7", ["altKey"], () => handleDelete(6), true, overlayName);
  useKeyPress("8", ["altKey"], () => handleDelete(7), true, overlayName);
  useKeyPress("9", ["altKey"], () => handleDelete(8), true, overlayName);
  useKeyPress("+", [], addMemberfunction, true, overlayName);
  useKeyPress("Enter", [], handleUpdateClient, true, overlayName, true);

  return (
    <UpdateClientModalWrapper>
      <HeaderWrapper>
        <HeadTitle>Manage Users</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
          />
          <Button
            type={"secondary"}
            text={updating ? "Saving..." : "Update Users"}
            onClick={handleUpdateClient}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || updating}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "inherit",
          width: "inherit",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div style={{ width: 584, marginBottom: "20px" }}>
          <SearchAutocomplete
            placeholder={"Search User to Add"}
            autoFocus={true}
            handleAddMember={addMemberfunction}
            searchCommand={{
              text: "[Alt+S]",
              key: "S",
              subkey: ["altKey"],
            }}
            isOverlay={true}
            overlayName={overlayName}
          />
        </div>
        <AddMemberWrapper>
          <Title>Users</Title>
          <MemberDetailContainer>
            {loading ? (
              <div style={{ width: "78%" }}>
                <SkeletonLoader count={4} height={60} />
              </div>
            ) : (
              <>
                {oldUserDetail.map((item, index) => {
                  return (
                    <MemberDetailWrapper
                      bottomPadding={
                        index == oldUserDetail.length - 1 ? "35" : false
                      }
                      style={{
                        paddingTop: index == 0 ? "0px" : "13px",
                        borderBottom:
                          index == oldUserDetail.length - 1
                            ? "none"
                            : "1px solid #f2f2f2",
                      }}
                    >
                      <>
                        <InfoTextarea
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`User's Name`}
                          inputStyle={{
                            paddingBottom: "10px",
                            paddingTop: "20px",
                          }}
                          icon={UserIcon()}
                          width={"280"}
                          value={item.name}
                        />
                        <InfoTextarea
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`User's Email`}
                          inputStyle={{
                            paddingBottom: "10px",
                            paddingTop: "20px",
                          }}
                          icon={Emailcon()}
                          width={"280"}
                          value={item.email}
                        />
                        <InfoTextarea
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`User's Role`}
                          inputStyle={{
                            paddingBottom: "10px",
                            paddingTop: "20px",
                          }}
                          icon={CompanyBadgeIcon()}
                          width={"180"}
                          value={`${item.role[0].toUpperCase() + item.role.substring(1)
                            }`}
                        />
                      </>
                      {
                        index !== 0 && item.role[0] !== "owner" ?
                          <Button
                            type={"ghost"}
                            icon={DeleteIcon()}
                            text={""}
                            accessKeyText={`[ Alt + ${index + 1} ]`}
                            onClick={() => deleteOldMemberfunction(index)}
                            iconStyle={{ marginRight: "0px" }}
                          />
                          : <div style={{ visibility: "hidden", width: "130px" }}>

                          </div>
                      }
                    </MemberDetailWrapper>
                  );
                })}
                {userDetail.map((item, index) => {
                  return (
                    <MemberDetailWrapper
                      bottomPadding={
                        index == userDetail.length - 1 ? "35" : false
                      }
                      style={{
                        paddingTop: index == 0 ? "0px" : "13px",
                        borderBottom:
                          index == userDetail.length - 1
                            ? "none"
                            : "1px solid #f2f2f2",
                      }}
                    >
                      <>
                        <InputField
                          width={"280"}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`Enter User's Name`}
                          value={item.name}
                          type={"text"}
                          inputStyle={{
                            paddingBottom: "10px",
                            height: "34px"
                          }}
                          onChangefunction={(e) =>
                            handleUserInputChange(e, index, "name")
                          }
                          icon={UserIcon()}
                        />
                        <InputField
                          width={"280"}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`Enter User's Email`}
                          value={item.email}
                          type={"email"}
                          setError={setEmailError}
                          inputStyle={{
                            paddingBottom: "10px",
                            height: "34px"
                          }}
                          onChangefunction={(e) =>
                            handleUserInputChange(e, index, "email")
                          }
                          icon={Emailcon("black")}
                        />

                        <DropDownNew
                          width={"180"}
                          label={`Select User's Role`}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          options={["owner", "member"]}
                          defaultText={`${item.role}`}
                          onChangefunction={(value) =>
                            handleUserInputChange(value, index, "role")
                          }
                          icon={CompanyBadgeIcon("black")}
                        />
                      </>
                      <Button
                        type={"ghost"}
                        icon={DeleteIcon()}
                        text={""}
                        accessKeyText={`[ Alt + ${oldUserDetail.length + index + 1
                          } ]`}
                        onClick={() => deleteMemberfunction(index)}
                        iconStyle={{ marginRight: "0px" }}
                      />
                    </MemberDetailWrapper>
                  );
                })}
              </>
            )}
          </MemberDetailContainer>
        </AddMemberWrapper>
        {/* <AddMemberLabel>
          {loading || (
            <Button
              type="tertiary"
              text="Add a user [ + ]"
              onClick={addMemberfunction}
              buttonStyle={{
                border: "1px solid #F2F2F2",
                padding: "4px 12px",
                height: "26px",
              }}
            />
          )}
        </AddMemberLabel> */}
      </div>
    </UpdateClientModalWrapper>
  );
};

export default UpdateClientModal;
