import styled, { keyframes, css } from "styled-components";

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ theme, thres, width }) =>
    thres && theme.window_width < thres
      ? "310px"
      : width
        ? `${width}px`
        : "100%"};
`;

export const StyledInput = styled.input`
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  height: ${({ height }) => (height ? height : "")};
  padding-left: ${({ hasIcon }) => (hasIcon ? "50px" : "20px")};
  background-position: ${({ hasIcon }) =>
    hasIcon ? "10px center" : "2px center"};
  background-repeat: no-repeat;
  background-size: ${({ hasIcon }) => (hasIcon ? "20px" : "auto")};
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: ${(props) => props.theme.color_theme.inputFillColor};
  color: ${(props) => props.theme.color_theme.color};
  &:focus {
    border-color: ${(props) => props.theme.color_theme.primaryColor}; /* Change to your desired focus color */
    outline: none;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
  opacity: 0.6;
`;

export const RightIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 18px;
  right: 0px;
  fill: ${(props) => props.theme.color_theme.color};
`;
export const RightUploadButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 18px;
  right: 0px;
  fill: ${(props) => props.theme.color_theme.color};
`;

export const Label = styled.label`
  // margin-bottom: 5px;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: 0.5;
`;

export const FileLabel = styled.label`
  position: absolute;
  display: flex;
  right: 0px;
  padding: 10px;
`;

export const FileBrowseButton = styled.button`
  padding: 3px 14px;
  border-radius: 50px;
  cursor: pointer;
`;

export const ErrorWrapper = styled.div`
  color: #a71414;
  position: absolute;
  padding: 0px 10px;
  bottom: ${({ messageLength }) =>
    Number(messageLength) > 55 ? "-32px" : "-16px"};
  font-size: ${(props) => props.theme.font_delta + 10}px;
  font-weight: 500;
`;

export const TextAreaWrapper = styled.textarea`
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  padding-left: ${({ hasIcon }) => (hasIcon ? "50px" : "20px")};
  background-position: ${({ hasIcon }) =>
    hasIcon ? "10px center" : "2px center"};
  background-repeat: no-repeat;
  background-size: ${({ hasIcon }) => (hasIcon ? "20px" : "auto")};
  border-radius: 18px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: #fbfbfb;
  &:focus {
    border-color: black; /* Change to your desired focus color */
    outline: none;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 42px;
  position: relative; /* Make the container a positioning context */
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0px;
  padding-right: 15px;
`;

export const ExtraTextWrapper = styled.span`
  margin-left: 6px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  margin-right: 10px;
`;

export const RightIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  fill: ${(props) => props.theme.color_theme.color};
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const MicIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  ${({ isListening }) =>
    isListening &&
    css`
      animation: ${pulseAnimation} 1.5s infinite;
    `}

  path {
    fill: ${({ isListening, theme }) =>
    isListening ? "red" : theme.color_theme.color};
  }
`;

export const DropDownWrapper = styled.div`
  position: relative;
`;

export const OptionMenu = styled.div`
  transform-origin: top bottom;
  position: absolute;
  right: 3px;
  top: 52px;
  background-color: ${(props) => props.theme.color_theme.bodyColor};
  margin-top: -3.8rem;
  padding: 14px 0px;
  margin-right: 0.5rem;
  width: 92%;
  max-height: 16rem;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  z-index: 40;
  &:hover {
    outline: none;
  }
`;

export const OptionRow = styled.div`
  display: flex;
  justify-content: space - between;
  align-items: center;

  background-color: ${(props) => props.theme.color_theme.bodyColor};
  height: 34px;
  padding: 0px 10px;

  &:hover {
    background-color: #2e9866;
  }
`;

export const OptionText = styled.label`
  display: block;
  color: ${(props) => props.theme.color_theme.color};
  font-weight: 400;
  font-size: ${(props) => props.theme.font_delta + 13} px;
  font-family: Segoe UI;
`;
