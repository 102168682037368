import React, { useEffect, useState } from "react";
import {
  TableContainer,
  UpdatedText,
} from "./ShowClientInformationModal.styled";
import { connect } from "react-redux";
import {
  EntitlementDetailWrapper,
  HeaderWrapper,
  HeadRight,
  HeadTitle,
  MemberDetailWrapper,
  Title,
  AddClientModalWrapper,
} from "../AddClientModal/AddClientModal.styled";
import Button from "../../components/Button/Button";
import speechFormat from "../../helpers/speechFormat";
import { ExtraWrapper } from "../ShowAuditSourceModal/ShowAuditSourceModal.styled";
import InputField from "../../components/InputField/InputField";
import CompanyAddressIcon from "../../assets/svgs/CompanyAddressIcon";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import BusinessIcon from "../../assets/svgs/BusinessIcon";
import Emailcon from "../../assets/svgs/Emailcon";
import BuildIcon from "../../assets/svgs/BuildIcon";
import EntitlementIcon from "../../assets/svgs/EntitlementIcon";
import UserIcon from "../../assets/svgs/UserIcon";
import useKeyPress from "../../hooks/useKeyPress";
import {
  DataCell,
  HeadingCell,
  TableDataRow,
  TableHeading,
  TableRows,
  TableWrapper,
} from "../MembersSideDrawer/MembersSideDrawer.styled";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";

const ShowClientInformationModal = ({
  onClose,
  clientInfo,
  previousClientInfo,
  overlayName,
}) => {
  useKeyPress("Escape", [], onClose, true, overlayName, true);

  const entitlementTypeAdded = clientInfo.entitlement_type || [];
  const entitlementTypeRemoved =
    previousClientInfo.entitlement_type?.removed || [];

  const combinedEntitlementTypes = Array.from(
    new Set([...entitlementTypeAdded, ...entitlementTypeRemoved])
  );

  return (
    <AddClientModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"Company Information"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Company Creation Dialog",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {clientInfo.pharma_name && (
          <>
            <ExtraWrapper>
              <Title>Company Name</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.pharma_name}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={CompanyBadgeIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.pharma_name && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.pharma_name}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.address && (
          <>
            <ExtraWrapper>
              <Title>Address</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.address}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={CompanyAddressIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.address && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.address}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.business_contact_name && (
          <>
            <ExtraWrapper>
              <Title>Business Contact Name</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.business_contact_name}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={BusinessIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.business_contact_name && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.business_contact_name}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.business_contact_email && (
          <>
            <ExtraWrapper>
              <Title>Business Contact Email</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.business_contact_email}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={Emailcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.business_contact_email && (
              <UpdatedText>
                Updated | Previously:{" "}
                {previousClientInfo.business_contact_email}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.technical_contact_name && (
          <>
            <ExtraWrapper>
              <Title>Technical Contact Name</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.technical_contact_name}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={BuildIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.technical_contact_name && (
              <UpdatedText>
                Updated | Previously:{" "}
                {previousClientInfo.technical_contact_name}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.technical_contact_email && (
          <>
            <ExtraWrapper>
              <Title>Technical Contact Email</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.technical_contact_email}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={Emailcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.technical_contact_email && (
              <UpdatedText>
                Updated | Previously:{" "}
                {previousClientInfo.technical_contact_email}
              </UpdatedText>
            )}
          </>
        )}

        {(clientInfo.custom_domain === false ||
          clientInfo.custom_domain === true) && (
          <>
            <ExtraWrapper>
              <Title>Custom Domain</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.custom_domain}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.hasOwnProperty("custom_domain") && (
              <UpdatedText>
                Updated | Previously:{" "}
                {previousClientInfo.custom_domain.toString()}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.custom_domain && (
          <>
            <ExtraWrapper>
              <Title>Custom domain</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.custom_domain}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.custom_domain && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.custom_domain}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.tenant && (
          <>
            <ExtraWrapper>
              <Title>Tenant</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.tenant}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.tenant && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.tenant}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.sharepoint_client_id && (
          <>
            <ExtraWrapper>
              <Title>Sharepoint client ID</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.sharepoint_client_id}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.sharepoint_client_id && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.sharepoint_client_id}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.sharepoint_client_secret && (
          <>
            <ExtraWrapper>
              <Title>Sharepoint client Secret</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.sharepoint_client_secret}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.sharepoint_client_secret && (
              <UpdatedText>
                Updated | Previously:{" "}
                {previousClientInfo.sharepoint_client_secret}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.sharepoint_client_url && (
          <>
            <ExtraWrapper>
              <Title>Sharepoint client URL</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.sharepoint_client_url}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.sharepoint_client_url && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.sharepoint_client_url}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.tenant_id && (
          <>
            <ExtraWrapper>
              <Title>Tenant ID</Title>
              <InputField
                width={"584"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                value={clientInfo.tenant_id}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={EntitlementIcon()}
              />
            </ExtraWrapper>
            {previousClientInfo.tenant_id && (
              <UpdatedText>
                Updated | Previously: {previousClientInfo.tenant_id}
              </UpdatedText>
            )}
          </>
        )}

        {clientInfo.users && (
          <ExtraWrapper>
            <Title>Users</Title>
            <TableWrapper>
              <TableHeading>
                <HeadingCell width={"10%"}>S.No.</HeadingCell>
                <HeadingCell width={"30%"}>Member's Name</HeadingCell>
                <HeadingCell width={"10%"}>Role</HeadingCell>
                <HeadingCell width={"30%"}>Email</HeadingCell>
                <HeadingCell width={"20%"}>Status</HeadingCell>
              </TableHeading>
              <TableRows>
                {clientInfo.users?.length > 0 &&
                  clientInfo.users?.map((user, index) => {
                    let status = "No changes";

                    if (
                      clientInfo.added_users?.some(
                        (addedUser) => addedUser.email === user.email
                      )
                    ) {
                      status = "Added";
                    } else if (
                      clientInfo.deleted_users?.some(
                        (deletedUser) => deletedUser.email === user.email
                      )
                    ) {
                      status = "Deleted";
                    }

                    return (
                      <TableDataRow key={index} index={index}>
                        <DataCell width={"10%"}>{index + 1}.</DataCell>
                        <DataCell width={"30%"}>{user.name}</DataCell>
                        <DataCell
                          style={{ textTransform: "capitalize" }}
                          width={"10%"}
                        >
                          {user.role}
                        </DataCell>
                        <DataCell width={"30%"}>{user.email}</DataCell>
                        <DataCell width={"20%"}>{status}</DataCell>
                      </TableDataRow>
                    );
                  })}
              </TableRows>
            </TableWrapper>
          </ExtraWrapper>
        )}

        {previousClientInfo.entitlement_type && (
          <ExtraWrapper>
            <Title>ENTITLEMENT TYPES</Title>
            <TableContainer>
              <TableWrapper>
                <TableHeading>
                  <HeadingCell width={"20%"}>S.No.</HeadingCell>
                  <HeadingCell width={"50%"}>Entitlement</HeadingCell>
                  <HeadingCell width={"30%"}>Status</HeadingCell>
                </TableHeading>
                <TableRows>
                  {combinedEntitlementTypes.length > 0 &&
                    combinedEntitlementTypes.map((user, index) => {
                      const added = previousClientInfo.entitlement_type.added;
                      const removed =
                        previousClientInfo.entitlement_type.removed;
                      let status = "No updates";

                      if (added.includes(user)) {
                        status = "Added";
                      } else if (removed.includes(user)) {
                        status = "Removed";
                      }

                      return (
                        <TableDataRow key={index} index={index}>
                          <DataCell width={"20%"}>{index + 1}.</DataCell>
                          <DataCell
                            style={{ textTransform: "capitalize" }}
                            width={"50%"}
                          >
                            {user}
                          </DataCell>
                          <DataCell width={"30%"}>{status}</DataCell>
                        </TableDataRow>
                      );
                    })}
                </TableRows>
              </TableWrapper>
            </TableContainer>
          </ExtraWrapper>
        )}
      </div>
    </AddClientModalWrapper>
  );
};

export default ShowClientInformationModal;
