import React from "react";
import {
  IconWrapper,
  InfoFieldWrapper,
  Label,
  StyledTextWrapper,
  RightIconWrapper,
} from "./InfoTextarea.styled";
import { connect } from "react-redux";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";

const InfoTextarea = ({
  label,
  icon,
  labelStyle,
  wrapperStyle,
  inputStyle,
  iconStyle,
  width,
  type,
  value,
  rightIcon,
  is_contrast,
  value1,
  loading,
  ...props
}) => {
  return (
    <InfoFieldWrapper
      style={{
        justifyContent: loading || value?.length > 0 ? "normal" : "center",
        width: width ? `${width}px` : "340px",
        ...wrapperStyle,
      }}
    >
      {label && (
        <Label
          style={{
            pointerEvents: "none",
            fontSize: loading || value?.length > 0 ? "10px" : "14px",
            ...labelStyle,
          }}
        >
          {label}
        </Label>
      )}
      <StyledTextWrapper style={inputStyle} hasIcon={!!icon}>
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>
            {value}
            <br />
            {value1}
          </>
        )}
      </StyledTextWrapper>
      {icon && <IconWrapper style={{ ...iconStyle }}>{icon}</IconWrapper>}

      {rightIcon && (
        <RightIconWrapper style={{ pointerEvents: "none", ...iconStyle }}>
          {rightIcon}
        </RightIconWrapper>
      )}
    </InfoFieldWrapper>
  );
};

InfoTextarea.propTypes = {};

InfoTextarea.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(InfoTextarea);
