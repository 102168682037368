import styled from "styled-components";

export const TableDataRow = styled.div`
  display: flex;
  padding: 6px 12px;
  height: 48px;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  width: calc(100% - 24px);
`;

export const TableRowWrapper = styled.div`
  background: ${({ theme, index, isSelected }) =>
    isSelected
      ? theme.color_theme.selectedBackgroundColor
      : index % 2 === 0
        ? theme.color_theme.tableStripColorLight
        : theme.color_theme.tableStripColorDark};
  width: 100%;
  display: flex;
  border-bottom-right-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  border-bottom-left-radius: ${({ islast }) => (islast ? "8px" : "0px")};
  color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : ""};
`;

export const DataCell = styled.div`
  min-width: ${({ width }) => `calc(${width} - 12px);`};
  max-width: ${({ width }) => `${width}`};
  display: ${({ theme, thres }) =>
    thres && theme.window_width < thres ? "none" : "flex"};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DataCellName = styled.div`
  display: "flex";
  flex-direction: "column";
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const DataCellNameItem = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DataCellAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  svg {
    fill: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.color};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 12}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    margin-left: 6px;
  }

  img {
    color: ${(props) => props.theme.color_theme.color};
  }
`;

export const TextWapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.color_theme.color};
  margin-right: 10px;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export const MemberNumberWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // text-decoration: underline;
  color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};

  &:hover {
    text-decoration: underline;
  }
`;
