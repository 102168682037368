import styled from "styled-components";

export const InfoFieldWrapper = styled.div `
  display: flex;
  flex-direction: column;
  position: relative; /* Make the container a positioning context */
`;

export const StyledTextWrapper = styled.div `
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  padding-left: ${({ hasIcon }) => (hasIcon ? "50px" : "20px")};
  background-position: ${({ hasIcon }) =>
    hasIcon ? "10px center" : "2px center"};
  background-repeat: no-repeat;
  background-size: ${({ hasIcon }) => (hasIcon ? "20px" : "auto")};
  border-radius: 18px;
  border:  ${(props) => `1px solid ${props.theme.color_theme.tableColor}`};
  background: ${(props) => props.theme.color_theme.bodyColor};
  color: ${(props) => props.theme.color_theme.color};
  height: 22px;
  &:focus {
    border-color: black; /* Change to your desired focus color */
    outline: none;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
  opacity: 0.5;
`;

export const RightIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 18px;
  right: 0px
  
`;


export const Label = styled.label`
  margin-bottom: 5px;
  font-size: ${(props) => props.theme.font_delta + 14}px;
  color : ${(props) => props.theme.color_theme.color};
  opacity: 0.5;
`;