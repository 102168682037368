import React from "react";

const MicrosoftIcon = () => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.869141 1.36987H9.56479V10.0655H0.869141V1.36987Z"
        fill="#F35325"
      />
      <path
        d="M10.4355 1.36987H19.1312V10.0655H10.4355V1.36987Z"
        fill="#81BC06"
      />
      <path
        d="M0.869141 10.9351H9.56479V19.6307H0.869141V10.9351Z"
        fill="#05A6F0"
      />
      <path
        d="M10.4355 10.9351H19.1312V19.6307H10.4355V10.9351Z"
        fill="#FFBA08"
      />
    </svg>
  );
};

export default MicrosoftIcon;
