import styled from "styled-components";

export const StyledReactSearchAutocomplete = styled.div`
    height: 54px;
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
  `


export const StyledSearchInput = styled.input`
    padding: 9px 20px;
    height: 54px;
    font-size: ${(props) => props.theme.font_delta + 13}px;
    font-style: normal;
    font-weight: 400;
    padding-left: 50px;
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 18px;
    border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
    background: ${(props) => props.theme.color_theme.inputFillColor};
    color: ${(props) => props.theme.color_theme.color};
    &:focus {
      border-color: ${(props) => props.theme.color_theme.primaryColor};
      outline: none;
    }
}
`


export const StyledResults = styled.div`
    position: absolute;
    font-size: ${(props) => props.theme.font_delta + 13}px;
    font-style: normal;
    font-weight: 400;
    z-index: 3;
    width: 96%;
    border-radius: 4px;
    margin: 45px 10px 10px 10px;
    border:  ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
    background: ${(props) => props.theme.color_theme.bodyColor};
    color: ${(props) => props.theme.color_theme.color};
  
    > ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 0 16px 0;
  
      > li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 0px 4px 4px;
  
        > div {
          margin-left: 13px;
          > span {
            font-size: ${(props) => props.theme.font_delta + 13}px;
            font-style: normal;
            font-weight: 400;
            text-transfrom: capitalize;
          }
        }
        &:hover {
          background-color: ${(props) => props.theme.color_theme.selectedBackgroundColor};
        }
      }

      > span {
        cursor: pointer;
        
      }
    }
  
    .ellipsis {
      text-align: left;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .selected {
      background-color: ${(props) => props.theme.color_theme.selectedBackgroundColor};
    }
  `

// export const IconWrapper = styled.div`
//   margin-left: 12px;
//   margin-top: 3px;
//   fill: ${(props) => props.theme.color_theme.color};
// `

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: inherit;
  padding-left: 20px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
`;

export const RightIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  height: inherit;
  right: 0px;
  padding-right: 6px;
  fill: ${(props) => props.theme.color_theme.color};
`;


export const ExtraTextWrapper = styled.span`
  margin-left: 6px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
  font-family: Segoe UI;
  margin-right: 10px;
`;