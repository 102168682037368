import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useKeyPress = (
  targetKey,
  modifierKeys = [],
  callback,
  isOverlay = false,
  overlayName = "",
  override = false
) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const overlay = useSelector((state) => state?.a11y?.overlay);
  const storeOverlayName = useSelector((state) => state?.a11y?.overlay_name);
  const allModifierKeys = ["altKey", "ctrlKey", "shiftKey"];

  const handleKeyDown = (event) => {
    if (
      event.key.toLowerCase() === targetKey.toLowerCase() &&
      ((!modifierKeys.length && allModifierKeys.every((key) => !event[key])) ||
        (modifierKeys.length && modifierKeys.every((key) => event[key]) && 
        allModifierKeys.filter(e => !modifierKeys.includes(e)).every((key) => !event[key]))) &&
      (event.target.tagName !== "INPUT" ||
        (modifierKeys.length && modifierKeys.every((key) => event[key]) && 
        allModifierKeys.filter(e => !modifierKeys.includes(e)).every((key) => !event[key])) ||
        override)
    ) {
      setKeyPressed(true);
      if (
        ((overlay && isOverlay && storeOverlayName === overlayName) ||
          (!overlay && !isOverlay)) &&
        callback
      ) {
        event.preventDefault();
        callback();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (
      event.key.toLowerCase() === targetKey.toLowerCase() &&
      ((!modifierKeys.length && allModifierKeys.every((key) => !event[key])) ||
        (modifierKeys.length && modifierKeys.every((key) => event[key]) &&
        allModifierKeys.filter(e => !modifierKeys.includes(e)).every((key) => !event[key])
        )) &&
      (event.target.tagName !== "INPUT" ||
        (modifierKeys.length && modifierKeys.every((key) => event[key]) &&
        allModifierKeys.filter(e => !modifierKeys.includes(e)).every((key) => !event[key])
        ) ||
        override)
    ) {
      setKeyPressed(false);
      if (
        ((overlay && isOverlay && storeOverlayName === overlayName) ||
          (!overlay && !isOverlay)) &&
        callback
      ) {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (!targetKey || !modifierKeys) {
      return;
    }
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [targetKey, modifierKeys]);

  return keyPressed;
};

export default useKeyPress;
