import React, { useState } from "react";
import {
  TableDataRow,
  DataCell,
  TableRowWrapper,
  DataCellAction,
  MemberNumberWrapper,
  DataCellName,
  DataCellNameItem,
} from "./TableRow.styled";
import MembersSideDrawer from "../../modules/MembersSideDrawer/MembersSideDrawer";
// import SharepointIcon from "../../assets/sharepoint.png";
import useKeyPress from "../../hooks/useKeyPress";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import UpdateClientModal from "../../modules/UpdateClientModal/UpdateClientModal";
import SuccessPopup from "../SuccesPopup/SuccessPopup";
import SharePointIcon from "../../assets/svgs/SharePointIcon";
import EditClientModal from "../../modules/EditClientModal/EditClientModal";
import UpdateEntitlementsModal from "../../modules/UpdateEntitlementsModal/UpdateEntitlementsModal";
import speechFormat from "../../helpers/speechFormat";

const TableRow = ({ row, index, onSelectRow, isSelected, islast }) => {
  const [membersSideDrawerState, setMembersSideDrawerState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditmodalOpen] = useState(false);
  const [entitlementmodalOpen, setEntitlementmodalOpen] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [loadingSuccessPopup, setLoadingSuccessPopup] = useState(false);

  const handleMembersSideDrawerState = (value) => {
    if (value && value?.edit) {
      setEditmodalOpen(true);
    }
    setMembersSideDrawerState(false);
  };

  const handleRowClick = () => {
    onSelectRow(index);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeEditModal = () => {
    setEditmodalOpen(false);
  };

  const closeEntitlementModal = () => {
    setEntitlementmodalOpen(false);
  };

  const handleSuccess = ({ loading }) => {
    setShowSuccessPopup(true);
    setLoadingSuccessPopup(loading);
    closeModal();
  };

  const handleEditSuccessModal = ({ }) => {
    closeEditModal();
  };

  const overlayName = "UpdateClientModal";
  const editoverlayName = "EditClientModal";
  const entitlementEditoverlayName ="entitlementEditModal"
  useKeyPress("1", ["altKey"], () => onSelectRow(0));
  useKeyPress("2", ["altKey"], () => onSelectRow(1));
  useKeyPress("3", ["altKey"], () => onSelectRow(2));
  useKeyPress("4", ["altKey"], () => onSelectRow(3));
  useKeyPress("5", ["altKey"], () => onSelectRow(4));
  useKeyPress("6", ["altKey"], () => onSelectRow(5));
  useKeyPress("7", ["altKey"], () => onSelectRow(6));
  useKeyPress("8", ["altKey"], () => onSelectRow(7));
  useKeyPress("9", ["altKey"], () => onSelectRow(8));
  useKeyPress("1", [], () => isSelected && setMembersSideDrawerState(true));
  useKeyPress("2", [], () => isSelected && setModalOpen(true));
  useKeyPress("3", [], () => isSelected && setEntitlementmodalOpen(true));
  useKeyPress(
    "4",
    [],
    () => isSelected && window.open(row.url.sharepoint, "_blank")
  );

  return (
    <>
      <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
        <TableDataRow
          key={index}
          onClick={handleRowClick}
          isSelected={isSelected}
        >
          <DataCell isSelected={isSelected} width={"10%"} style={{ justifyContent: "normal" }}>
            <div style={{ marginRight: "4px" }} 
             data-speech={speechFormat({
              key: `Alt + ${index + 1}`,
              action:"select",
              item: `company with the id ${index+1}`,
            })}
            aria-label="company id" tabIndex={0}>{index + 1}.</div>
            <span> [Alt + {index + 1}]</span>
          </DataCell>
          <DataCell
            width={"20%"}
            onClick={() => setMembersSideDrawerState(true)}
            style={{ cursor: "pointer" }}
          >
            <DataCellAction isSelected={isSelected} >
              <span style={{ opacity: "0.8" }}>[1]</span>
            </DataCellAction>
            <MemberNumberWrapper isSelected={isSelected} tabIndex={0}>{row.pharmaName}</MemberNumberWrapper>
          </DataCell>
          <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
            <MemberNumberWrapper isSelected={isSelected} onClick={() => setModalOpen(true)} tabIndex={0} aria-label="Number of members">
              {row?.members || 0}
            </MemberNumberWrapper>
            <DataCellAction isSelected={isSelected} >
              <span style={{ opacity: "0.8" }}>[2]</span>
            </DataCellAction>
          </DataCell>
          <DataCell
            width={"20%"}
            style={{ justifyContent: "normal" }}
            thres={1300}
          >
            <DataCellName>
              <DataCellNameItem style={{ marginRight: "4px" }} tabIndex={0} aria-label="Business contact name">
                {row.contact_details.business_contact_name || "Name not provided"}
              </DataCellNameItem>
              <DataCellNameItem style={{ marginRight: "4px", opacity: 0.75 }} tabIndex={0} aria-label="Business contact email">
                {row.contact_details.business_contact_email || "Email not provided" }
              </DataCellNameItem>
            </DataCellName>
          </DataCell>
          <DataCell
            width={"20%"}
            style={{ justifyContent: "normal" }}
            thres={1600}
          >
            <DataCellName>
              <DataCellNameItem style={{ marginRight: "4px" }} tabIndex={0} aria-label="Technical contact name">
                {row.contact_details.technical_contact_name}
              </DataCellNameItem>
              <DataCellNameItem style={{ marginRight: "4px", opacity: 0.75 }} tabIndex={0} aria-label="Technical contact email">
                {row.contact_details.technical_contact_email}
              </DataCellNameItem>
            </DataCellName>
          </DataCell>
          <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
            <MemberNumberWrapper isSelected={isSelected} onClick={() => setEntitlementmodalOpen(true)} tabIndex={0} aria-label="Entitlement types">
              {row?.contact_details?.entitlement_type.length || 0}
            </MemberNumberWrapper>
            <DataCellAction isSelected={isSelected} >
              <span style={{ opacity: "0.8" }}>[3]</span>
            </DataCellAction>
          </DataCell>
          <DataCell width={"10%"}>
            <DataCellAction isSelected={isSelected} onClick={() => window.open(row.url.sharepoint, "_blank")}>
              <SharePointIcon />
              <span
                style={{ opacity: "0.8" }}
              >
                [4]
              </span>
            </DataCellAction>
          </DataCell>
        </TableDataRow>
      </TableRowWrapper>
      {membersSideDrawerState && <MembersSideDrawer
        isOpen={membersSideDrawerState}
        handleOpen={handleMembersSideDrawerState}
        pharma_name={row.pharmaName}
      />}

      {modalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <UpdateClientModal
            onClose={closeModal}
            onSuccess={handleSuccess}
            pharma_name={row.pharmaName}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}

      {
        entitlementmodalOpen && (
          <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={entitlementEditoverlayName}
          >
            <UpdateEntitlementsModal
              onClose={closeEntitlementModal}
              onSuccess={closeEntitlementModal}
              pharma_name={row.pharmaName}
              overlayName={entitlementEditoverlayName}
            />
          </ModalWrapper>
        )
      }

      {editmodalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={editoverlayName}
        >
          <EditClientModal
            onClose={closeEditModal}
            onSuccess={handleEditSuccessModal}
            overlayName={editoverlayName}
            pharma_name={row.pharmaName}
          />
        </ModalWrapper>
      )}

      {showSuccessPopup && (
        <SuccessPopup
          msg_processing={"Updating company details, please wait..."}
          msg_done={"Company is updated successfully."}
          onClose={() => setShowSuccessPopup(false)}
          loading={loadingSuccessPopup}
        />
      )}
    </>
  );
};

export default TableRow;
