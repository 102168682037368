import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 448px;
`;

export const Title = styled.div`
  ${Fonts.Body1};
  text-align: center;
  margin-right: 6px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 44px 0px;
  align-items: center;
`;

export const DocTitle = styled.div`
  ${Fonts.Subtitle2};
  text-align: center;
  margin-top: 18px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 220px;
  margin-top: 6px;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CardContainer = styled.div`
  width: 287px;
`;


export const DotWrapper = styled.div`
  width: 3px;
  height: 3px;
  background-color: #C2C2C2;
  border-radius: 50%;
`
