import React from "react";
import folderIcon from "../Docfolder.png"
const FolderDocIcon = () => {
  return (
    <>
    <img src={folderIcon} style={{height: "30px"}} alt="folder"/>
    </>
  );
};

export default FolderDocIcon;
