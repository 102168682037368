import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LayoutMain, LayoutWrapper } from "./Layout.styled";
import { useMsal } from "@azure/msal-react";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/slices/UserSlice";
import { connect } from "react-redux";
import IndeterminateLoader from "../../components/IndeterminateLoader/IndeterminateLoader";
import { ThemeProvider } from "styled-components";
import { setOverlay } from "../../store/slices/A11ySlice";
import { GlobalStyles } from "../../Theme/globalStyled";
import { useLocation } from "react-router-dom";

const Layout = ({
  children,
  token,
  noToken = false,
  font_delta = 0,
  high_contrast,
  dark_mode,
}) => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(setOverlay(false));
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const getToken = async () => {
      try {
        await instance.initialize();
        const response = await instance.acquireTokenSilent({
          scopes: ["https://graph.microsoft.com/User.Read"],
          account: account,
        });

        dispatch(setToken(response.accessToken));
      } catch (error) {
        console.log("Error acquiring token:", error);
      }
    };

    if (account) getToken();
  }, [instance, account]);

  /* Normal Contrast Light Mode */
  const NC_LM_THEME = {
    selectedTheme: "NC_LM_THEME",
    bodyColor: "#FFFFFF",
    color: "#000",
    borderColor: "#f2f2f2",
    primaryColor: "#2E9866",
    accentColor: "#A5E5C2",
    selectedBackgroundColor: "#DEEBE9",
    shortcutLabelOpacity: "0.6",
    labelOpacity: "0.6",
    toggleSwitchDisableColor: "#9E9E9E",
    inputFillColor: "#FBFBFB",
    inputBorderColor: "#e8e8e8",
    tableColor: "#F3F3F3",
    tableStripColorLight: "#ffffff",
    tableStripColorDark: "#FBFBFB",
    infoBorderColor: "#A5E5C2",
    toolTipBorder: "#806400",
    toolTipBackgoundColor: "#FFD749"
  };

  /* Normal Contrast Dark Mode */
  const NC_DM_THEME = {
    selectedTheme: "NC_DM_THEME",
    bodyColor: "#1D1D1D",
    color: " #FFFFFF",
    borderColor: "#2D2D2D",
    primaryColor: "#2E9866",
    accentColor: "#A5E5C2",
    selectedBackgroundColor: "#A5E5C233",
    labelOpacity: "0.4",
    shortcutLabelOpacity: "0.6",
    toggleSwitchDisableColor: "#5E5E5E",
    inputFillColor: "#131313",
    inputBorderColor: "#424242",
    tableColor: "#2C2C2D",
    tableStripColorLight: "#1E1E1E",
    tableStripColorDark: "#131313",
    infoBorderColor: "#A5E5C2",
    toolTipBorder: "#E6BF37",
    toolTipBackgoundColor: "#B29220"
  };

  /* Dark Contrast Light Mode */
  const DC_LM_THEME = {
    selectedTheme: "DC_LM_THEME",
    bodyColor: "#FFFFFF",
    color: "#000",
    borderColor: "#B7B7B7",
    primaryColor: "#065A32",
    accentColor: "#A5E5C2",
    selectedBackgroundColor: "rgb(145, 218, 178, 0.4)",
    labelOpacity: "0.8",
    shortcutLabelOpacity: "0.8",
    toggleSwitchDisableColor: "#727272",
    inputFillColor: "#FBFBFB",
    inputBorderColor: "#B7B7B7",
    tableColor: "#F3F3F3",
    tableStripColorLight: "#ffffff",
    tableStripColorDark: "#FBFBFB",
    infoBorderColor: "#B7B7B7",
    toolTipBorder: "#806400",
    toolTipBackgoundColor: "#FFD749"
  };

  /* Dark Contrast Dark Mode */
  const DC_DM_THEME = {
    selectedTheme: "DC_DM_THEME",
    bodyColor: "#000000",
    color: " #FFFFFF",
    borderColor: "#B7B7B7",
    primaryColor: "#49FFA9",
    accentColor: "#A5E5C2",
    selectedBackgroundColor: "#E9FFF3",
    labelOpacity: "0.6",
    shortcutLabelOpacity: "0.7",
    toggleSwitchDisableColor: "#777777",
    inputFillColor: "#2B2B2B",
    inputBorderColor: "#5F5F5F",
    tableColor: "#161616",
    tableStripColorLight: "#000",
    tableStripColorDark: "#161616",
    infoBorderColor: "#A5E5C2",
    toolTipBorder: "#665B34",
    toolTipBackgoundColor: "#413300"
  };

  const [selectedTheme, setSelectedTheme] = useState(NC_LM_THEME);
  useEffect(() => {
    const getColorTheme = () => {
      if (!high_contrast && !dark_mode) {
        return NC_LM_THEME;
      }

      if (high_contrast && !dark_mode) {
        return DC_LM_THEME;
      }

      if (!high_contrast && dark_mode) {
        return NC_DM_THEME;
      }

      if (high_contrast && dark_mode) {
        return DC_DM_THEME;
      }
    };

    setSelectedTheme(getColorTheme);
  }, [high_contrast, dark_mode]);

  const theme = {
    font_delta: font_delta ? font_delta : 0,
    color_theme: selectedTheme,
    window_width: windowWidth,
  };

  const location = useLocation();

  const [selectedButton, setSelectedButton] = useState("")

  useEffect(() => {
    const pathname = window.location.pathname;
    const pathSegments = pathname.split('/');
    setSelectedButton(pathSegments[1])
    
  }, [location])



  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LayoutWrapper>
        {token || noToken ? (
          <>
            <Header selectedButton={selectedButton} />
            <LayoutMain>{children}</LayoutMain>
          </>
        ) : (
          <IndeterminateLoader size={64} />
        )}
      </LayoutWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    token: state?.user?.token,
    font_delta: state?.a11y?.font_delta,
    high_contrast: state?.a11y.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
  };
};

export default connect(mapStateToProps)(Layout);
