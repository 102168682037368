import React, { useEffect } from "react";
import {
  SuccessPopupWrapper,
  PopupContent,
  OverlayWrapper,
} from "./SuccessPopup.styled";
import Button from "../Button/Button";
import SuccessIcon from "../../assets/success-1.png";
import DarkSuccessIcon from "../../assets/success-dark.png";
import FailedIcon from "../../assets/Failed-1.png";
import DarkFailedIcon from "../../assets/Failed-dark.png";
import { connect } from "react-redux";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import useKeyPress from "../../hooks/useKeyPress";
import speechFormat from "../../helpers/speechFormat";
import ProgressBar from "../ProgressBar/ProgressBar";

const SuccessPopup = ({
  onClose,
  is_contrast,
  loading,
  msg_processing,
  msg_done,
  progress,
  type = "success",
  styled =false,
  docUploadErrorMsg,
  msg_failed,
  setDocUploadErrorMessage
}) => {
  useKeyPress("Escape", [], onClose);

  return (
    <OverlayWrapper>
      {
        <SuccessPopupWrapper>
          <PopupContent>
            {loading ? (
                <>
                  <div>{msg_processing}</div>
                  {/* <div style={{ width: "120%", marginBottom: 10 }}>
                    <SkeletonLoader
                      height={10}
                      baseColor="#2E9866"
                      highlightColor="#065A32"
                    />
                  </div> */}
                  {!styled && progress && <ProgressBar progress={progress} />}
                </>
              
            ) : (
              !docUploadErrorMsg && (
              <>
                {type === "success" ? (
                  <img
                    height={100}
                    src={is_contrast ? DarkSuccessIcon : SuccessIcon}
                    alt="sharepoint"
                  />
                ) : (
                  <img
                    height={100}
                    src={is_contrast ? DarkFailedIcon : FailedIcon}
                    alt="sharepoint"
                  />
                )}
                <div>{msg_done}</div>
              </>
              )
            )}
            {docUploadErrorMsg && (
              <>
                <img
                  height={100}
                  src={is_contrast ? DarkFailedIcon : FailedIcon}
                  alt="sharepoint"
                />
                <div>{msg_failed}</div>
              </>
            )}
          </PopupContent>

          {loading || (
            <Button
              type="ghost"
              text={type === "success" ? "Done" : "Close"}
              accessKeyText="[ ESC ]"
              onClick={onClose}
              data-speech={speechFormat({
                key: "Escape",
                action: "close",
                item: "Dialog",
              })}
            />
          )}
        </SuccessPopupWrapper>
      }
    </OverlayWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(SuccessPopup);
