import React, { useEffect, useState } from "react";
import { SignInWrapper, HeadingWrapper, TextWrapper, SignInTextWrapper, TitleWrapper, Tooltip, TooltipText, LogoWrapper } from "./SSOLogin.styled";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Button from "../../components/Button/Button";
import MicrosoftIcon from "../../assets/svgs/MicrosoftIcon";
import useKeyPress from "../../hooks/useKeyPress";
import { connect, useDispatch } from "react-redux";
import Logo from "../../components/Logo/Logo";
import { setLoginToolTip } from "../../store/slices/A11ySlice";

const SSOLogin = ({ is_contrast, dark_mode, loginToolTip, optionToolTip }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn]);

  const handleSignInSSO = async () => {
    instance
      .loginPopup({ scopes: ["https://graph.microsoft.com/User.Read"] })
      .then(() => navigate("/"))
      .catch(console.log);
  };

  const hideLoginToolTip = () => {
    dispatch(setLoginToolTip(false))
  }

  useKeyPress("s", [], handleSignInSSO);
  useKeyPress("Q", [], hideLoginToolTip)

  return (
    <SignInWrapper>
      <LogoWrapper>
        <Logo width={"auto"} />
      </LogoWrapper>
      <HeadingWrapper dark_mode={dark_mode} is_contrast={is_contrast} >
        <TitleWrapper dark_mode={dark_mode} is_contrast={is_contrast}>
          Hello,
        </TitleWrapper>
        Welcome to the Admin Portal,
        <br />
        <TextWrapper dark_mode={dark_mode} is_contrast={is_contrast} >

          Please <SignInTextWrapper is_contrast={is_contrast}>Sign In</SignInTextWrapper>
        </TextWrapper>
      </HeadingWrapper>
      <Tooltip>
        <Button
          type="secondary"
          text="Sign In with Microsoft"
          accessKeyText="[ S ]"
          buttonStyle={{ width: "260px", height: "30px", marginTop: "20px", padding: "20px" }}
          icon={<MicrosoftIcon />}
          onClick={handleSignInSSO}
        />
        {loginToolTip &&
          <TooltipText>
            Letters with [ ] are Keyboard shortcuts. Click as "Dismiss" below or press [ Q ] on your keyboard to dismiss this alert.
            <br />
            <div style={{ marginTop: "10px", cursor: "pointer" }} onClick={() => hideLoginToolTip()}>
              Dismiss <span style={{ opacity: 0.5 }}>[Q]</span>

            </div>
          </TooltipText>
        }
      </Tooltip>
    </SignInWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
    loginToolTip: state?.a11y?.loginToolTip,
    optionToolTip: state?.a11y?.optionsToolTip,
  };
};

export default connect(mapStateToProps)(SSOLogin);
