import React, { useEffect, useState } from "react";
import {
  Body,
  ButtonContainer,
  CardContainer,
  DocTitle,
  Footer,
  Head,
  Title,
  Wrapper,
  DotWrapper,
} from "./SuccessICF.styled";
import SuccessIcon from "../../assets/svgs/SuccessIcon";
import Docs from "../../assets/Docs.jpg";
import Button from "../../components/Button/Button";
import DownloadIcon from "../../assets/svgs/DownloadIcon";
import LoaderCard from "../../components/LoaderCard/LoaderCard";
import { getGeneratedDocumentDownloadUrl } from "../../apis/ApiServerRequests";
import useKeyPress from "../../hooks/useKeyPress";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import PreviewModal from "../PreviewModal/PreviewModal";
import { useLocation, useNavigate } from "react-router-dom";
import DocxIcon from "../../assets/svgs/DocxIcon";
import { useDispatch } from "react-redux";
import { incrICFCount } from "../../store/slices/UserSlice";
import extractExtensionIcon from "../../helpers/extractExtension";

const SuccessICF = ({
  docName,
  docTitle,
  handleGenerated,
  totalMapping,
  resolvedMapping,
  styled = false,
  downloadUrl = null
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sampleVal, setSampleVal] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const maxVal = resolvedMapping;

  useEffect(() => {
    const interval = setInterval(() => {
      if (sampleVal < maxVal) {
        setSampleVal((prevVal) =>
          Math.max(prevVal + parseInt(maxVal * 0.05), maxVal)
        );
      } else {
        dispatch(incrICFCount(1));
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [sampleVal, maxVal]);

  const handleFileDownload = () => {
    let file_name;
    file_name = docName ? docName : docTitle;
    if (downloadUrl) {
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = file_name;
      anchor.click();
    } else {
      getGeneratedDocumentDownloadUrl(file_name).then(({ data }) => {
        const anchor = document.createElement("a");
        anchor.href = data?.url;
        anchor.download = file_name;
        anchor.click();
      });
    }
  };

  useKeyPress("d", [], handleFileDownload);
  useKeyPress("a", [], () => handleGenerated(false));
  useKeyPress("Escape", [], () => navigate("/"));

  return (
    <Wrapper>
      <Head>
        <Title>{`Your Doc for ${docTitle} is generated successfully`}</Title>
        <SuccessIcon />
      </Head>
      <Body>
        {extractExtensionIcon(docTitle)}
        {docName ? <DocTitle>{`${docName}`}</DocTitle> : <DocTitle>{`${docTitle}`}</DocTitle>}
        <ButtonContainer>
          <Button
            type="ghost"
            accessKeyText={"[D]"}
            text={"Download"}
            buttonStyle={{ padding: "4px 0px" }}
            iconStyle={{ marginRight: "6px" }}
            icon={<DownloadIcon />}
            onClick={handleFileDownload}
          />
        </ButtonContainer>
        {docName &&
        <CardContainer>
          <LoaderCard minVal={sampleVal} maxVal={totalMapping} />
        </CardContainer>
        }
      </Body>
      <Footer>
        <Button
          type="tertiary"
          accessKeyText={"[A]"}
          text={"Generate Another"}
          onClick={() => handleGenerated(false)}
        />
        <Button
          type="ghost"
          accessKeyText={"[Esc]"}
          text={"Done"}
          onClick={() => navigate("/")}
        />
      </Footer>
      {modalOpen && (
        <ModalWrapper isOpen={modalOpen} onClose={closeModal}>
          <PreviewModal onClose={closeModal} />
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default SuccessICF;
