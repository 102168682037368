import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MembersSideDrawerWrapper,
  TopNavWrapper,
  TopNavText,
  TopNavButton,
  TopWrapper,
  TableWrapper,
  TableHeading,
  HeadingCell,
  TableRows,
  TableDataRow,
  DataCell,
  ClientDetailsWrapper,
  RowWrapper,
  BottomNavWrapper,
  HeadingWrapper,
  DetailsWrapper,
} from "./MembersSideDrawer.styled";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import Button from "../../components/Button/Button";
import { getClientInfo, getMembersList } from "../../apis/ApiServerRequests";
import useKeyPress from "../../hooks/useKeyPress";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import CompanyAddressIcon from "../../assets/svgs/CompanyAddressIcon";
import BusinessIcon from "../../assets/svgs/BusinessIcon";
import SettingIcon from "../../assets/svgs/SettingIcon";
import InfoTextarea from "../../components/InfoTextarea/InfoTextarea";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import EditIcon from "../../assets/svgs/EditIcon";
import speechFormat from "../../helpers/speechFormat";
import EntitlementIcon from "../../assets/svgs/EntitlementIcon";

const MembersSideDrawer = ({ isOpen, handleOpen, pharma_name }) => {
  const [client, setClient] = useState({ pharmaName: "", members: [] });
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [clientInfo, setClientInfo] = useState({});

  useEffect(() => {
    if (isOpen) {
      setClientInfo({});
      setClient({ pharmaName: "", members: [] });
      setDetailsLoading(true);
      setMembersLoading(true);
      getMembersList(pharma_name)
        .then(({ data }) => setClient(data))
        .finally(() => setMembersLoading(false));
      getClientInfo(pharma_name)
        .then(({ data }) => setClientInfo(data))
        .finally(() => setDetailsLoading(false));
    }
  }, [isOpen]);

  const overlayName = "MemberSideDrawer"

  const handleEdit = () => {
    handleOpen({ edit: true });
  }

  useKeyPress("Escape", [], handleOpen, true, overlayName);
  useKeyPress("E", [], handleEdit, true, overlayName);

  return (
    isOpen && (
      <SideDrawer isOpen={isOpen} handleOpen={handleOpen} overlayName={overlayName}>
        <MembersSideDrawerWrapper>
        <TopNavWrapper>
              <TopNavText>COMPANY DETAILS</TopNavText>
              <TopNavButton>
                <Button
                  text={"Done"}
                  accessKeyText={"[ESC]"}
                  type={"ghost"}
                  onClick={handleOpen}
                  buttonStyle={{ padding: "9px 0px" }}
                  data-speech={speechFormat({
                    key: "Escape",
                    action: "close",
                    item: "Drawer",
                  })}
                />
              </TopNavButton>
            </TopNavWrapper>
          <TopWrapper>
            <BottomNavWrapper onClick={() => handleEdit()}>
              Edit Company Details

              <Button
                icon={<EditIcon />}
                accessKeyText={"[ E ]"}
                type={"ghost"}
                buttonStyle={{ padding: "0px" }}
              />
            </BottomNavWrapper>

            <ClientDetailsWrapper>
              <InfoTextarea
                width={"412"}
                labelStyle={{
                  position: "absolute",
                  padding: "8px 50px",
                }}
                label={`Company Name`}
                value={pharma_name}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", paddingTop: "24px" }}
                icon={CompanyBadgeIcon()}
              />
              <InfoTextarea
                width={"412"}
                labelStyle={{
                  position: "absolute",
                  padding: "8px 50px",
                }}
                label={`Company Address`}
                loading={detailsLoading}
                value={clientInfo?.address}
                type={"text"}
                inputStyle={{
                  paddingBottom: "10px",
                  paddingTop: "24px",
                  verticalAlign: "top",
                }}
                iconStyle={{ alignItems: "initial", marginTop: "10px" }}
                icon={CompanyAddressIcon()}
              />

              <RowWrapper>
                <InfoTextarea
                  width={"198"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 50px",
                  }}
                  label={`Business Contact`}
                  loading={detailsLoading}
                  value={clientInfo?.business_contact_name}
                  value1={clientInfo?.business_contact_number}
                  type={"text"}
                  inputStyle={{ 
                    paddingBottom: "10px", 
                    paddingTop: "24px", 
                    wordWrap: "break-word",
                    height: "auto" 
                  }}
                  iconStyle={{
                    alignItems: "initial",
                    marginTop: "10px",
                    height: "auto",
                  }}
                  icon={BusinessIcon()}
                />
                <InfoTextarea
                  width={"198"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 50px",
                  }}
                  label={`Technical Contact`}
                  loading={detailsLoading}
                  value={clientInfo?.technical_contact_name}
                  value1={clientInfo?.technical_contact_number}
                  type={"text"}
                  inputStyle={{ 
                    paddingBottom: "10px", 
                    paddingTop: "24px", 
                    wordWrap: "break-word",
                    height: "auto" 
                  }}
                  iconStyle={{
                    alignItems: "initial",
                    marginTop: "10px",
                    height: "auto",
                  }}
                  icon={SettingIcon()}
                />
              </RowWrapper>

            </ClientDetailsWrapper>
            <HeadingWrapper>
              <TopNavText>USERS</TopNavText>
            </HeadingWrapper>
            <TableWrapper>
              <TableHeading>
                <HeadingCell width={"30px"}>S.No.</HeadingCell>
                <HeadingCell width={"280px"}>Member's Name</HeadingCell>
                <HeadingCell width={"75px"}>Role</HeadingCell>
              </TableHeading>
              <TableRows>
                {membersLoading ? (
                  <div style={{ width: "100%" }}>
                    <SkeletonLoader count={5} height={60} />
                  </div>
                ) : (
                  client.members.map((row, index) => (
                    <TableDataRow
                      key={index}
                      index={index}
                      islast={index === client.members.length - 1}
                    >
                      <DataCell width={"30px"}>{index + 1}.</DataCell>
                      <DataCell width={"280px"}>{row.name}</DataCell>
                      <DataCell style={{ textTransform: "capitalize" }} width={"80px"}>{row.role}</DataCell>
                    </TableDataRow>
                  ))
                )}
              </TableRows>
            </TableWrapper>
            <HeadingWrapper>
              <TopNavText>ENTITLEMENTS</TopNavText>
            </HeadingWrapper>
            <DetailsWrapper>
              {clientInfo?.entitlement_type?.map((entitlement, index) => (
                <InfoTextarea
                  key={index}
                  width={"412"}
                  labelStyle={{
                    position: "absolute",
                    padding: "8px 50px",
                  }}
                  value={entitlement}
                  label={`Entitlement ${index + 1}`}
                  type={"text"}
                  inputStyle={{ paddingBottom: "10px", paddingTop: "24px" }}
                  icon={EntitlementIcon()} // Replace with your actual icon
                />
              ))}
            </DetailsWrapper>
            <HeadingWrapper>
              <TopNavText>SHAREPOINT SITE</TopNavText>
            </HeadingWrapper>
            <DetailsWrapper>
              <InfoTextarea
                width={"412"}
                labelStyle={{
                  position: "absolute",
                  padding: "8px 50px",
                }}
                value={clientInfo?.custom_domain !== "True" ? "Synterex sharepoint": "Custom sharepoint"}
                label={"Sharepoint Site"}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", paddingTop: "24px" }}
                icon={EntitlementIcon()} 
              />
              {
              clientInfo?.custom_domain === "True" && <InfoTextarea
                width={"412"}
                labelStyle={{
                  position: "absolute",
                  padding: "8px 50px",
                }}
                value={clientInfo?.tenant}
                label={"Custom sharepoint Tenant Name"}
                type={"text"}
                inputStyle={{ paddingBottom: "10px", paddingTop: "24px" }}
                icon={EntitlementIcon()}
              />
              }
            </DetailsWrapper>
          </TopWrapper>


        </MembersSideDrawerWrapper>
      </SideDrawer>
    )
  );
};

MembersSideDrawer.propTypes = {};

MembersSideDrawer.defaultProps = {};

export default MembersSideDrawer;
