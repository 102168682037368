import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  A11YSideDrawerWrapper,
  BottomNavWrapper,
  ItemWrapper,
  TopNavWrapper,
  TopWrapper,
  TopNavText,
  TopNavButton,
} from "./A11ySideDrawer.styled";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import A11YSideDrawerItem from "../../components/A11ySideDrawerItem/A11ySideDrawerItem";
import Button from "../../components/Button/Button";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import SpeakerIcon from "../../assets/svgs/SpeakerIcon";
import AMinusIcon from "../../assets/svgs/AMinusIcon";
import AReset from "../../assets/svgs/AReset";
import APlusIcon from "../../assets/svgs/APlusIcon";
import ContrastIcon from "../../assets/svgs/ContrastIcon";
import MoonIcon from "../../assets/svgs/MoonIcon";
import LogoutIcon from "../../assets/svgs/LogoutIcon";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import {
  toggleContrastMode,
  toggleScreenReader,
  incFontDelta,
  decFontDelta,
  toggleDarkMode,
  noFontDelta,
} from "../../store/slices/A11ySlice";
import { connect } from "react-redux";
import useKeyPress from "../../hooks/useKeyPress";
import speechFormat from "../../helpers/speechFormat";
import { useNavigate } from "react-router-dom";

const A11YSideDrawer = ({
  isOpen,
  handleOpen,
  is_contrast,
  speaker_enabled,
  dark_mode,
}) => {
  const { instance, accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;
  const dispatch = useDispatch();

  const [highContrastMode, setHighContrastMode] = useState(is_contrast);
  const [darkMode, setDarkMode] = useState(dark_mode);
  const [speakerMode, setSpeakerMode] = useState(speaker_enabled);
  const DEFAULT_URL = "https://dms-onlyoffice.agilewriter.smarter.codes";
  const DMS_URL = process.env.REACT_APP_DMS_URL
    ? process.env.REACT_APP_DMS_URL
    : DEFAULT_URL;
  const navigate = useNavigate();

  const handleLogout = () => {
    instance.logout();
  };

  // Function to handle toggle switch changes
  const handleToggleSwitchChange = (e) => {
    e && e.preventDefault();
    setHighContrastMode(!highContrastMode);
    dispatch(toggleContrastMode(!highContrastMode));
  };

  const handleToggleSwitchDarkMode = (e) => {
    e && e.preventDefault();
    setDarkMode(!darkMode);
    dispatch(toggleDarkMode(!darkMode));
  };

  const handlespeakerMode = (e) => {
    e && e.preventDefault();
    setSpeakerMode(!speakerMode);
    dispatch(toggleScreenReader(!speakerMode));
  };

  const handleFontIncrease = () => {
    dispatch(incFontDelta());
  };
  const handleFontReset = () => {
    dispatch(noFontDelta());
  };
  const handleFontDecrease = () => {
    dispatch(decFontDelta());
  };

  const onViewLogClick = () => {
    navigate("/logs");
  };

  const overlayName = "A11yDrawer";
  const handleTrain = () => window.open(DMS_URL, "_blank");
  useKeyPress("Escape", [], handleOpen, true, overlayName);
  useKeyPress("H", [], handleToggleSwitchChange, true, overlayName);
  useKeyPress("B", [], handleToggleSwitchDarkMode, true, overlayName);
  useKeyPress("U", [], handlespeakerMode, true, overlayName);
  useKeyPress("+", [], handleFontIncrease, true, overlayName);
  useKeyPress("+", ["shiftKey"], handleFontIncrease, true, overlayName);
  useKeyPress("0", [], handleFontReset, true, overlayName);
  useKeyPress("-", [], handleFontDecrease, true, overlayName);
  useKeyPress("K", ["altKey"], handleLogout, true, overlayName);
  useKeyPress("T", [], handleTrain, true, overlayName);
  useKeyPress("L", ["altKey"], onViewLogClick, true, overlayName);

  return (
    isOpen && (
      <SideDrawer
        isOpen={isOpen}
        handleOpen={handleOpen}
        overlayName={overlayName}
      >
        <A11YSideDrawerWrapper>
          <TopWrapper>
            <TopNavWrapper>
              <TopNavText>OPTIONS</TopNavText>
              <TopNavButton>
                <Button
                  text={"Done"}
                  accessKeyText={"[ESC]"}
                  type={"ghost"}
                  onClick={handleOpen}
                  data-speech={speechFormat({
                    key: "Escape",
                    action: "close",
                    item: "Accessibility Options",
                  })}
                />
              </TopNavButton>
            </TopNavWrapper>
            <ItemWrapper>
              <A11YSideDrawerItem
                onClick={handlespeakerMode}
                title={"Screen Read: Enabled"}
                subtitle={
                  "Title of selected components on the screen will be spoken"
                }
                icon={<SpeakerIcon />}
                toggleSwitch={
                  <ToggleSwitch
                    checked={speakerMode}
                    onChange={handlespeakerMode}
                  />
                }
                accessKeyText={"[U]"}
              />
              <A11YSideDrawerItem
                onClick={handleFontIncrease}
                title={"Increase Font Size"}
                subtitle={
                  "Increases the size of all the fonts visible on screen"
                }
                icon={<APlusIcon />}
                accessKeyText={"[+]"}
              />
              <A11YSideDrawerItem
                onClick={handleFontReset}
                title={"Reset Font Size"}
                subtitle={"Reset the size of all the fonts visible on screen"}
                icon={<AReset />}
                accessKeyText={"[0]"}
              />
              <A11YSideDrawerItem
                onClick={handleFontDecrease}
                title={"Decrease Font Size"}
                subtitle={
                  "Decreases the size of all the fonts visible on screen"
                }
                icon={<AMinusIcon />}
                accessKeyText={"[-]"}
              />
              <A11YSideDrawerItem
                onClick={handleToggleSwitchDarkMode}
                title={"Dark Theme: Enabled"}
                subtitle={
                  "Reduces strain on time, makes BG Dark & font light in color."
                }
                icon={<MoonIcon />}
                toggleSwitch={
                  <ToggleSwitch
                    checked={darkMode}
                    onChange={handleToggleSwitchDarkMode}
                  />
                }
                accessKeyText={"[B]"}
              />
              <A11YSideDrawerItem
                onClick={handleToggleSwitchChange}
                title={"High-Contrast Mode: Enabled"}
                subtitle={
                  "Makes theme color High Contrast, makes UI more readable & clear."
                }
                icon={<ContrastIcon />}
                toggleSwitch={
                  <ToggleSwitch
                    checked={highContrastMode}
                    onChange={handleToggleSwitchChange}
                  />
                }
                accessKeyText={"[H]"}
              />
            </ItemWrapper>
          </TopWrapper>
          {isLoggedIn && (
            <BottomNavWrapper>
               <Button
                // icon={<LogoutIcon />}
                text={"Train AI"}
                accessKeyText={"[ T ]"}
                type={"ghost"}
                buttonStyle={{ padding: "0px", width: "100%" }}
                onClick={handleTrain}
                data-speech={speechFormat({
                  key: "T",
                  action: "",
                  item: "Train AI",
                })}
              />
               <Button
                // icon={<LogoutIcon />}
                text={"Audit Log"}
                accessKeyText={"[Alt + L ]"}
                type={"ghost"}
                buttonStyle={{ padding: "0px", width: "100%" }}
                onClick={onViewLogClick}
                data-speech={speechFormat({
                  key: "Alt + L",
                  action: "",
                  item: "Audit Log",
                })}
              />
              <Button
                icon={<LogoutIcon />}
                text={"Log Out"}
                accessKeyText={"[Alt + K]"}
                type={"ghost"}
                onClick={handleLogout}
                buttonStyle={{ padding: "0px", width: "100%" }}
                data-speech={speechFormat({
                  key: "Alt + K",
                  action: "",
                  item: "Logout",
                })}
              />
            </BottomNavWrapper>
          )}
        </A11YSideDrawerWrapper>
      </SideDrawer>
    )
  );
};

A11YSideDrawer.propTypes = {};

A11YSideDrawer.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
    speaker_enabled: state?.a11y?.screen_reader,
  };
};

export default connect(mapStateToProps)(A11YSideDrawer);
