import styled from "styled-components";

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  cursor: pointer;
`;

export const LogoIconWrapper = styled.div`
  width: ${({ width }) => (width ? width : "50px")};
  height: ${({ height }) => (height ? height : "50px")};
`;

export const LogoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const LogoTitle = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Barlow Condensed;
  font-size: ${(props) => props.theme.font_delta + 24}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const LogoSubtitle = styled.div`
  color : ${(props) => props.theme.color_theme.color};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
`;
