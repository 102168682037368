import React, { useState } from "react";
import {
  PreviewBody,
  PreviewFooter,
  PreviewHeader,
  PreviewSubtext,
  PreviewTitle,
  Wrapper,
} from "./PreviewModal.styled";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "../../components/Button/Button";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";

const PreviewModal = ({ onClose }) => {
  const [fileBlob, setFileBlob] = useState(null);
  const [numPages, setNumPages] = useState(null);
  return (
    <Wrapper>
      <Button onClick={onClose} text={"Close"} type={"ghost"}></Button>
      <PreviewHeader>
        <PreviewTitle>Preview of the Selected Doc</PreviewTitle>
        <PreviewSubtext>Vaccine_protocol_template.docx</PreviewSubtext>
      </PreviewHeader>
      <PreviewBody>
        {(fileBlob && (
          <Document
            file={fileBlob}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
          </Document>
        )) || (
            <div style={{ width: "100%" }}>
              <SkeletonLoader height={74} />
            </div>
          )}
      </PreviewBody>
      <PreviewFooter>
        <Button
          type={"ghost"}
          text={"Scroll Up"}
          buttonStyle={{ padding: "20px 0px" }}
          accessKeyText={"[Page Up]"}
        />
        <Button
          type={"ghost"}
          text={"Scroll Down"}
          buttonStyle={{ padding: "20px 0px" }}
          accessKeyText={"[Page Dn]"}
        />
      </PreviewFooter>
    </Wrapper>
  );
};

export default PreviewModal;
