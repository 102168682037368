import React from "react";

const PrevPageIcon = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.0002V6.00024H7.5V18.0002H6ZM19 18.0002L10.35 12.0002L19 6.00024V18.0002ZM17.5 15.1252V8.87524L12.975 12.0002L17.5 15.1252Z"
      />
    </svg>
  );
};

export default PrevPageIcon;
