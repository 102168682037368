import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RadioButtonWrapper, RadioLabel, StyledInput, TextLabel } from "./RadioButtonGroup.styled";

const RadioButtonGroup = ({
  voiceCommand,
  searchCommand,
  righticons,
  wrapperStyle,
  value,
  width,
  onChange,
  speech,
  options,
  selectedIndex,
  ...props
}) => {

  return (
    <RadioButtonWrapper style={wrapperStyle}>
      {options.map((item, index) => 
        <RadioLabel
        key={item.title}
        onClick={() => {
          onChange(index);
        }}
      >
        <StyledInput
          type="radio"
          value={item.title}
          checked={index === selectedIndex}
          onClick={() => {
            onChange(index);
          }}
        />
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <TextLabel>{item.title}</TextLabel>
          <TextLabel>{item.inputCommand.text}</TextLabel>
        </div>
      </RadioLabel>
      )}
      
    </RadioButtonWrapper>
  );
};

RadioButtonGroup.propTypes = {};

RadioButtonGroup.defaultProps = {};

export default RadioButtonGroup;
