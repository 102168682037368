import React from "react";
import unknownicon from "../unknown.png"
const UnknownFileIcon = () => {
  return (
    <>
    <img src={unknownicon} alt="unknown"/>
    </>
  );
};

export default UnknownFileIcon;
