import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, TextWrapper, ShortcutWrapper } from "./NoSearchFound.styled";
import NoSearchFoundIcon from "../../assets/svgs/NoSearchFoundIcon";
import Button from "../Button/Button";
import { connect } from "react-redux";
import speechFormat from "../../helpers/speechFormat";

const NoSearchFound = ({
  height,
  high_contrast,
  dark_mode,
  accessKey,
  resetSearch,
}) => {
  const [crossColor, setCrossColor] = useState("#F2F2F2");
  const [rectColor, setRectColor] = useState("#D9D9D9");
  const [searchColor, setSearchColor] = useState("#AAAAAA");

  useEffect(() => {
    if (!high_contrast && !dark_mode) {
      setCrossColor("#F2F2F2");
      setRectColor("#D9D9D9");
      setSearchColor("#AAAAAA");
    }
    if (high_contrast && !dark_mode) {
      setRectColor("#C5C5C5");
      setSearchColor("#868686");
    }

    if (!high_contrast && dark_mode) {
      setCrossColor("#242424");
      setRectColor("#525252");
      setSearchColor("#AAAAAA");
    }

    if (high_contrast && dark_mode) {
      setCrossColor("#FFFFFF");
      setRectColor("#525252");
      setSearchColor("#AAAAAA");
    }
  }, [high_contrast, dark_mode]);

  return (
    <Wrapper height={height}>
      <NoSearchFoundIcon
        crossColor={crossColor}
        rectColor={rectColor}
        searchColor={searchColor}
      />
      <TextWrapper>No match found for your query</TextWrapper>
      <Button
        type="ghost"
        text={"Clear Search"}
        accessKeyText={accessKey ? `[ ${accessKey} ]` : `[ Alt + K ]`}
        buttonStyle={{ padding: "4px 0px", width: "fit-content" }}
        data-speech={speechFormat({
          key: accessKey || "Alt + K",
          action: "",
          item: "Clear Search",
        })}
        onClick={resetSearch}
      />
    </Wrapper>
  );
};

NoSearchFound.propTypes = {};
const mapStateToProps = (state) => {
  return {
    high_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
  };
};

export default connect(mapStateToProps)(NoSearchFound);
