import React from "react";

const DownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M12 16.3758L6 10.3758L7.075 9.30078L12 14.2508L16.925 9.32578L18 10.4008L12 16.3758Z" />
        </svg>
    );
};

export default DownIcon;
