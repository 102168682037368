import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
    name: "client",
    initialState: { client_count: 0 },
    reducers: {
        incrClientCount(state, action) {
            state.client_count += action.payload;
        },
    },
});

export const { incrClientCount } = clientSlice.actions;
export default clientSlice.reducer;
