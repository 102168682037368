import React from "react";

const AddUserIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clip-path="url(#clip0_2117_16124)">
                <path d="M18.25 13.9986V10.7486H15V9.24863H18.25V5.99863H19.75V9.24863H23V10.7486H19.75V13.9986H18.25ZM9 11.9736C7.9 11.9736 7 11.6236 6.3 10.9236C5.6 10.2236 5.25 9.32363 5.25 8.22363C5.25 7.12363 5.6 6.22363 6.3 5.52363C7 4.82363 7.9 4.47363 9 4.47363C10.1 4.47363 11 4.82363 11.7 5.52363C12.4 6.22363 12.75 7.12363 12.75 8.22363C12.75 9.32363 12.4 10.2236 11.7 10.9236C11 11.6236 10.1 11.9736 9 11.9736ZM1 19.9986V17.6486C1 17.0653 1.14583 16.5361 1.4375 16.0611C1.72917 15.5861 2.15 15.232 2.7 14.9986C3.95 14.4486 5.06115 14.0611 6.03345 13.8361C7.00577 13.6111 7.99327 13.4986 8.99595 13.4986C9.99865 13.4986 10.9833 13.6111 11.95 13.8361C12.9167 14.0611 14.025 14.4486 15.275 14.9986C15.825 15.2486 16.25 15.607 16.55 16.0736C16.85 16.5403 17 17.0653 17 17.6486V19.9986H1ZM2.5 18.4986H15.5V17.6486C15.5 17.382 15.425 17.1278 15.275 16.8861C15.125 16.6445 14.925 16.4653 14.675 16.3486C13.4917 15.7986 12.4917 15.4361 11.675 15.2611C10.8583 15.0861 9.96667 14.9986 9 14.9986C8.03333 14.9986 7.1375 15.0861 6.3125 15.2611C5.4875 15.4361 4.48333 15.7986 3.3 16.3486C3.05 16.4653 2.85417 16.6445 2.7125 16.8861C2.57083 17.1278 2.5 17.382 2.5 17.6486V18.4986ZM9 10.4736C9.65 10.4736 10.1875 10.2611 10.6125 9.83613C11.0375 9.41113 11.25 8.87363 11.25 8.22363C11.25 7.57363 11.0375 7.03613 10.6125 6.61113C10.1875 6.18613 9.65 5.97363 9 5.97363C8.35 5.97363 7.8125 6.18613 7.3875 6.61113C6.9625 7.03613 6.75 7.57363 6.75 8.22363C6.75 8.87363 6.9625 9.41113 7.3875 9.83613C7.8125 10.2611 8.35 10.4736 9 10.4736Z" />
            </g>
            <defs>
                <clipPath id="clip0_2117_16124">
                    <rect width="24" height="24" fill="white" transform="translate(0 -0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AddUserIcon;
