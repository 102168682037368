import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { HeaderWrapper, OptionsWrapper, Tooltip, TooltipText } from "./Header.styled";
import Logo from "../../components/Logo/Logo";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import A11YSideDrawer from "../A11ySideDrawer/A11ySideDrawer";
import A11YMenuButtonIcon from "../../assets/svgs/A11YMenuButtonIcon";
import useKeyPress from "../../hooks/useKeyPress";
import speechFormat from "../../helpers/speechFormat";
import { connect, useDispatch } from "react-redux";
import { setOptionsToolTip } from "../../store/slices/A11ySlice";

const Header = ({ loginToolTip, optionToolTip, selectedButton }) => {
  const DEFAULT_URL = "https://dms-onlyoffice.agilewriter.smarter.codes";
  const DMS_URL = process.env.REACT_APP_DMS_URL
    ? process.env.REACT_APP_DMS_URL
    : DEFAULT_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;

  const [a11yDrawerOpen, setA11yDrawerOpen] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  const handleA11yDrawerOpen = () => {
    setA11yDrawerOpen(false);
  };


  const handleheaderClick = (buttonType) => {

    if (buttonType === 'generate') {
      navigate("/generate");
    }

    if (buttonType === 'format') {
      navigate("/format");
    }

    if (buttonType === 'redact') {
      navigate("/redact");
    }
  }

  const onViewLogClick = () => {
    navigate("/logs");
  };

  const hideToolTip = () => {
    if (!loginToolTip && optionToolTip) {
      dispatch(setOptionsToolTip(false))
    }
  }

  const handleTrain = () => window.open(DMS_URL, "_blank");
  useKeyPress("o", [], () => setA11yDrawerOpen(true));
  useKeyPress("I", ["altKey"], () => handleheaderClick("generate"));
  useKeyPress("S", ["altKey"], () => handleheaderClick("format"));
  useKeyPress("R", ["altKey"], () => handleheaderClick("redact"));

  // useKeyPress("L", ["altKey"], onViewLogClick);
  // useKeyPress("T", [], handleTrain);
  useKeyPress("Q", [], hideToolTip)

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes("signin")) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
  }, []);



  return (
    <HeaderWrapper hideHeader={hideHeader}>
      {!hideHeader ? <Logo /> : <div style={{ display: "flex" }}></div>}
      <OptionsWrapper>
        {isLoggedIn && (
          <>
            {/* <Button
              type="tertiary"
              text="Train AI"
              accessKeyText="[ T ]"
              onClick={handleTrain}
              data-speech={speechFormat({
                key: "T",
                action: "",
                item: "Train AI",
              })}
            /> */}

            <Button 
              buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
              type="brand"
              disabled={selectedButton === "redact"}
              text="Redact Doc"
              accessKeyText="[Alt + R ]"
              onClick={() => handleheaderClick("redact")}
              data-speech={speechFormat({
                key: "Alt + R",
                action: "",
                item: "Redact Doc",
              })}
            />
            <Button
              buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
              type="brand"
              disabled={selectedButton === "generate"}
              text="Generate Doc"
              accessKeyText="[Alt + I ]"
              onClick={() => handleheaderClick("generate")}
              data-speech={speechFormat({
                key: "Alt + I",
                action: "",
                item: "Generate Doc",
              })}
            />
             <Button
              buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
              type="brand"
              disabled={selectedButton === "format"}
              text="Format Doc"
              accessKeyText="[Alt + S ]"
              onClick={() => handleheaderClick("format")}
              data-speech={speechFormat({
                key: "Alt + S",
                action: "",
                item: "Format Doc",
              })}
            />
            {/* <Button
              buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
              type="brand"
              disabled={getAuditLogButtonState}
              text="Audit Log"
              accessKeyText="[Alt + L ]"
              onClick={onViewLogClick}
              data-speech={speechFormat({
                key: "Alt + L",
                action: "",
                item: "Audit Log",
              })}
            /> */}
          </>
        )}
        <Tooltip>
          <Button
            icon={<A11YMenuButtonIcon />}
            accessKeyText={"[ O ]"}
            type={"ghost"}
            onClick={() => setA11yDrawerOpen(true)}
            data-speech={speechFormat({
              key: "O",
              action: "open",
              item: "Accessibility Options",
            })}
          />
          {!loginToolTip && optionToolTip &&
            <TooltipText>
              Click here for options for Accessibilities
              <br />
              <div style={{ marginTop: "10px", cursor: "pointer" }} onClick={() => hideToolTip()}>
                Dismiss <span style={{ opacity: 0.5 }}>[Q]</span>

              </div>
            </TooltipText>
          }
        </Tooltip>
      </OptionsWrapper>
      <A11YSideDrawer
        isOpen={a11yDrawerOpen}
        handleOpen={handleA11yDrawerOpen}
      />

    </HeaderWrapper>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
    dark_mode: state?.a11y?.dark_mode,
    loginToolTip: state?.a11y?.loginToolTip,
    optionToolTip: state?.a11y?.optionsToolTip,
  };
};

export default connect(mapStateToProps)(Header);
