import { styled } from "styled-components";

export const Wrapper = styled.div`
//   background-color: ${(props) => props.theme.color_theme.bodyColor};
    display: flex;
    justify-content: center;
    width: -webkit-fill-available; /* For WebKit-based browsers */
    width: -moz-available; /* For Mozilla Firefox */
    width: fill-available; /* For other modern browsers */
    align-items: center;
    flex-direction: column;
    height: ${({ height }) => height};

`;

export const TextWrapper = styled.div`
    color: ${(props) => props.theme.color_theme.color};
    opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`


export const ShortcutWrapper = styled.div`
    color: ${(props) => props.theme.color_theme.color};
    opacity: ${(props) => props.theme.color_theme.shortcutLabelOpacity};
    font-family: Segoe UI;
    font-size: ${(props) => props.theme.font_delta + 10}px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

