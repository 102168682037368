import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/UserSlice";
import clientReducer from "./slices/ClientSlice";
import A11yReducer from "./slices/A11ySlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);
const clientpersistedReducer = persistReducer(persistConfig, clientReducer);
const A11ypersistedReducer = persistReducer(persistConfig, A11yReducer);

const store = configureStore({
  reducer: {
    user: persistedReducer,
    client: clientpersistedReducer,
    a11y: A11ypersistedReducer
  },
});

const persistor = persistStore(store);

export { store, persistor };
export default store;
