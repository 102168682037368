import styled from "styled-components";

export const RadioButtonWrapper = styled.div`
  display: flex;
  height: 42px;
  text-align: center;
  width: 100%;
  justify-content: space-between;
`;


export const RadioLabel = styled.div`
  margin-bottom: 10px;
  padding: 9px 20px;
  border: 1px solid #ccc;
  border-radius: 16px;
  height: ${({ height }) => (height ? height : "")};
  cursor: pointer;
  height: 34px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  display: flex;
  width: 246px;
  gap: 8px;
  align-items: center;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: ${(props) => props.theme.color_theme.inputFillColor};
  color: ${(props) => props.theme.color_theme.color};
  font-style: normal;
  font-weight: 400;
`;

export const StyledInput = styled.input`
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  height: ${({ height }) => (height ? height : "")};
  padding-left: ${({ hasIcon }) => (hasIcon ? "50px" : "20px")};
  background-position: ${({ hasIcon }) =>
    hasIcon ? "10px center" : "2px center"};
  background-repeat: no-repeat;
  background-size: ${({ hasIcon }) => (hasIcon ? "20px" : "auto")};
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: ${(props) => props.theme.color_theme.inputFillColor};
  color: ${(props) => props.theme.color_theme.color};
  &:focus {
    border-color: ${(props) => props.theme.color_theme.primaryColor}; /* Change to your desired focus color */
    outline: none;
  }
`;

export const TextLabel = styled.span`
  font-size: ${(props) => props.theme.font_delta + 14}px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: 0.8;
`
