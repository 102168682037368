import DocxIcon from "../assets/svgs/DocxIcon";
import JsonIcon from "../assets/svgs/JsonIcon";
import XlsxIcon from "../assets/svgs/XlsxIcon";
import PdfIcon from "../assets/svgs/PdfIcon";
import UnknownFileIcon from "../assets/svgs/UnknownFileIcon";
import HtmlIcon from "../assets/svgs/HtmlIcon";
import RtfIcon from "../assets/svgs/RtfIcon";
import FolderDocIcon from "../assets/svgs/FolderDocIcon";


function extractExtensionIcon(filename, isFolder=false) {

    if (isFolder) {
        return <FolderDocIcon />
    }

    const fileExtension = filename?.split('.')?.pop();
    if (fileExtension === 'docx') {
        return <DocxIcon />
    } else if (fileExtension === 'json') {
        return <JsonIcon />
    } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        return <XlsxIcon />
    } else if (fileExtension === 'pdf') {
        return <PdfIcon />
    }  else if (fileExtension === 'html') {
        return <HtmlIcon />
    }  else if (fileExtension === 'rtf') {
        return <RtfIcon />
    } else {
        return <UnknownFileIcon />
    }
}

export default extractExtensionIcon