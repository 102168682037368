import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const EditClientModalWrapper = styled.div`
  display: flex;
  width: 800px;
  height: 530px;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
  margin-bottom: 20px;
`;

export const HeadTitle = styled.div`
  ${Fonts.Body3}
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-transform: uppercase;
`;
export const HeadRight = styled.div`
  display: flex;
`;

export const MemberDetailWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: ${({ bottomPadding }) => (bottomPadding ? `${bottomPadding}px` : "15px")}; 
    border-bottom: 1px solid #f2f2f2;
`

export const AddMemberWrapper = styled.div`
    width: 100%;
    padding-top: 15px;
    border-bottom: 1px solid #F2F2F2;
`

export const MemberDetailContainer = styled.div`
    // overflow-y: scroll;
    // height: 300px;
`

export const AddMemberLabel = styled.div`
    position: relative;
    top: -35px;
    padding: 20px;
    left: calc(100% - 320px);
`
export const EntitlementDetailWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px; 
    /* border-bottom: 1px solid #f2f2f2; */
`

export const SelectMemberRole = styled.div`
  display: flex;
  width: 300px;
  border-radius: 18px;
  border: 1px solid #e8e8e8;
  background: #fbfbfb;
  height: 55px;
  flex-direction: column;
  padding-left: 13px;

  label{
    font-size: ${(props) => props.theme.font_delta + 10}px;
    padding: 8px 20px;
    text-align: left;
    opacity:0.6;
    padding-left: 4px;
  }

  select {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 290px;
  }

  select option {
    background-color: #f7f7f7;
    color: #333;
    
  }
`

export const ExtraWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  width: 584px;
  justify-content: space-between;
`

export const SharepointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  gap: 20px;
`

export const ErrorWrapper = styled.div`
  padding: 10px;
  color: red;
  font-size: ${(props) => props.theme.font_delta + 10}px;
`