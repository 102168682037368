import React from "react";

const MicIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_581_2404)">
                <g clip-path="url(#clip1_581_2404)">
                    <g clip-path="url(#clip2_581_2404)">
                        <path d="M12 14.4248C11.2833 14.4248 10.6833 14.1671 10.2 13.6518C9.71667 13.1366 9.475 12.5109 9.475 11.7748V5.49976C9.475 4.80531 9.72034 4.21503 10.211 3.72893C10.7017 3.24281 11.2975 2.99976 11.9985 2.99976C12.6995 2.99976 13.2958 3.24281 13.7875 3.72893C14.2792 4.21503 14.525 4.80531 14.525 5.49976V11.7748C14.525 12.5109 14.2833 13.1366 13.8 13.6518C13.3167 14.1671 12.7167 14.4248 12 14.4248ZM11.25 21.9998V18.5998C9.48333 18.4164 8 17.6748 6.8 16.3748C5.6 15.0748 5 13.5414 5 11.7748H6.5C6.5 13.2914 7.03573 14.5664 8.1072 15.5998C9.17867 16.6331 10.4745 17.1498 11.9947 17.1498C13.5149 17.1498 14.8125 16.6331 15.8875 15.5998C16.9625 14.5664 17.5 13.2914 17.5 11.7748H19C19 13.5414 18.4 15.0748 17.2 16.3748C16 17.6748 14.5167 18.4164 12.75 18.5998V21.9998H11.25ZM12 12.9248C12.3 12.9248 12.5458 12.8123 12.7375 12.5873C12.9292 12.3623 13.025 12.0914 13.025 11.7748V5.49976C13.025 5.21642 12.9268 4.97892 12.7303 4.78726C12.5339 4.59559 12.2904 4.49976 12 4.49976C11.7096 4.49976 11.4661 4.59559 11.2697 4.78726C11.0732 4.97892 10.975 5.21642 10.975 5.49976V11.7748C10.975 12.0914 11.0708 12.3623 11.2625 12.5873C11.4542 12.8123 11.7 12.9248 12 12.9248Z" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_581_2404">
                    <rect y="-0.000244141" width="24" height="24" rx="12" fill="white" />
                </clipPath>
                <clipPath id="clip1_581_2404">
                    <rect width="24" height="24" fill="white" transform="translate(0 -0.000244141)" />
                </clipPath>
                <clipPath id="clip2_581_2404">
                    <rect width="24" height="24" fill="white" transform="translate(0 -0.000244141)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MicIcon;
