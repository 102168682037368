import React from "react";

const SettingIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6234 20.8L11.1734 14.3C10.7901 14.4333 10.4068 14.5417 10.0234 14.625C9.6401 14.7083 9.24844 14.75 8.84844 14.75C7.23039 14.75 5.85504 14.1861 4.72239 13.0583C3.58975 11.9306 3.02344 10.5611 3.02344 8.95C3.02344 8.43333 3.09142 7.9301 3.22739 7.4403C3.36335 6.95048 3.5537 6.48705 3.79844 6.05L7.42344 9.675L9.72344 7.525L5.99844 3.8C6.43035 3.54737 6.88833 3.35088 7.37236 3.21053C7.85641 3.07018 8.34844 3 8.84844 3C10.5012 3 11.9061 3.57847 13.063 4.73542C14.22 5.89236 14.7984 7.29722 14.7984 8.95C14.7984 9.35 14.7568 9.74167 14.6734 10.125C14.5901 10.5083 14.4818 10.8917 14.3484 11.275L20.8234 17.725C21.0068 17.9076 21.0984 18.1283 21.0984 18.387C21.0984 18.6457 21.0068 18.8667 20.8234 19.05L18.9234 20.8C18.7452 20.9833 18.5298 21.075 18.2772 21.075C18.0247 21.075 17.8068 20.9833 17.6234 20.8ZM18.3234 19.375L19.3234 18.375L12.4984 11.55C12.7651 11.2 12.9651 10.7875 13.0984 10.3125C13.2318 9.8375 13.2984 9.38333 13.2984 8.95C13.2984 7.7 12.8359 6.64167 11.9109 5.775C10.9859 4.90833 9.93177 4.46667 8.74844 4.45L11.2984 7.05C11.4484 7.2 11.5193 7.37917 11.5109 7.5875C11.5026 7.79583 11.4234 7.975 11.2734 8.125L7.94844 11.25C7.79389 11.3833 7.61359 11.45 7.40754 11.45C7.20147 11.45 7.03177 11.3833 6.89844 11.25L4.44844 8.825C4.49844 10.1083 4.954 11.1667 5.81514 12C6.67627 12.8333 7.68737 13.25 8.84844 13.25C9.2651 13.25 9.70677 13.1833 10.1734 13.05C10.6401 12.9167 11.0484 12.7167 11.3984 12.45L18.3234 19.375Z" />
        </svg>
    );
};

export default SettingIcon;
