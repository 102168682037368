import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures that the layout covers the entire viewport height */
  overflow: hidden; /* Remove if not required */
`;

export const LayoutMain = styled.main`
  flex-grow: 1; /* Takes up the remaining vertical space */
  display: flex;
`;