import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const Wrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackspaceContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const FormTitle = styled.div`
  ${Fonts.Body3};
  text-align: center;
  text-transform: uppercase;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 0px;
  align-items: center;
  row-gap: 20px;
`;

export const FormFooter = styled.div`
  display: flex;
  margin: 0 110px;
  /* margin-top: -24px; */
  align-items: center;
  width: 420px;
  justify-content: center
`;