import React, { useState } from "react";
import Button from '../Button/Button';
import {UploadWrapper} from './UploadDoc.styled'
import InputField from "../InputField/InputField";

const UploadDoc = ({width,righticons,wrapperStyle, rightUploadButton,selectedFile,setSelectedFile, placeholder="Upload Document", ...props}) => {
    return (
        <UploadWrapper 
            style={{
                // height: "62px",
                ...wrapperStyle,
            }}
        >
            <InputField
                width={width}
                type="file"
                placeholder={selectedFile ? selectedFile.name : placeholder}
                setSelectedFile={setSelectedFile}
                rightUploadButton={rightUploadButton}
                {...props}
            />

        </UploadWrapper>
    )
}
export default UploadDoc;