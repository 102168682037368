import React from "react";

const DeleteIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_944_9167)">
                <path d="M12.525 27C12.1125 27 11.7594 26.8531 11.4656 26.5594C11.1719 26.2656 11.025 25.9125 11.025 25.5V11.25H10V9.75H14.7V9H21.3V9.75H26V11.25H24.975V25.5C24.975 25.9 24.825 26.25 24.525 26.55C24.225 26.85 23.875 27 23.475 27H12.525ZM23.475 11.25H12.525V25.5H23.475V11.25ZM15.175 23.35H16.675V13.375H15.175V23.35ZM19.325 23.35H20.825V13.375H19.325V23.35Z" />
            </g>
            <defs>
                <clipPath id="clip0_944_9167">
                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>


    );
};

export default DeleteIcon;