import React, { useEffect, useState } from "react";
import DocxIcon from "../../assets/svgs/DocxIcon";
import {
  TextContainer,
  ActionContainer,
  Row,
  RowJustify,
  SmallText,
  TitleText,
  Wrapper,
  SelectTextWrapper,
} from "./DocumentItem.styled";
import Button from "../Button/Button";
import DownloadIcon from "../../assets/svgs/DownloadIcon";
import { connect } from "react-redux";
import speechFormat from "../../helpers/speechFormat";
import extractExtensionIcon from "../../helpers/extractExtension";

const DocumentItem = ({
  text,
  date,
  index,
  selectedItemIndex,
  setSelectedItemIndex,
  handleDownload,
  showIcon,
  labelText,
  wrapperStyle,
  is_contrast,
  height,
  RightSelectButton = false,
  type="file",
  addElement,
  removeElement,
  array=[],
  setArray,
  templateType
}) => {
  
  const handleClick = () => {
    try {
      if (typeof addElement === 'function') {
        if (!array.includes(text)) {
          addElement(text);
        }
      } else {
        // console.error('addElement is not a function');
      }
      setSelectedItemIndex(index);
    } catch (error) {
      console.error('Error occurred in handleClick:', error);
    }
  };
  

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === String(index + 1)) {
        event.preventDefault();
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [index, handleClick]);

  return (
    <Wrapper
      key={index}
      selected={selectedItemIndex === index}
      onClick={templateType === "configuration_Doc" ? handleClick : () => setSelectedItemIndex(index)}
      style={{ ...wrapperStyle }}
      RightSelectButton={RightSelectButton}
    >
      <Row height={height}>
        {showIcon ? extractExtensionIcon(text, type === 'folder') : null}
        <RowJustify RightSelectButton={RightSelectButton}>
          <TextContainer RightSelectButton={RightSelectButton} selected={selectedItemIndex === index}>
            <TitleText tabIndex={0} >{text}</TitleText>
            {RightSelectButton ? <SmallText RightSelectButton={RightSelectButton} selected={selectedItemIndex === index} >{labelText}</SmallText> :
              <Button
                type="ghost"
                text={selectedItemIndex === index ? "Selected" : labelText}
                subText={true}
                onClick={templateType === "configuration_Doc" ? handleClick : () => setSelectedItemIndex(index)}
                accessKeyText={
                  selectedItemIndex === index ? "" : `[ Ctrl + ${index + 1} ]`
                }
                buttonStyle={{ padding: "4px 0px", width: "fit-content" }}
                textStyle={{
                  fontStyle: selectedItemIndex === index ? "italic" : "inherit",
                }}
                data-speech={speechFormat({
                  key:
                    selectedItemIndex === index ? "Z" : `Control + ${index + 1}`,
                  action: selectedItemIndex === index ? "download" : "select",
                  item: selectedItemIndex === index ? text : `${text}`,
                })}
              />
            }
          </TextContainer>
          {RightSelectButton &&
            <>
              <div style={{
                "display": "flex",
                "alignItems": "end",
              }}>
                <div style={{ "display": "flex", "alignItems": "flex-end" }}>
                  {/* {templateType==="configuration_Doc"? "":
                  <> */}
                   <SelectTextWrapper selected={selectedItemIndex === index} >{selectedItemIndex === index ? "Selected" : `Select this doc`}</SelectTextWrapper>&nbsp;
                   {selectedItemIndex !== index && <SelectTextWrapper style={{ "fontSize": "12px", opacity: "0.6" }}>{`[ Ctrl + ${index + 1} ]`}</SelectTextWrapper>}
                {/* </> } */}
                 </div>
              </div>
            </>
          }
          {handleDownload && (
            <ActionContainer>
              <Button
                type="ghost"
                accessKeyText="[Z]"
                buttonStyle={{ padding: "0px 0px", height: "26px" }}
                iconStyle={{ fill: selectedItemIndex === index && is_contrast ? "#000" : "" }}
                extraTextStyle={{ color: selectedItemIndex === index && is_contrast ? "#000" : "" }}
                icon={<DownloadIcon />}
                onClick={() => handleDownload(text)}
                data-speech={speechFormat({
                  key: "Z",
                  action: "download",
                  item: ``,
                })}
              />
              <SmallText selected={selectedItemIndex === index} >{date}</SmallText>
            </ActionContainer>
          )}
        </RowJustify>
      </Row>
    </Wrapper>
  );
};

DocumentItem.defaultProps = {
  showIcon: true,
  labelText: "Select this doc",
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(DocumentItem);
