import axios from "axios";
import axiosRetry from "axios-retry";
import store from "../store";

const DEFAULT_URL = "https://api-dpai.agilewriter.smarter.codes";
const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : DEFAULT_URL;

const ApiServer = axios.create({ baseURL: API_URL, timeout: 100000 });

axiosRetry(ApiServer, {
  retries: 5,
  retryDelay: () => {
    return 1000;
  },
  retryCondition: (error) => {
    return [401, 403].includes(error?.response?.status);
  },
});

ApiServer.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiServer.defaults.headers.common["X-API-Key"] = process.env.REACT_APP_API_TEST_KEY;

store.subscribe(listener);

function listener() {
  let token = select(store.getState());

  ApiServer.defaults.headers.common.Authorization = `Bearer ${token}`;

  function select(state) {
    return state?.user?.token;
  }
}

export default ApiServer;
