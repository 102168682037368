import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  height: 36px;
  border: none;
  border-radius: 21px;
  cursor: pointer;
  width: auto;
  font-weight: 500;
  font-family: Segoe UI;
  transition: background-color 0.3s ease-in-out;

  ${({ type, disabled, is_contrast, theme }) => {
    if (disabled) {
      return css`
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
        opacity: 0.6;
      `;
    }

    if (disabled && is_contrast) {
      return css`
        background-color: #ccc;
        color: ${theme.color_theme.color};
        cursor: not-allowed;
        opacity: 0.2;
      `;
    }

    if (is_contrast && type === "brand") {
      return `
        background-color: ${theme.color_theme.primaryColor};
        color: ${theme.color_theme.bodyColor};
      `;
    }

    if (type === "primary") {
      return `
      background-color: #15910A;
        color: white;
      `;
    }
    if (type === "secondary") {
      return css`
        background-color: ${theme.color_theme.color};
        color: ${theme.color_theme.bodyColor};
      `;
    }

    if (type === "brand") {
      return `
        background-color: #2E9866;
        color: white;
      `;
    }
    if (type === "tertiary") {
      return `
        background-color: ${theme.color_theme.bodyColor};
        color: ${theme.color_theme.color};
        border: 1px solid ${theme.color_theme.color};
      `;
    }
    if (type === "ghost") {
      return `
        background-color: transparent;
        color: inherit;
        border: none;
      `;
    }
  }};

  &:hover {
    opacity: ${({ type }) => (type !== "ghost" ? "0.8" : "1")};
  }
`;

export const IconWrapper = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  fill: ${(props) => props.theme.color_theme.color};
`;

export const TextWrapper = styled.span`
  text-align: center;

  ${({ type, theme, subText }) => {
    if (type === "ghost" && subText) {
      return `
      color: ${(props) => props.theme.color_theme.color};
      opacity: ${theme.color_theme.labelOpacity};
      `;
    }
  }}
`;

export const ExtraTextWrapper = styled.span`
  margin-left: 6px;

  ${(props) =>
    props.dark_mode
      ? `
      color : ${props.theme.color_theme.color};
      opacity: ${props.theme.color_theme.shortcutLabelOpacity};
  `
      : `
      opacity: ${props.theme.color_theme.shortcutLabelOpacity};
      ${({ type, is_contrast }) => {
        if ((is_contrast && type === "secondary") || type === "brand") {
          return `
          color: rgba(255, 255, 255, 0.8);
          `;
        }
        if (type === "secondary" || type === "brand") {
          return `
          color: rgba(255, 255, 255, 0.6);
          `;
        }
      }}
      `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
