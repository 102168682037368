import React from "react";
import PropTypes from "prop-types";
import {
  ToggleSwitchWrapper,
  ToggleSwitchLabel,
  ToggleSwitchInput,
  ToggleSwitchSpan,
} from "./ToggleSwitch.styled";

const ToggleSwitch = ({ checked, onChange }) => (
  <ToggleSwitchWrapper>
    <ToggleSwitchLabel>
      <ToggleSwitchInput type="checkbox" checked={checked} onChange={onChange} />
      <ToggleSwitchSpan />
    </ToggleSwitchLabel>
  </ToggleSwitchWrapper>
);

ToggleSwitch.propTypes = {};

ToggleSwitch.defaultProps = {};

export default ToggleSwitch;
