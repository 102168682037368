import React, { useState, useRef, useEffect } from "react";
import {
  DropDownWrapper,
  DropDownButton,
  SVG,
  OptionMenu,
  OptionRow,
  SvgTest,
  Label,
  OptionText,
  IconWrapper,
  ExtraTextWrapper,
  RightIconWrapper
} from "./Dropdown.styled";
import styled from "styled-components";
import useKeyPress from "../../hooks/useKeyPress";

function DropDownNew({
  defaultText = "",
  options = [],
  label,
  labelStyle,
  icon,
  width,
  onChangefunction,
  inputCommand,
  iconStyle,
  isOverlay,
  overlayName
}) {
  const [actionDropDown, setActionDropDown] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const dropdownEl = useRef(null);
  const [mainDefaultText, setMainDefaultText] = useState(defaultText);
  const [highlightedItem, setHighlightedItem] = useState(-1);


  const handleClickOutside = (event) => {
    if (dropdownEl.current && !dropdownEl.current.contains(event.target)) {
      setActionDropDown(false);
    }
  };

  useEffect(() => {
    // Bind the event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  const dropdown = () => {
    setActionDropDown(!actionDropDown);
  };

  useKeyPress(
    inputCommand?.key,
    inputCommand?.subkey,
    dropdown,
    isOverlay,
    overlayName
  );

  // Set Default Text on Button
  useEffect(() => {
    setMainDefaultText(defaultText);
  }, [defaultText]);

  useEffect(() => {
    onChangefunction(mainDefaultText);
  }, [mainDefaultText]);

  const handleSelectedItem = (event, index = undefined) => {
    let itemIndex = -1
    if (index !== undefined) {
    } else if (event) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault()
          if (highlightedItem > -1) {
            setMainDefaultText(options[highlightedItem]);
          }
          setActionDropDown(!actionDropDown)

          break
        case 'ArrowUp':
          event.preventDefault()
          itemIndex = highlightedItem > -1 ? highlightedItem - 1 : options.length - 1
          setHighlightedItem(itemIndex);
          break
        case 'ArrowDown':
          event.preventDefault()
          itemIndex = highlightedItem < options.length - 1 ? highlightedItem + 1 : -1
          setHighlightedItem(itemIndex);
          break
        default:
          break
      }
    }
  }

  return (
    <DropDownWrapper>
      <div ref={dropdownEl} tabIndex={0}
        onKeyDown={(event) => handleSelectedItem(event)}>
        {label && (
          <Label
            style={{
              pointerEvents: "none",
              fontSize: mainDefaultText?.length > 0 ? "10px" : "14px",
              ...labelStyle,
            }}
          >
            {label}
          </Label>
        )}
        {icon && (
          <IconWrapper style={{ pointerEvents: "none" }}>{icon}</IconWrapper>
        )}
        <DropDownButton
          onClick={dropdown}
          style={{
            width: width ? `${width}px` : "300px",
            paddingBottom: "10px",
            paddingTop: "12px",
          }}
          icon={!!icon}
        >
          {mainDefaultText}
          <RightIconWrapper style={{ pointerEvents: "none", ...iconStyle }}>
            <SVG>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </SVG>
            {inputCommand ? (
              <ExtraTextWrapper>{inputCommand.text}</ExtraTextWrapper>
            ) : null}
          </RightIconWrapper>

        </DropDownButton>

        <DropDownWrapper>
          {actionDropDown ? (
            <>
              <OptionMenu role="menu">
                {options.map((option, index) => (
                  <OptionRow
                    selected={highlightedItem === index}
                    onClick={() => {
                      setMainDefaultText(option);
                      setActionDropDown(false);
                    }}
                  >
                    <OptionText>{`${option}`}</OptionText>
                  </OptionRow>
                ))}
              </OptionMenu>
            </>
          ) : null}
        </DropDownWrapper>
      </div>
    </DropDownWrapper>
  );
}

export default DropDownNew;

export const Content = styled.div`
  position: relative;
  height: 50px;
  right: -300px;
  top: 200px;
  background-color: blue;
  z-index: 40;
`;
