import React from "react";
import rtfIcon from "../rtf.png"
const RtfIcon = () => {
  return (
    <>
    <img src={rtfIcon} alt="rtf"/>
    </>
  );
};

export default RtfIcon;
