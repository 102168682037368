import React, { useEffect, useState } from 'react';
import { AccordionContainer, AccordionHeader, AccordionContent, AccordionIconWrapper, RightIconWrapper, ExtraTextWrapper, IconWrapper, TextWrapper } from './Accordion.styled';
import DocumentItem from '../DocumentItem/DocumentItem';
import useKeyPress from '../../hooks/useKeyPress';


const Accordion = ({ title, templateType, content, icon, iconStyle, selectedItem, selectedAccordion, setSelectedAccordion, handleSelection, sno, isOverlay, overlayName, array,setArray, addElement, removeElement }) => {
    const [isOpen, setIsOpen] = useState(null);

    const toggleAccordion = () => {
        handleSelection(null)
        setIsOpen(null);
        if (sno === selectedAccordion) {
            setSelectedAccordion(-1)
        } else {
            setIsOpen(sno);
            setSelectedAccordion(sno)
        }
        
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.altKey && event.key === `${sno + 1}`) {
                event.preventDefault();
                toggleAccordion();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [sno, toggleAccordion]);

    useKeyPress(`${sno + 1}`, ['altKey'], toggleAccordion, isOverlay, overlayName);

    return (
        <AccordionContainer>
            <AccordionIconWrapper onClick={toggleAccordion}>
                {icon && <IconWrapper style={{ ...iconStyle }}>{icon}</IconWrapper>}
                <AccordionHeader >{title}</AccordionHeader>
                <RightIconWrapper>
                    <TextWrapper>{isOpen === selectedAccordion ? "Collapse" : "Expand"}</TextWrapper>
                    <ExtraTextWrapper>{`[Alt + ${sno + 1}]`}</ExtraTextWrapper>
                </RightIconWrapper>
            </AccordionIconWrapper>
            {
                content?.map((item, index) => {
                    return (
                        <AccordionContent open={isOpen === selectedAccordion} >
                            <DocumentItem
                                index={index}
                                text={item?.name}
                                type={item?.type}
                                selectedItemIndex={selectedAccordion === sno ? selectedItem : null}
                                setSelectedItemIndex={handleSelection}
                                wrapperStyle={{ "padding": "8px 12px 8px 16px" }}
                                showIcon={true}
                                labelText={item?.date}
                                height={"36px"}
                                RightSelectButton={true}
                                addElement={addElement}
                                removeElement={removeElement}
                                array={array}
                                setArray={setArray}
                                templateType={templateType}
                            />
                        </AccordionContent>
                    )
                })
            }
        </AccordionContainer>
    );
};

export default Accordion;
