import React, { useEffect, useState } from "react";
import {
  AuditListWrapper,
  AuditListOptionsWrapper,
  AuditListTitle,
  AuditListTitleIcon,
  ButtonContainer,
  Line,
} from "./AuditList.styled";
import Search from "../../components/Search/Search";
import Button from "../../components/Button/Button";
import speechFormat from "../../helpers/speechFormat";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import { getCsv } from "../../apis/ApiServerRequests";
import AuditListTable from "../AuditListTable/AuditListTable";
import useKeyPress from "../../hooks/useKeyPress";
import DownloadIcon from "../../assets/svgs/DownloadIcon";

const AuditList = () => {
  const [input, setInput] = useState("");
  const [clickedButton, setClickedButton] = useState("L");
  const [currentTab, setCurrentTab] = useState("generation log");
  const [currentPage, setCurrentPage] = useState(1);

  const fetchInput = (val) => {
    setInput(val);
  };

  useEffect(() => {
    setCurrentTab(
      clickedButton === "L"
        ? "generation log"
        : clickedButton === "M"
        ? "client log"
        : "format log"
    );
  }, [clickedButton]);

  const downloadCsvFile = async (response, filename) => {
    try {
      const csvData = response.data;
      const blob = new Blob([csvData], { type: "text/csv" });
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = filename;
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
    } catch (error) {
      console.error(`Error downloading ${filename}:`, error);
    }
  };

  const handleDownloadLogs = async () => {
    try {
      if (clickedButton === "L") {
        const generationResponse = await getCsv("document");
        downloadCsvFile(generationResponse, "Document Generation Logs.csv");
      } else if (clickedButton === "M") {
        const generationResponse = await getCsv("client");
        downloadCsvFile(generationResponse, "Company Management Logs.csv");
      } else if (clickedButton === "F") {
        const generationResponse = await getCsv("format");
        downloadCsvFile(generationResponse, "Format Management Logs.csv");
      }
    } catch (error) {
      console.error("Error downloading logs:", error);
    }
  };

  const handleButtonClick = (button) => {
    setCurrentPage(1)
    setClickedButton(button);
  };

  useKeyPress("A", ["altKey"], () => handleDownloadLogs());
  useKeyPress("M", [], () => handleButtonClick("M"));
  useKeyPress("L", [], () => handleButtonClick("L"));
  useKeyPress("F", [], () => handleButtonClick("F"));

  return (
    <>
      <AuditListWrapper>
        <div style={{ display: "flex", gap: "20px" }}>
          <ButtonContainer>
            <Button
              type="ghost"
              accessKeyText="[L]"
              onClick={() => handleButtonClick("L")}
              text="DOCUMENT GENERATION LOGS"
              buttonStyle={{ marginLeft: 10 }}
              data-speech={speechFormat({
                key: "L",
                action: "view",
                item: "generation logs",
              })}
            />
            {clickedButton === "L" && <Line marginLeft="2px" />}
          </ButtonContainer>

          <ButtonContainer>
            <Button
              type="ghost"
              accessKeyText="[M]"
              onClick={() => handleButtonClick("M")}
              text="COMPANY MANAGEMENT LOGS"
              buttonStyle={{ marginLeft: 10 }}
              data-speech={speechFormat({
                key: "M",
                action: "view",
                item: "client Logs",
              })}
            />
            {clickedButton === "M" && <Line marginLeft="5px" />}
          </ButtonContainer>

          <ButtonContainer>
            <Button
              type="ghost"
              accessKeyText="[F]"
              onClick={() => handleButtonClick("F")}
              text="FORMAT MANAGEMENT LOGS"
              buttonStyle={{ marginLeft: 10 }}
              data-speech={speechFormat({
                key: "F",
                action: "view",
                item: "client Logs",
              })}
            />
            {clickedButton === "F" && <Line marginLeft="5px" />}
          </ButtonContainer>
        </div>

        <AuditListOptionsWrapper>
          <Search
            width={400}
            thres={1300}
            placeholdertext={"Search Audit Logs..."}
            righticons={true}
            onChange={fetchInput}
            value={input}
            searchCommand={{
              text: "[G]",
              key: "G",
              subkey: [],
            }}
            voiceCommand={{
              text: "[Alt+G]",
              key: "G",
              subkey: ["altKey"],
            }}
            speech={speechFormat({
              action: "search",
              item: "audit logs",
            })}
          />
          <Button
            type="ghost"
            accessKeyText="[Alt+A]"
            onClick={handleDownloadLogs}
            icon={<DownloadIcon />}
            buttonStyle={{ marginLeft: 10 }}
            data-speech={speechFormat({
              key: "Alt + A",
              action: "download",
              item: "logs",
            })}
          />
        </AuditListOptionsWrapper>
      </AuditListWrapper>
      <AuditListTable
        searchInput={input}
        setSearchInput={setInput}
        currentTab={currentTab}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

AuditList.propTypes = {};

AuditList.defaultProps = {};

export default AuditList;
