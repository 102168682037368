import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AuthCallbackPageWrapper } from "./AuthCallbackPage.styled";
import { useMsal } from "@azure/msal-react";

const AuthCallbackPage = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.handleRedirectPromise().catch((error) => {
      console.error("Error handling redirect:", error);
    });
  }, []);

  return <AuthCallbackPageWrapper>Redirecting...</AuthCallbackPageWrapper>;
};

AuthCallbackPage.propTypes = {};

AuthCallbackPage.defaultProps = {};

export default AuthCallbackPage;
