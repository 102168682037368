import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 42px;
  position: relative; /* Make the container a positioning context */
  text-align: center;
`;

export const SearchError = styled.span`
  color: #a71414;
  font-size: ${(props) => props.theme.font_delta + 10}px;
`;
