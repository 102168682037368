import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  border: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  border-radius: 18px;
  padding: 38px 0px 44px 0px;
  background-color: ${(props) => props.theme.color_theme.bodyColor};
  position: relative;
`;

export const HeadingWrapper = styled.div`
  text-align: center; /* Center-align text */
  width: 246px; /* Set the desired width */
  text-align: left;
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 36px;

  ${props =>
    props.dark_mode && !props.is_contrast
      ? `
      color : #8C8C8C;
  `
      : `
      color : ${(props) => props.theme.color_theme.color};
      `}
`;

export const TextWrapper = styled.div`
  display: flex;
`

export const TitleWrapper = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.font_delta + 20}px;
  color:  ${(props) => `1px solid ${props.theme.color_theme.color}`}; 
`

export const SignInTextWrapper = styled.div`
  color: ${(props) => props.theme.color_theme.primaryColor}; 
  margin-left: 6px
`

export const LogoWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 36px;
    border-bottom: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.div`
  width: 440px;
  background-color: ${(props) => props.theme.color_theme.toolTipBackgoundColor};
  color: ${(props) => props.theme.color_theme.color};
  border-radius: 8px;
  padding: 16px 20px 12px 20px;
  position: absolute;
  left: 60%;
  margin-top: 4px;
  font-size: ${(props) => props.theme.font_delta + 12}px;
  font-weight: 400;
  font-family: Segoe UI;
  
  &::after {
    content: "";
    position: absolute;
    
    top: -25px;
    left: 2%;
    margin-left: -5px;
    border-width: 14px;
    border-style: solid;
    border-color: ${(props) => `transparent transparent ${props.theme.color_theme.toolTipBackgoundColor} transparent`};
  }
`;

// .message-orange:after {
//   content: '';
//   position: absolute;
//   width: 0;
//   height: 0;
//   border-bottom: 15px solid #f8e896;
//   border-left: 15px solid transparent;
//   border-right: 15px solid transparent;
//   bottom: 0;
//   right: -15px;
// }
