import styled from "styled-components";
import Fonts from "../../Fonts/Fonts";

export const DocumentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 0px 24px;
  border-left: ${(props) => `1px solid ${props.theme.color_theme.borderColor}`};
  background: rgba(255, 255, 255, 0);
  margin-top: 7px;
`;

export const RowCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0px 0px 0px;
`;

export const DocumentTitle = styled.div`
  ${Fonts.Body3}
`;

export const DocumentTitleIcon = styled.div`
  color: ${(props) =>
    props.theme.color_theme.selectedTheme === "NC_DM_THEME"
      ? props.theme.color_theme.color
      : props.theme.color_theme.bodyColor};
  background-color: ${(props) => props.theme.color_theme.primaryColor};
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 18}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  // width: 184px;
  height: 28px;
  // position: absolute;
  // left: 0px;
  display: flex;
  align-items: center;
  padding: 0px 24px 0px 24px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
`;
export const SmallText = styled.div`
  ${Fonts.Small};
  line-height: 15px;
  color: ${(props) => props.theme.color_theme.color};
  opacity: 0.4;
  font-family: Segoe UI;
`;

export const DocumentBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaginationContainer = styled.div`
  position: sticky;
  margin: 14px 0px;
  display: flex;
  justify-content: center;
`;
