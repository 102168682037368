import React from "react";
import xlsIcon from "../xls.png"
const XlsxIcon = () => {
  return (
    <>
    <img src={xlsIcon} alt="xls"/>
    </>
  );
};

export default XlsxIcon;
