// Button.js
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  ContentWrapper,
  ExtraTextWrapper,
  IconWrapper,
  StyledButton,
  TextWrapper,
  ContentContainer
} from "./Button.styled";
import { connect } from "react-redux";

const Button = ({
  type,
  icon,
  text,
  accessKeyText,
  buttonStyle,
  textStyle,
  children,
  iconStyle,
  extraTextStyle,
  subText,
  is_contrast,
  ...props
}) => {


  return (
    <StyledButton type={type} {...props} style={buttonStyle} is_contrast={is_contrast}>
      <ContentWrapper>
        <ContentContainer>
          {icon && <IconWrapper style={iconStyle}>{icon}</IconWrapper>}
          <TextWrapper type={type} subText={subText} style={textStyle}>{text}</TextWrapper>
        </ContentContainer>
        {accessKeyText && <ExtraTextWrapper style={extraTextStyle} is_contrast={is_contrast} type={type}>{accessKeyText}</ExtraTextWrapper>}
      </ContentWrapper>
    </StyledButton >
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "ghost", "brand"]),
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  accessKeyText: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  type: "primary",
  subText: false,
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast
  };
};

export default connect(mapStateToProps)(Button);
