import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Body, MainText, SmallText, Wrapper } from "./LoaderCard.styled";
import { Circle } from "./LoaderCard.styled";

const LoaderCard = ({ minVal, maxVal }) => {
  const value = (minVal / maxVal) * 100; // Calculate the value as a decimal
  const roundedValue = Math.floor(value); // Round down to the nearest whole number
  return (
    <Wrapper>
      <Circle>
        <CircularProgressbar
          value={roundedValue}
          strokeWidth={6}
          styles={buildStyles({
            pathColor: "black",
          })}
        />
      </Circle>
      <Body>
        <MainText>{`${roundedValue}% Mapping Resolved`}</MainText>
        {
          maxVal === minVal ?
            <SmallText>{`${minVal}/${maxVal} Resolved`}</SmallText>
            :
            <SmallText>{`${minVal}/${maxVal} Resolved | ${maxVal - minVal
              } left Unresolved`}</SmallText>
        }
      </Body>
    </Wrapper>
  );
};

export default LoaderCard;
