import React, { useEffect, useState } from "react";
import {
  TableDataRow,
  DataCell,
  TableRowWrapper,
  DataCellAction,
  MemberNumberWrapper,
} from "./AuditTableRow.styled";
import useKeyPress from "../../hooks/useKeyPress";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ShowAuditSourceModal from "../../modules/ShowAuditSourceModal/ShowAuditSourceModal";
import ShowClientInformationModal from "../../modules/ShowClientInformationModal/ShowClientInformationModal";
import speechFormat from "../../helpers/speechFormat";

const AuditTableRow = ({
  row,
  index,
  onSelectRow,
  isSelected,
  islast,
  currentTab,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [clientModalOpen, setClientModalOpen] = useState(false);

  const handleRowClick = () => {
    onSelectRow(index);
  };

  const closeModal = () => {
    setModalOpen(false);
    setClientModalOpen(false);
  };

  const overlayName = "UpdateClientModal";
  useKeyPress("1", ["altKey"], () => onSelectRow(0));
  useKeyPress("2", ["altKey"], () => onSelectRow(1));
  useKeyPress("3", ["altKey"], () => onSelectRow(2));
  useKeyPress("4", ["altKey"], () => onSelectRow(3));
  useKeyPress("5", ["altKey"], () => onSelectRow(4));
  useKeyPress("6", ["altKey"], () => onSelectRow(5));
  useKeyPress("7", ["altKey"], () => onSelectRow(6));
  useKeyPress("8", ["altKey"], () => onSelectRow(7));
  useKeyPress("9", ["altKey"], () => onSelectRow(8));
  // useKeyPress("1", [], () =>
  //   isSelected && currentTab === "generation log"
  //     ? handleDocumentDownload(row.document_url, row.document_name)
  //     : setClientModalOpen(true)
  // );

  useKeyPress("1", [], () => {
    if (isSelected && ["generation log", "format log"].includes(currentTab)) {
      handleDocumentDownload(row.document_url, row.document_name);
    } else if (isSelected && !["generation log", "format log"].includes(currentTab)) {
      setClientModalOpen(true);
    }

  });
  
  useKeyPress(
    "2",
    [],
    () => 
      isSelected && ["generation log", "format log"].includes(currentTab) && setModalOpen(true)
  );
  useKeyPress(
    "3",
    [],
    () => isSelected && handleDestinationDocumentDownload(row.destination)
  );

  const handleDestinationDocumentDownload = (destination) => {
    if (destination && destination.length > 0) {
      destination.forEach((dest) => {
        if (dest.url && dest.name) {
          handleDocumentDownload(dest.url, dest.name);
        }
      });
    }
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1;
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDateTime = `${formattedDay}-${formattedMonth}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };

  const handleDocumentDownload = async (documentUrl, documentName) => {
    if (documentUrl) {
      try {
        const downloadUrl = `${documentUrl}/download`;
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = documentName;
        anchor.click();
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    }
  };

  const getSourceAndStyleCount = () => {
    const sourceCount = row?.source ? row?.source?.length : 0;
    const styleCount = row?.styling_configurations_files ? row?.styling_configurations_files?.length : 0;
    return sourceCount + styleCount;
  };

  return (
    <>
      {currentTab === "generation log" && (
        <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
          <TableDataRow
            key={index}
            onClick={handleRowClick}
            isSelected={isSelected}
          >
            <DataCell
              isSelected={isSelected}
              width={"14.2%"}
              style={{ justifyContent: "normal" }}
            >
              <div style={{ marginRight: "4px" }} 
              data-speech={speechFormat({
                key: `Alt + ${index + 1}`,
                action:"select",
                item: `log with the id ${index+1}`,
              })}
              tabIndex={0}>{index + 1}.</div>
              <span> [Alt + {index + 1}]</span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="time stamp">
              <span>{formatDateTime(row?.timestamp) || "Not available"}</span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="company name">
              <span>{row.company_name}</span>
            </DataCell>
            <DataCell width={"18.2%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                [1]
                <MemberNumberWrapper
                  isSelected={isSelected}
                  onClick={() =>
                    handleDocumentDownload(row.document_url, row.document_name)
                  }
                >
                  {row.document_url ? (
                    <span style={{ cursor: "pointer" }} tabIndex={0} aria-label="document name">
                      {row?.document_name || ""}
                    </span>
                  ) : (
                    <span tabIndex={0} aria-label="document name">{row?.document_name || ""}</span>
                  )}
                </MemberNumberWrapper>
              </span>
            </DataCell>
            <DataCell width={"10.2%"} style={{ justifyContent: "normal", gap:"5px" }}>
              <MemberNumberWrapper
                isSelected={isSelected}
                onClick={() => setModalOpen(true)}
                tabIndex={0} aria-label="number of sources"
              >
                {getSourceAndStyleCount()}
              </MemberNumberWrapper>
              <span>[2]</span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                [3]
                <MemberNumberWrapper isSelected={isSelected}>
                  {row.destination ? (
                    <span style={{ cursor: "pointer" }}>
                      {row.destination.map((dest, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleDocumentDownload(dest.url, dest.name)
                          }
                          tabIndex={0} 
                          aria-label="destination"
                        >
                          {dest.name}
                        </div>
                      ))}
                    </span>
                  ) : (
                    <span tabIndex={0} aria-label="destination">{row?.destination || ""}</span>
                  )}
                </MemberNumberWrapper>
              </span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="destination">
              <span>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </span>
            </DataCell>
          </TableDataRow>
        </TableRowWrapper>
      )}

      {currentTab === "format log" && (
        <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
          <TableDataRow
            key={index}
            onClick={handleRowClick}
            isSelected={isSelected}
          >
            <DataCell
              isSelected={isSelected}
              width={"14.2%"}
              style={{ justifyContent: "normal" }}
            >
              <div style={{ marginRight: "4px" }} 
              data-speech={speechFormat({
                key: `Alt + ${index + 1}`,
                action:"select",
                item: `log with the id ${index+1}`,
              })}
              tabIndex={0}>{index + 1}.</div>
              <span> [Alt + {index + 1}]</span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="time stamp">
              <span>{formatDateTime(row?.timestamp) || "Not available"}</span>
            </DataCell>
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="company name">
              <span>{row?.company_name}</span>
            </DataCell>
            <DataCell width={"18.2%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                [1]
                <MemberNumberWrapper
                  isSelected={isSelected}
                  onClick={() =>
                    handleDocumentDownload(row?.formatted_document_url, row?.formatted_document_name)
                  }
                >
                  {row.formatted_document_url ? (
                    <span style={{ cursor: "pointer" }} tabIndex={0} aria-label="document name">
                      {row?.formatted_document_name || ""}
                    </span>
                  ) : (
                    <span tabIndex={0} aria-label="document name">{row?.formatted_document_name || ""}</span>
                  )}
                </MemberNumberWrapper>
              </span>
            </DataCell>
            <DataCell width={"10.2%"} style={{ justifyContent: "normal", gap:"5px" }}>
              <MemberNumberWrapper
                isSelected={isSelected}
                onClick={() => setModalOpen(true)}
                tabIndex={0} aria-label="number of sources"
              >
                {getSourceAndStyleCount()}
              </MemberNumberWrapper>
              <span>[2]</span>
            </DataCell>
            
            <DataCell width={"14.2%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="destination">
              <span>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </span>
            </DataCell>
          </TableDataRow>
        </TableRowWrapper>
      )}
      
      {currentTab === "client log" && (
        <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
          <TableDataRow
            key={index}
            onClick={handleRowClick}
            isSelected={isSelected}
          >
            <DataCell
              isSelected={isSelected}
              width={"20%"}
              style={{ justifyContent: "normal" }}
            >
              <div style={{ marginRight: "4px" }}>{index + 1}.</div>
              <span> [Alt + {index + 1}]</span>
            </DataCell>
            <DataCell width={"20%"} style={{ justifyContent: "normal" }}>
              <span>{formatDateTime(row?.timestamp) || "Not available"}</span>
            </DataCell>
            <DataCell
              width={"20%"}
              style={{ justifyContent: "normal", gap: "5px" }}
            >
              <span>[1]</span>
              <MemberNumberWrapper
                isSelected={isSelected}
                onClick={() => setClientModalOpen(true)}
              >
                {row.company_name}
              </MemberNumberWrapper>
            </DataCell>

            <DataCell width={"20%"} style={{ justifyContent: "normal" }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {row?.method || ""}
              </span>
            </DataCell>
            <DataCell width={"20%"} style={{ justifyContent: "normal" }}>
              <span>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </span>
            </DataCell>
          </TableDataRow>
        </TableRowWrapper>
      )}

      {modalOpen && currentTab === "generation log" && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <ShowAuditSourceModal
            onClose={closeModal}
            source_info={row?.source}
            styling_configuration_files={row.styling_configuration_files}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}

      {modalOpen && currentTab === "format log" && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <ShowAuditSourceModal
            onClose={closeModal}
            source_info={row?.source || [
              {
                "source_file": {
                  "name": row?.input_document_name,
                  "url": row?.formatted_document_url
                }
              }
            ]}
            styling_configuration_files={row.style_configuration_urls}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}

      {clientModalOpen && currentTab === "client log" && (
        <ModalWrapper
          isOpen={clientModalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <ShowClientInformationModal
            onClose={closeModal}
            previousClientInfo={row.previous_client_info}
            clientInfo={row.client_information}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}
    </>
  );
};

export default AuditTableRow;
